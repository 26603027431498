import React from "react";
import Spinner from "./Spinner";

interface LoadingPageProps {}

const LoadingPage: React.FC<LoadingPageProps> = () => {
  return (
    <div className="w-full h-screen bg-primary-60 flex justify-center items-center">
      <Spinner className="text-white" size="large" />
    </div>
  );
};

export default LoadingPage;
