import { useCallback, useEffect, useRef, useState } from "react";
import { paginationLimitList } from "src/components/PaginationLimitChanger/hooks/usePaginationLimitChanger";
import { useDebounce } from "src/hooks/useDebounce";
import { POSBuyer } from "src/pages/POS/api/interfaces";
import { useGetPOSBuyers } from "src/pages/POS/api/queries";
import { formatPhoneNumber } from "src/utils/formatPhoneNumber";
import { CustomerAndPartnerSelectionProps } from "..";

interface useCustomerAndPartnerSelectionHandlerProps
  extends CustomerAndPartnerSelectionProps {}

export type CustomerAndPartnerSelectionOption = {
  label: string;
  value: string;
  name: string;
  phoneNumber: string;
  country: string;
  city: string;
  address: string | undefined;
  type: "customer" | "partner";
  customerTypeId: string;
  customerType: string;
  typeHex: string;
};

const useSearchInput = ({
  onValueChange,
}: useCustomerAndPartnerSelectionHandlerProps) => {
  const [searchInput, setSearchInput] = useState("");
  const [isShowCreateCustomerModal, setIsShowCreateCustomerModal] =
    useState(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const [isOpen, setIsOpen] = useState(false);
  const [isInit, setIsInit] = useState(true);

  const [existingData, setExistingData] = useState<POSBuyer[]>([]);

  const [pagination, setPagination] = useState({
    limit: paginationLimitList[0],
    offset: 0,
  });

  const debouncedSearchInput = useDebounce({ value: searchInput });

  const { data, isLoading } = useGetPOSBuyers({
    ...pagination,
    ...(searchInput ? { qn: debouncedSearchInput } : {}),
  });

  const searchOptions: CustomerAndPartnerSelectionOption[] =
    existingData?.map((posBuyer) => {
      let formattedData = {
        label: `${posBuyer.name}${
          posBuyer.phone_number
            ? ` - ${formatPhoneNumber(posBuyer.phone_number)}`
            : ""
        }`,
        value: `${posBuyer.id}`,
        name: posBuyer.name,
        phoneNumber: posBuyer.phone_number ?? "",
        country: posBuyer.country,
        city: posBuyer.city,
        address: posBuyer.address ?? undefined,
        type: posBuyer.buyer_type,
        customerTypeId: posBuyer?.customer_type
          ? `${posBuyer?.customer_type.id}`
          : "",
        customerType: posBuyer?.customer_type?.name ?? "",
        typeHex: posBuyer?.hex,
      };

      return formattedData;
    }) ?? [];

  const hasMoreData =
    existingData.length > 0 &&
    data?.meta?.last_page !== data?.meta?.current_page;

  const clearState = () => {
    setIsInit(true);
    setExistingData([]);
    setPagination({
      limit: paginationLimitList[0],
      offset: 0,
    });
  };

  const handleLoadMore = () => {
    let tempExistingData = [...existingData] as POSBuyer[];

    setTimeout(() => {
      setExistingData(tempExistingData);
      setPagination({
        limit: pagination.limit,
        offset: pagination.offset + pagination.limit,
      });

      setIsOpen(true);
      inputRef?.current?.focus();
    }, 250);
  };

  useEffect(() => {
    if (!isOpen && !isInit) {
      clearState();
    }
  }, [isOpen, isInit]);

  useEffect(() => {
    if (isOpen && data?.data) {
      const responseData = data?.data as POSBuyer[];

      if (isInit || searchInput) {
        setExistingData([...responseData]);
        setIsInit(false);
      } else {
        if (pagination.offset > 0) {
          setExistingData((prevState) => [...prevState, ...responseData]);
        }
      }
    }
  }, [isOpen, data, isInit, pagination.offset, searchInput]);

  const handleBlur = useCallback(() => {
    setTimeout(() => {
      setIsOpen(false);
      setIsInit(true);
    }, 200);
  }, []);

  const handleSelectOption = useCallback(
    (selectedOption: CustomerAndPartnerSelectionOption) => {
      onValueChange(selectedOption);

      setTimeout(() => {
        setSearchInput("");
        inputRef?.current?.blur();
      }, 0);
    },
    [onValueChange]
  );

  const handleChangeSearchInput = (search: string) => {
    clearState();
    setSearchInput(search);
  };

  const handleCreateCustomerModal = () => setIsShowCreateCustomerModal(true);
  const handleCloseCreateCustomerModal = () =>
    setIsShowCreateCustomerModal(false);

  return {
    handleChangeSearchInput,
    inputRef,
    searchInput,
    isOpen,
    setIsOpen,
    isShowCreateCustomerModal,
    isLoading,
    handleBlur,
    handleSelectOption,
    searchOptions,
    hasMoreData,
    handleLoadMore,
    handleCreateCustomerModal,
    handleCloseCreateCustomerModal,
  };
};

export default useSearchInput;
