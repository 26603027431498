import { UseFormReturn } from "react-hook-form";
import { Pagination } from "src/components/ui/pagination";
import useSearch from "src/hooks/useSearch";
import { useGetProductVariantList } from "src/pages/ProductManagement/ProductList/api/queries";
import { AddProductModalSchemaType } from "../addProductModalSchema";
import { SelectedPackageProductType } from "./usePOSHandler";
import { useEffect } from "react";

interface UseAddProductModalPackageProductListHandlerProps {
  form: UseFormReturn<AddProductModalSchemaType, any, undefined>;
  index: number;
  packageVariantId: number;
  selectedPackageProducts: SelectedPackageProductType[];
  setSelectedPackageProducts: React.Dispatch<
    React.SetStateAction<SelectedPackageProductType[]>
  >;
  packageProduct: AddProductModalSchemaType["products"][number];
}

const useAddProductModalPackageProductListHandler = ({
  form,
  index,
  packageVariantId,
  selectedPackageProducts,
  setSelectedPackageProducts,
  packageProduct,
}: UseAddProductModalPackageProductListHandlerProps) => {
  const {
    value: searchProductVariantList,
    setSearch: setSearchProductVariantList,
  } = useSearch();

  const {
    data: productVariantListData,
    isLoading: isLoadingProductVariantListData,
  } = useGetProductVariantList({
    ...Pagination,
    ...(searchProductVariantList ? { name: searchProductVariantList } : {}),
    selectedVariantIds: [],
  });

  const productVariants = productVariantListData?.data ?? [];
  const productVariantListOptions =
    productVariants.map((pvld) => ({
      label: `${pvld.product.name} - ${pvld.name}`,
      value: `${pvld.id}`,
    })) ?? [];

  const formValues = form.getValues();

  const selectedProductVariantId = formValues.products[index].id;
  const selectedPackageProduct = selectedPackageProducts.find(
    (selectedPackageProduct) =>
      `${selectedPackageProduct.id}` === `${selectedProductVariantId}` &&
      `${selectedPackageProduct.packageVariantId}` === `${packageVariantId}`
  );

  let filteredProductVariantListOptions = [...productVariantListOptions];

  if (selectedPackageProduct) {
    filteredProductVariantListOptions = [
      {
        label: `${selectedPackageProduct.productName} - ${selectedPackageProduct.variantName}`,
        value: `${selectedPackageProduct.id}`,
      },
      ...productVariantListOptions,
    ];
  }

  const selectedProduct = productVariants.find(
    (productVariant) => `${productVariant.id}` === `${packageProduct.id}`
  );
  const stocks =
    selectedPackageProduct?.stocks ?? selectedProduct?.stocks ?? [];
  const warehouseOptions =
    stocks.map((stock) => ({
      label: stock.warehouse.name,
      value: `${stock.warehouse?.id}`,
    })) ?? [];

  const selectedStock = (stocks as any)?.find(
    (stock: any) => `${stock.warehouse.id}` === `${packageProduct.warehouseId}`
  );
  const hasStock = (selectedStock?.available ?? 0) > 0;
  const availableStock = selectedStock?.available ?? 0;

  const onChangeProductItemCallback = (value: any) => {
    const selectedPkgProduct = selectedPackageProducts.find(
      (selectedPackageProduct) =>
        `${selectedPackageProduct.id}` === value &&
        `${selectedPackageProduct.packageVariantId}` === `${packageVariantId}`
    );

    form.setValue(`products.${index}.warehouseId`, "0", {
      shouldValidate: true,
    });
    form.setValue(`products.${index}.warehouseName`, "", {
      shouldValidate: true,
    });
    form.setValue(
      `products.${index}.quantity`,
      `${selectedPkgProduct?.quantity ?? 0}`,
      {
        shouldValidate: true,
      }
    );
  };
  const onChangeWarehouseCallback = (value: any) => {
    const selectedStock = (stocks as any)?.find(
      (stock: any) => `${stock.warehouse.id}` === value
    );

    const productQuantity = selectedPackageProduct?.quantity ?? 0;
    const stockAvailability = selectedStock.available;
    const isAvailable = stockAvailability >= productQuantity;

    if (isAvailable) {
      form.setValue(`products.${index}.quantity`, `${productQuantity}`, {
        shouldValidate: true,
      });
    } else {
      form.setValue(`products.${index}.quantity`, `${availableStock}`, {
        shouldValidate: true,
      });
    }

    form.setValue(
      `products.${index}.warehouseName`,
      selectedStock?.warehouse?.name,
      {
        shouldValidate: true,
      }
    );
  };

  const handleDeleteProductList = (index: number) => {
    let newProducts = [...form.getValues().products];

    delete newProducts[index];

    newProducts = newProducts.filter(Boolean);

    form.setValue("products", newProducts, {
      shouldValidate: true,
    });
  };

  const isRentOnly = !selectedProduct?.is_purchase && selectedProduct?.is_rent;

  useEffect(() => {
    if (isRentOnly) {
      form.setValue(`products.${index}.markAsRent`, true, {
        shouldValidate: true,
      });
    }
  }, [isRentOnly, index, form]);

  useEffect(() => {
    if (selectedProduct) {
      form.setValue(
        `products.${index}.price`,
        `${
          isRentOnly ? selectedProduct.rent_price : selectedProduct.sell_price
        }`,
        {
          shouldValidate: true,
        }
      );

      setSelectedPackageProducts((prevState) => {
        let newArr = [...prevState];

        const isExist = prevState.find(
          (spp) => `${spp.id}` === `${selectedProduct.id}`
        );

        if (!isExist) {
          newArr.push({
            id: selectedProduct.id,
            packageVariantId,
            productName: selectedProduct.product.name,
            variantName: selectedProduct.name,
            itemStockUnit: selectedProduct.product?.item_stock_unit?.name,
            stocks:
              selectedProduct.stocks.map((stock) => ({
                ...stock,
                product_variant_id: selectedProduct.id,
                warehouse_id: stock.warehouseId,
              })) ?? [],
            quantity: 0,
          });
        }

        return newArr;
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProduct, setSelectedPackageProducts]);

  const totalItemPrice = Math.max(
    Math.max(
      parseInt(`${packageProduct.price}`, 0) *
        parseInt(`${packageProduct.quantity}`, 0),
      0
    ) - parseInt(`${packageProduct.discount}`, 0),
    0
  );

  const uniqueObjects = (arr: { label: string; value: string }[]) => {
    const seen = new Set();
    return arr.filter((item) => !seen.has(item.value) && seen.add(item.value));
  };

  const handleMarkAsRentCallBack = ({
    checked,
    index,
  }: {
    checked: boolean;
    index: number;
  }) => {
    if (checked) {
      const rentPrice = selectedProduct?.rent_price ?? 0;

      form.setValue(`products.${index}.price`, `${rentPrice}`, {
        shouldValidate: true,
      });
    } else {
      const sellPrice = selectedProduct?.sell_price ?? 0;

      form.setValue(`products.${index}.price`, `${sellPrice}`, {
        shouldValidate: true,
      });
    }
  };

  return {
    productVariantListOptions: uniqueObjects(filteredProductVariantListOptions),
    isLoadingProductVariantListData,
    setSearchProductVariantList,
    warehouseOptions,
    selectedPackageProduct,
    availableStock,
    selectedStock,
    hasStock,
    totalItemPrice,
    isRentOnly,
    onChangeWarehouseCallback,
    onChangeProductItemCallback,
    handleDeleteProductList,
    handleMarkAsRentCallBack,
  };
};

export default useAddProductModalPackageProductListHandler;
