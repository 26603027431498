import { More, MoreSquare } from "iconsax-react";
import React, { ReactNode } from "react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";

interface TableActionDropdownProps {
  onViewDetail?: () => void;
  currentActiveStatus?: "active" | "inactive";
  onToggleActiveStatus?: () => void;
  onEdit?: () => void;
  onDownloadQR?: () => void;
  onRemove?: () => void;
  onAdd?: () => void;
  addLabel?: string;
  removeLabel?: string;
  className?: string;
  withoutOutline?: boolean;
  otherPositiveAction?: () => void;
  otherPositiveActionTitle?: string;
  otherPositiveAction2?: () => void;
  otherPositiveAction2Title?: string;
  children?: ReactNode;
}

const TableActionDropdown: React.FC<TableActionDropdownProps> = ({
  onViewDetail,
  currentActiveStatus,
  onToggleActiveStatus,
  onEdit,
  onDownloadQR,
  onRemove,
  onAdd,
  addLabel,
  removeLabel = "Remove",
  className,
  withoutOutline,
  otherPositiveAction,
  otherPositiveActionTitle,
  otherPositiveAction2,
  otherPositiveAction2Title,
  children,
}) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger className={className}>
        {!withoutOutline ? (
          <MoreSquare className="text-darkRed-60" />
        ) : (
          <More className="text-darkRed-60" />
        )}
      </DropdownMenuTrigger>

      <DropdownMenuContent className="text-darkIndicator-40">
        {otherPositiveAction && (
          <DropdownMenuItem
            className="text-indicator-success hover:!text-indicator-success"
            onClick={otherPositiveAction}
          >
            {otherPositiveActionTitle}
          </DropdownMenuItem>
        )}
        {otherPositiveAction2 && (
          <DropdownMenuItem
            className="text-[#0099FF] hover:!text-[#0099FF]"
            onClick={otherPositiveAction2}
          >
            {otherPositiveAction2Title}
          </DropdownMenuItem>
        )}
        {onAdd && (
          <DropdownMenuItem onClick={onAdd}>{addLabel}</DropdownMenuItem>
        )}
        {onViewDetail && (
          <DropdownMenuItem onClick={onViewDetail}>
            View Detail
          </DropdownMenuItem>
        )}
        {onToggleActiveStatus && (
          <DropdownMenuItem onClick={onToggleActiveStatus}>
            {currentActiveStatus === "active" ? "Deactivate" : "Activate"}
          </DropdownMenuItem>
        )}
        {onEdit && <DropdownMenuItem onClick={onEdit}>Edit</DropdownMenuItem>}
        {onDownloadQR && (
          <DropdownMenuItem onClick={onDownloadQR}>
            Download QR Code
          </DropdownMenuItem>
        )}
        {onRemove && (
          <DropdownMenuItem
            className="text-redIndicator-50 hover:!text-redIndicator-50"
            onClick={onRemove}
          >
            {removeLabel}
          </DropdownMenuItem>
        )}

        {children}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default TableActionDropdown;
