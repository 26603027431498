"use client";

import { z } from "zod";

export type AddProductModalSchemaType = z.infer<typeof addProductModalSchema>;

export const addPOSProductSchema = {
  markAsRent: z.boolean(),
  price: z.string().regex(/^\d+$/),
  discount: z.string().regex(/^\d+$/),
  quantity: z.string().regex(/^\d+$/),
  warehouses: z.array(
    z.object({
      id: z.number({
        coerce: true,
        invalid_type_error: "Required",
      }),
      quantity: z.string().regex(/^\d+$/),
    })
  ),
  products: z.array(
    z.object({
      id: z.string().regex(/^\d+$/),
      warehouseId: z
        .string()
        .regex(/^\d+$/)
        .refine((val) => parseInt(val, 10) >= 1, { message: "Required" }),
      warehouseName: z.string(),
      quantity: z
        .string()
        .regex(/^\d+$/)
        .refine((val) => parseInt(val, 10) >= 1, { message: "Required" }),
      price: z.string().regex(/^\d+$/),
      discount: z.string().regex(/^\d+$/),
      markAsRent: z.boolean(),
    })
  ),
};

export const addProductModalSchema = z.object(addPOSProductSchema);
