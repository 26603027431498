import React, { useState } from "react";
import { capitalize } from "src/utils/commons";
import { TableOrderItem } from "./OrderListTableRow";

interface OrderListTableRowOrderItemProductsProps {
  orderItem: TableOrderItem;
}

const OrderListTableRowOrderItemProducts: React.FC<
  OrderListTableRowOrderItemProductsProps
> = ({ orderItem }) => {
  const [isShowMoreOrderItemProducts, setIsShowMoreOrderItemProducts] =
    useState(false);

  const products = orderItem?.products ?? [];

  const showedData = isShowMoreOrderItemProducts
    ? products
    : [...products].splice(0, 2);

  const handleToggleShowMoreOrderItem = () =>
    setIsShowMoreOrderItemProducts(!isShowMoreOrderItemProducts);

  return (
    <>
      {showedData.map((product, i) => (
        <div className="flex flex-col pl-4" key={i}>
          <span className="text-sm font-normal text-primary-80">{`${
            product.name
          }${product.quantity ? ` - (x${product.quantity})` : ""}`}</span>
          <span className="text-sm font-normal text-darkIndicator-30">{`${capitalize(
            product.orderType
          )} Item - ${product.warehouseName}`}</span>
        </div>
      ))}

      {products.length > 2 && (
        <div className="pl-4">
          <span
            className="cursor-pointer font-semibold underline text-primary-50"
            onClick={handleToggleShowMoreOrderItem}
          >
            {isShowMoreOrderItemProducts ? "Show Less" : "Show More"}
          </span>
        </div>
      )}
    </>
  );
};

export default OrderListTableRowOrderItemProducts;
