import { useState } from "react";

const useCustomInputHandler = () => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return { showPassword, togglePasswordVisibility };
};

export default useCustomInputHandler;
