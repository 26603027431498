import { UseQueryResult } from "@tanstack/react-query";
import { Navigate, Outlet } from "react-router-dom";
import LoadingPage from "src/components/LoadingPage";
import { RoutePaths } from "src/pages/routePaths";
import { MeResponse } from "src/pages/SignIn/api/intefaces";

interface AuthRouteProps {
  currentUser: UseQueryResult<MeResponse, Error>;
}

const AuthRoute = ({ currentUser }: AuthRouteProps) => {
  const token = localStorage.getItem("token");
  const { data, isLoading } = currentUser;

  if (isLoading) {
    return <LoadingPage />;
  } else {
    if (!data || !token) {
      return <Navigate to={RoutePaths.SignIn} replace />;
    } else {
      return <Outlet />;
    }
  }
};

export default AuthRoute;
