export const snakeToCamel = (snakeStr: string = ""): string => {
  if (snakeStr.split(".").length > 1) {
    return snakeStr;
  }

  return snakeStr
    .toLowerCase()
    .replace(/(_\w)/g, (matches) => matches[1].toUpperCase());
};

export const camelToSnake = (camelStr: string = ""): string => {
  return camelStr.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
};
