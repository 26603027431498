import { startTransition } from "react";
import { useNavigate } from "react-router-dom";
import { defaultPagination } from "src/constant";
import { useGetOverviewNotification } from "src/pages/Overview/api/queries";
import { RoutePaths } from "src/pages/routePaths";

const useNotificationDropdownMenuHandler = () => {
  const navigate = useNavigate();

  const { data, isLoading } = useGetOverviewNotification({
    ...defaultPagination,
  });

  const handleViewDetail = () =>
    startTransition(() => navigate(RoutePaths.Notification));

  return {
    notifications: data?.data ?? [],
    isLoading,
    handleViewDetail,
  };
};

export default useNotificationDropdownMenuHandler;
