// SocketContext.tsx
import React, { createContext, useEffect, useState } from "react";
import { Socket, io } from "socket.io-client";
import { getBaseURL } from "src/services";
import { getAuthTokens } from "src/utils/getAuthTokens";

type SocketContextType = Socket | null;

export const SocketContext = createContext<SocketContextType>(null);

interface SocketProviderProps {
  children: React.ReactNode;
}

export const SocketProvider: React.FC<SocketProviderProps> = ({ children }) => {
  const baseURL = getBaseURL();
  const { token } = getAuthTokens();

  const [socket, setSocket] = useState<Socket | null>(null);

  useEffect(() => {
    const socketIo = io(baseURL, {
      query: {
        access_token: token,
      },
    });
    setSocket(socketIo);

    return () => {
      socketIo.disconnect();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
};
