import React, { ReactNode } from "react";
import { Button } from "../ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../ui/dialog";
import useConfirmationModal, {
  ConfirmationModalIconType,
} from "./hooks/useConfirmationModal";

interface ConfirmationModalProps {
  isShow: boolean;
  onClose: () => void;
  onSubmit: () => void;
  iconType?: ConfirmationModalIconType;
  title: string;
  description?: string;
  cancelButtonTitle: string;
  submitButtonTitle: string;
  footerAlignment?: "start" | "end" | "between";
  cancelButtonPosition?: "first" | "last";
  noHeader?: boolean;
  children?: ReactNode;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  isShow,
  onClose,
  onSubmit,
  iconType,
  title,
  description,
  cancelButtonTitle,
  submitButtonTitle,
  footerAlignment = "between",
  cancelButtonPosition = "first",
  noHeader = false,
  children,
}) => {
  const { icon } = useConfirmationModal({ iconType });

  const cancelButtonVariant = iconType === "danger" ? "default" : "outline";
  const submitButtonVariant = iconType === "danger" ? "outline" : "default";

  return (
    <Dialog open={isShow} onOpenChange={onClose}>
      <DialogContent>
        {!noHeader && (
          <DialogHeader>
            <DialogTitle className="text-md font-semibold text-darkBrown-default">
              Pop Up Confirmation
            </DialogTitle>
          </DialogHeader>
        )}

        <div className="flex flex-col items-center gap-4 pt-2 pb-5">
          {icon}

          <div className="grid text-center gap-1">
            <span className="font-bold text-lg">{title}</span>

            {description && (
              <span className="font-medium text-sm">{description}</span>
            )}
          </div>

          {children}
        </div>

        <DialogFooter>
          <div
            className={`flex flex-1 flex-row justify-${footerAlignment} gap-4`}
          >
            {cancelButtonPosition === "first" && (
              <DialogClose asChild onClick={onClose}>
                <Button type="button" variant={cancelButtonVariant}>
                  {cancelButtonTitle}
                </Button>
              </DialogClose>
            )}

            <Button variant={submitButtonVariant} onClick={onSubmit}>
              {submitButtonTitle}
            </Button>

            {cancelButtonPosition === "last" && (
              <DialogClose asChild onClick={onClose}>
                <Button type="button" variant={cancelButtonVariant}>
                  {cancelButtonTitle}
                </Button>
              </DialogClose>
            )}
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmationModal;
