import AngieFiorLogo1 from "src/assets/images/angie_fior_logo_1.png";
import AngieFiorLogo2 from "src/assets/images/angie_fior_logo_2.png";
import SolusiMekanikLogo from "src/assets/images/solusi_mekanik_logo.png";

export const appName = process.env.REACT_APP_APP_NAME;

const getAppAsset = () => {
  let appAsset = {
    appName: "",
    appLogo1: "",
    appLogo2: "",
    appTabIconHref: "",
    appleTouchIconLinkHref: "",
  };

  const angiefiorAsset = {
    appName: "Angie Fior",
    appLogo1: AngieFiorLogo1,
    appLogo2: AngieFiorLogo2,
    appTabIconHref: "/favicon.ico",
    appleTouchIconLinkHref: "/logo192.png",
  };

  switch (appName) {
    case "angie-fior":
      appAsset = angiefiorAsset;
      break;
    case "solusi-mekanik":
      appAsset = {
        appName: "Solusi Mekanik",
        appLogo1: SolusiMekanikLogo,
        appLogo2: undefined as any,
        appTabIconHref: "/faviconSolusiMekanik.ico",
        appleTouchIconLinkHref: "/logo192SolusiMekanik.png",
      };
      break;

    default:
      appAsset = angiefiorAsset;
      break;
  }

  return appAsset;
};

export const appAsset = getAppAsset();
