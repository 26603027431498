import { startTransition } from "react";
import { useNavigate } from "react-router-dom";
import { RoutePaths } from "src/pages/routePaths";
import { AccessResource, ActionType } from "src/types/accessControl";
import { useGetAccessPermission } from "./useGetAccessPermission";

interface UseGetPagePermissionProps {
  resource: AccessResource | null;
  action: ActionType;
}

export const useGetPagePermission = ({
  resource,
  action,
}: UseGetPagePermissionProps) => {
  const navigate = useNavigate();

  const isAuthenticated = useGetAccessPermission({ resource, action });

  if (!isAuthenticated) {
    startTransition(() => {
      navigate(RoutePaths.UnAuthenticated, { replace: true });
    });
  }
};
