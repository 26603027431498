import { startTransition, useState } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { AdminList } from "src/pages/AdminManagement/AdminList/api/interfaces";
import { RoutePaths } from "src/pages/routePaths";
import { useCurrentUser } from "src/pages/SignIn/api/queries";
import { logOut } from "src/services/Auth.service";
import { apiErrorHandler } from "src/utils/apiErrorHandler";

const handleLogout = async (navigate: NavigateFunction) => {
  try {
    await logOut();
    startTransition(() => navigate(RoutePaths.SignIn));
  } catch (error) {
    apiErrorHandler({ error });
  }
};

const useGetSignedAdmin = (user?: AdminList) => {
  return {
    name: user?.name ?? "",
    email: user?.email ?? "-",
  };
};

const useAdminDropdownMenu = () => {
  const navigate = useNavigate();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const { data } = useCurrentUser();

  const handleDialogOpen = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  return {
    admin: useGetSignedAdmin(data?.data),
    handleLogout: async () => await handleLogout(navigate),
    isDialogOpen,
    setIsDialogOpen,
    handleDialogOpen,
    handleDialogClose,
  };
};

export default useAdminDropdownMenu;
