import { ReactShowMoreTextProps } from "react-show-more-text";

export const reactShowMoreTextConfig: ReactShowMoreTextProps = {
  lines: 1,
  more: "Show more",
  less: "Show less",
  width: 460,
  truncatedEndingComponent: "... ",
  anchorClass: "text-darkRed-50 font-medium cursor-pointer",
  className: "break-all",
};
