import { useEffect } from "react";
import { MetaType } from "src/types/apiResponse";

interface UseSetPaginationCountProps {
  meta?: MetaType;
  setCount: (value: number) => void;
}

export const useSetPaginationCount = ({
  meta,
  setCount,
}: UseSetPaginationCountProps) => {
  useEffect(() => {
    if (meta) {
      const count = meta.total ?? 0;

      setCount(count);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meta?.total]);
};
