import { useQuery } from "@tanstack/react-query";
import { reactQueryOptions } from "src/config/reactQuery.config";
import {
  getOrderList,
  getOrderListDetail,
} from "src/services/OrderList.service";
import { OrderListDetailParams, OrderListParams } from "./interfaces";

export const useGetOrderList = (params: OrderListParams) => {
  return useQuery({
    ...reactQueryOptions,
    queryKey: ["order-list", params],
    queryFn: () => getOrderList(params),
  });
};

export const useGetOrderListDetail = (params: OrderListDetailParams) => {
  return useQuery({
    ...reactQueryOptions,
    queryKey: ["order-list-detail", params],
    queryFn: () => getOrderListDetail(params),
  });
};
