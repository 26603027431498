import { useQuery } from "@tanstack/react-query";
import { getNotifications } from "src/services/Notification.service";
import {
  OverviewCardParams,
  OverviewNotificationsParams,
  OverviewOrdersParams,
  OverviewTopProductsParams,
  OverviewTotalSalesParams,
} from "./interfaces";
import { reactQueryOptions } from "src/config/reactQuery.config";
import {
  getOverviewCard,
  getOverviewChart,
  getOverviewOrders,
  getOverviewTopItems,
} from "src/services/Overview.service";

export const useGetOverviewNotification = (
  params: OverviewNotificationsParams
) => {
  return useQuery({
    ...reactQueryOptions,
    queryKey: ["overview-notification", params],
    queryFn: () => getNotifications(params),
  });
};

export const useGetOverviewCard = (params: OverviewCardParams) => {
  return useQuery({
    ...reactQueryOptions,
    queryKey: ["overview-card", params],
    queryFn: () => getOverviewCard(params),
  });
};

export const useGetOverviewTotalSales = (params: OverviewTotalSalesParams) => {
  return useQuery({
    ...reactQueryOptions,
    queryKey: ["overview-total-sales", params],
    queryFn: () => getOverviewChart(params),
  });
};

export const useGetOverviewTopProducts = (
  params: OverviewTopProductsParams
) => {
  return useQuery({
    ...reactQueryOptions,
    queryKey: ["overview-top-products", params],
    queryFn: () => getOverviewTopItems(params),
  });
};

export const useGetOverviewOrders = (params: OverviewOrdersParams) => {
  return useQuery({
    ...reactQueryOptions,
    queryKey: ["overview-orders", params],
    queryFn: () => getOverviewOrders(params),
  });
};
