import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { reactQueryOptions } from "src/config/reactQuery.config";
import {
  getPaymentMethods,
  getPOSBuyerPromoCodes,
  getPOSBuyers,
  getPOSItemDetail,
  getPOSItems,
} from "src/services/POS.service";
import {
  POSBuyerDetailPromoCodeParams,
  POSBuyersParams,
  POSItemDetailParams,
  POSItemsParams,
} from "./interfaces";

export const useGetPOSItems = (params: POSItemsParams) => {
  return useInfiniteQuery({
    ...reactQueryOptions,
    queryKey: ["pos-items", params],
    queryFn: (pageParam) => getPOSItems({ ...pageParam, ...params }),
    initialPageParam: { limit: 10, offset: 0 },
    getNextPageParam: (lastPage: any, pages) => {
      return {
        limit: 10,
        offset: lastPage.prevOffset + 10,
      };
    },
  });
};

export const useGetItemVariants = (params: POSItemsParams) => {
  return useQuery({
    ...reactQueryOptions,
    queryKey: ["item-variant", params],
    queryFn: () =>
      getPOSItems({
        ...params,
        pageParam: {
          limit: 10,
          offset: 0,
        },
      }),
  });
};

export const useGetPOSItemDetail = (params: POSItemDetailParams) => {
  return useQuery({
    ...reactQueryOptions,
    refetchOnWindowFocus: false,
    queryKey: ["pos-items-detail", params],
    queryFn: () => getPOSItemDetail(params),
  });
};

export const useGetPOSBuyers = (params: POSBuyersParams) => {
  return useQuery({
    ...reactQueryOptions,
    queryKey: ["pos/buyers", params],
    queryFn: () => getPOSBuyers(params),
  });
};

export const useGetPOSBuyerPromoCodes = (
  params: POSBuyerDetailPromoCodeParams
) => {
  return useQuery({
    ...reactQueryOptions,
    queryKey: ["pos/buyers/promo-code", params],
    queryFn: () => getPOSBuyerPromoCodes(params),
  });
};

export const useGetPaymentMethods = () => {
  return useQuery({
    ...reactQueryOptions,
    queryKey: ["payment-methods"],
    queryFn: () => getPaymentMethods(),
  });
};
