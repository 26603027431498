import { useContext } from "react";
import { Socket } from "socket.io-client";
import { SocketContext } from "src/context/SocketContext";

export const useSocket = (): Socket => {
  const socket = useContext(SocketContext);

  if (!socket) {
    throw new Error("Socket must be used within a SocketProvider");
  }

  return socket;
};
