import { useQuery } from "@tanstack/react-query";
import { reactQueryOptions } from "src/config/reactQuery.config";
import {
  getProductPackage,
  getProductPackageDetail,
  getProductPackageVariantList,
} from "src/services/ProductPackage.service";
import {
  ProductPackageDetailRequestBody,
  ProductPackageParams,
  ProductPackageVariantListParams,
} from "./interfaces";

export const useGetProductPackage = (params: ProductPackageParams) => {
  return useQuery({
    ...reactQueryOptions,
    queryKey: ["product-package", params],
    queryFn: () => getProductPackage(params),
  });
};

export const useGetProductPackageDetail = (
  requestBody: ProductPackageDetailRequestBody
) => {
  return useQuery({
    ...reactQueryOptions,
    queryKey: ["product-package-detail", requestBody],
    queryFn: () => getProductPackageDetail(requestBody),
  });
};

export const useGetProductPackageVariantList = (
  params: ProductPackageVariantListParams
) => {
  return useQuery({
    ...reactQueryOptions,
    queryKey: ["product-package-variant-list", params],
    queryFn: () => getProductPackageVariantList(params),
  });
};
