import {
  OverviewCardParams,
  OverviewCardResponse,
  OverviewOrdersParams,
  OverviewOrdersResponse,
  OverviewTopProductsParams,
  OverviewTopProductsResponse,
  OverviewTotalSalesParams,
  OverviewTotalSalesResponse,
} from "src/pages/Overview/api/interfaces";
import API from ".";

const featureApiRoute = "/overviews";

export const getOverviewCard = async ({
  interval,
}: OverviewCardParams): Promise<OverviewCardResponse> => {
  const response = await API.get<OverviewCardResponse>(
    `${featureApiRoute}/card`,
    {
      params: {
        interval,
      },
    }
  );

  return response.data;
};

export const getOverviewChart = async ({
  interval,
}: OverviewTotalSalesParams): Promise<OverviewTotalSalesResponse> => {
  const response = await API.get<OverviewTotalSalesResponse>(
    `${featureApiRoute}/chart`,
    {
      params: {
        interval,
      },
    }
  );

  return response.data;
};

export const getOverviewTopItems = async ({
  interval,
  categoryId,
  categoryType,
}: OverviewTopProductsParams): Promise<OverviewTopProductsResponse> => {
  const response = await API.get<OverviewTopProductsResponse>(
    `${featureApiRoute}/top-items`,
    {
      params: {
        interval,
        ...(categoryId ? { category_id: categoryId } : {}),
        ...(categoryType ? { category_type: categoryType } : {}),
      },
    }
  );

  return response.data;
};

export const getOverviewOrders = async ({
  tab,
}: OverviewOrdersParams): Promise<OverviewOrdersResponse> => {
  const response = await API.get<OverviewOrdersResponse>(
    `${featureApiRoute}/orders`,
    {
      params: {
        tab,
      },
    }
  );

  return response.data;
};
