export const tokenNames = {
  token: "token",
  refreshToken: "refresh-token",
};

export const getAuthTokens = () => {
  const token = localStorage.getItem(tokenNames["token"]);
  const refreshToken = localStorage.getItem(tokenNames["refreshToken"]);

  return {
    token,
    refreshToken,
  };
};
