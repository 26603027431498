interface GetPaginationProps {
  limit?: number;
  offset?: number;
}

export const getPagination = ({ limit, offset }: GetPaginationProps) => {
  if (limit === undefined || offset === undefined) return {};

  const page = (offset + limit) / limit;

  return {
    page,
    size: limit,
  };
};
