import { Danger } from "iconsax-react";
import { GoAlertFill } from "react-icons/go";
import DeliveryIcon from "../../../assets/images/delivery_icon.png";

export type ConfirmationModalIconType = "delivery" | "danger";

interface UseConfirmationModalProps {
  iconType?: ConfirmationModalIconType;
}

const useConfirmationModal = ({ iconType }: UseConfirmationModalProps) => {
  const getIcon = () => {
    if (iconType === "delivery") {
      return <img className="w-24" src={DeliveryIcon} alt="icon" />;
    } else if (iconType === "danger") {
      return (
        <Danger
          size={96}
          strokeWidth={1}
          className="text-darkRed-70 border-1"
        />
      );
    }

    return <GoAlertFill className="text-yellowIndicator-30 text-6xl" />;
  };

  return { icon: getIcon() };
};

export default useConfirmationModal;
