"use client";

import { adminPasswordMinSchema, adminPasswordSchema } from "src/validators";
import { z } from "zod";

const changePasswordForm = {
  password: adminPasswordSchema,
  confirmPassword: z.string().min(1, { message: "Required" }),
};

export const changePasswordFormSchema = z
  .object({
    ...changePasswordForm,
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Passwords must match",
    path: ["confirmPassword"],
  });

export const changeMyPasswordFormSchema = z
  .object({
    ...changePasswordForm,
    oldPassword: adminPasswordMinSchema,
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Passwords must match",
    path: ["confirmPassword"],
  });
