import { AddSquare } from "iconsax-react";
import React from "react";
import { UseFormReturn } from "react-hook-form";
import CustomInput from "src/components/CustomInput";
import CustomerAndPartnerSelection from "src/components/CustomerAndPartnerSelection";
import { Button } from "src/components/ui/button";
import { Form } from "src/components/ui/form";
import { formatPhoneNumber } from "src/utils/formatPhoneNumber";
import POSAddProductIcon from "../../assets/images/pos_add_product_icon.png";
import POSCartCard from "./POSCartCard";
import useCartSectionHandler from "./hooks/useCartSectionHandler";
import {
  POSCartItemType,
  SelectedPackageProductType,
} from "./hooks/usePOSHandler";
import { PosCartSchemaType } from "./posCartSchema";
import { capitalize } from "src/utils/commons";
import { USER_TYPE_BACKGROUND } from "src/constant";
import { convertToCurrency } from "src/utils/convertToCurrency";
import Spinner from "src/components/Spinner";

interface CartSectionProps {
  isEditOrder: boolean;
  transactionId: number | undefined;
  isLoading: boolean;
  isSubmitting: boolean;
  form: UseFormReturn<PosCartSchemaType, any, undefined>;
  posCartItems: POSCartItemType[];
  selectedPackageProducts: SelectedPackageProductType[];
  handleEditOrderItem: ({
    orderItem,
    index,
  }: {
    orderItem: PosCartSchemaType["orders"][number];
    index: number;
  }) => void;
  handleShowRepeatTransactionModal: () => void;
  handleGoToPaymentSummary: () => void;
  handleScanQR: () => void;
}

const CartSection: React.FC<CartSectionProps> = ({
  isEditOrder,
  transactionId,
  isLoading,
  isSubmitting,
  form,
  posCartItems,
  selectedPackageProducts,
  handleEditOrderItem,
  handleShowRepeatTransactionModal,
  handleGoToPaymentSummary,
  handleScanQR,
}) => {
  const {
    selectedCustomer,
    businessOperationOptions,
    handleAddExistingCustomerInfo,
    handleDeleteOrderItem,
    handleNext,
  } = useCartSectionHandler({
    isEditOrder,
    transactionId,
    form,
    handleShowRepeatTransactionModal,
    handleGoToPaymentSummary,
  });

  const formValues = form.watch();

  const selectedCustomerName = selectedCustomer?.name;
  const selectedCustomerPhoneNumber = formatPhoneNumber(
    selectedCustomer?.phoneNumber
  );
  const selectedUserType = selectedCustomer?.type;
  const selectedCustomerType = selectedCustomer?.customerType;
  const selectedCustomerTypeHex = selectedCustomer?.hex ?? "";

  const orderItems = formValues.orders;
  const isEmpty = orderItems.length === 0;

  const isDisableGoToPaymentSummary =
    isEmpty || !selectedCustomer || !formValues.businessOperationId;
  const totalItemOrdersPrice = orderItems
    .map((orderItem) => {
      const totalPrice = Math.max(
        parseInt(orderItem.price, 0) *
          parseInt(`${orderItem.warehouse.quantity}`, 0) -
          parseInt(`${orderItem.discount}`, 0),
        0
      );

      return totalPrice;
    })
    .reduce((a, b) => a + b, 0);
  const totalOrder = convertToCurrency(totalItemOrdersPrice);

  return (
    <div className="w-1/3">
      <Form {...form}>
        <div
          className="bg-white flex flex-col gap-4 lg:gap-6 m-4 xl:m-6 !ml-0 rounded-2xl border overflow-hidden"
          style={{
            minHeight: "50vh",
          }}
        >
          <div className="flex justify-between items-center bg-primary-50 p-5 lg:gap-6">
            <span className="text-lg font-semibold text-white">Cart</span>

            <div className="w-1/3">
              <CustomInput
                form={form}
                name="businessOperationId"
                placeholder="Select Business Operation"
                variant="select"
                options={businessOperationOptions}
                onChange={(value) => {
                  form.setValue(
                    "businessOperationName",
                    businessOperationOptions.find(
                      (businessOperationOption) =>
                        `${businessOperationOption.value}` === `${value}`
                    )?.label ?? ""
                  );
                }}
              />
            </div>
          </div>

          <div className="flex flex-col items-start pb-3 -my-3 gap-4">
            <div className="w-full px-4 flex flex-col gap-4">
              <div className="flex flex-col items-start w-full">
                <span className="text-md font-normal mb-2">Customer Info</span>

                <CustomerAndPartnerSelection
                  onValueChange={handleAddExistingCustomerInfo}
                />
              </div>

              {selectedCustomer && (
                <div className="flex items-center gap-1 justify-between w-full border rounded-lg px-3 py-2">
                  <span className="text-sm font-medium">
                    {selectedCustomerName}
                  </span>

                  <div className="flex flex-col items-end gap-1">
                    <div
                      className="font-normal text-xs px-1 rounded"
                      style={{
                        backgroundColor:
                          selectedUserType === "customer"
                            ? selectedCustomerTypeHex
                            : selectedUserType === "partner"
                            ? USER_TYPE_BACKGROUND.Partner
                            : USER_TYPE_BACKGROUND.Vendor,
                      }}
                    >
                      {selectedUserType === "customer"
                        ? selectedCustomerType
                        : capitalize(selectedUserType)}
                    </div>

                    <span className="text-sm text-darkIndicator-30">
                      {selectedCustomerPhoneNumber}
                    </span>
                  </div>
                </div>
              )}

              <hr className="w-full" />

              <div className="flex items-center justify-between w-full">
                <span className="text-md font-normal">Order List</span>

                {!isEmpty && <Button onClick={handleScanQR}>Scan QR</Button>}
              </div>
            </div>

            <div className="w-full flex flex-col">
              <div
                className="px-4 overflow-auto gap-4 pb-4"
                style={{ minHeight: "40vh", maxHeight: "80vh" }}
              >
                <div
                  className={`flex flex-col ${
                    isEmpty || isLoading
                      ? "items-center justify-center min-h-80"
                      : ""
                  } w-full gap-3`}
                >
                  {isLoading ? (
                    <div className="flex flex-col items-center justify-center gap-3">
                      <Spinner size="large" />
                    </div>
                  ) : isEmpty ? (
                    <div className="flex flex-col items-center justify-center gap-3">
                      <img
                        className="h-16 w-16 -mb-1"
                        src={POSAddProductIcon}
                        alt="icon"
                      />

                      <span className="text-xs font-medium italic text-darkIndicator-40">
                        Select or Scan QR to Add Product
                      </span>

                      <Button onClick={handleScanQR}>Scan QR</Button>
                    </div>
                  ) : (
                    <div className="flex flex-col gap-3">
                      {orderItems.map((orderItem, index) => {
                        const posCartItem = posCartItems.find(
                          (posCartItem) =>
                            posCartItem.id === orderItem.id &&
                            posCartItem.type === orderItem.type
                        );

                        return (
                          <POSCartCard
                            form={form}
                            posCartItem={posCartItem!}
                            orderItem={orderItem}
                            index={index}
                            selectedPackageProducts={selectedPackageProducts}
                            handleDeleteOrderItem={handleDeleteOrderItem}
                            handleEditOrderItem={handleEditOrderItem}
                            key={index}
                          />
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>

              <div className="w-full rounded-t-2xl border overflow-hidden bg-white">
                <div className="flex justify-between bg-primary-50 px-5 py-2 lg:gap-6">
                  <span className="text-sm font-normal text-white">
                    Total Order
                  </span>
                  <span className="text-sm font-medium text-white">
                    {totalOrder}
                  </span>
                </div>

                <div className="px-5 py-3">
                  <CustomInput
                    form={form}
                    name="note"
                    placeholder="Add Order Notes (example: name on flower board, flower color, etc)"
                    textArea
                    maxCharacters={500}
                  />
                </div>

                <div className="px-5 py-3 border-t">
                  <Button
                    type="submit"
                    disabled={isDisableGoToPaymentSummary}
                    isLoading={isSubmitting}
                    className="w-full"
                    onClick={handleNext}
                  >
                    <AddSquare className="mr-2" size={16} /> Add Recipient &
                    Payment Method
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default CartSection;
