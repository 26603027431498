import React from "react";
import NoDataFoundIcon from "../assets/images/no_data_found_icon.png";

interface PageEmptyProps {}

const PageEmpty: React.FC<PageEmptyProps> = () => {
  return (
    <div className="h-full flex flex-col items-center justify-center drop-shadow-2xl py-4">
      <img className="h-14 w-14 mb-3" src={NoDataFoundIcon} alt="icon" />

      <span className="text-darkBrown-default text-sm font-medium opacity-25 italic">
        No Data Found
      </span>
    </div>
  );
};

export default PageEmpty;
