import { useCurrentUser } from "src/pages/SignIn/api/queries";
import { AccessResource, ActionType } from "src/types/accessControl";

interface UseGetAccessPermissionProps {
  resource: AccessResource | null;
  action: ActionType;
}

export const useGetAccessPermission = ({
  resource,
  action,
}: UseGetAccessPermissionProps) => {
  const { data } = useCurrentUser();

  if (resource === null) return true;

  const adminAccess = data?.data?.accesses ?? [];

  const feature = adminAccess.find((access) => access.resource === resource);
  const subFeature = adminAccess
    .flatMap((access) => access?.sub_features)
    .find((subFeature) => subFeature?.resource === resource);

  const isAuthenticated = (feature || subFeature)?.actions?.find(
    (accessAction) => accessAction === action
  );

  return isAuthenticated ? true : false;
};
