import React from "react";
import POS from "../../../POS";
import useEditOrderHandler from "../hooks/useEditOrderHandler";

interface EditOrderProps {}

const EditOrder: React.FC<EditOrderProps> = () => {
  const {
    isLoading,
    formValues,
    posCartItems,
    selectedPackageProducts,
    transactionSummaryFormValues,
  } = useEditOrderHandler();

  return (
    <div>
      <POS
        formValues={formValues}
        posCartItems={posCartItems}
        selectedPackageProducts={selectedPackageProducts}
        transactionSummaryFormValues={transactionSummaryFormValues}
        isLoadingDetailData={isLoading}
      />
    </div>
  );
};

export default EditOrder;
