import { City, Country } from "country-state-city";

interface CountryCodeType {
  countryName: string;
  code: string;
  flag: string;
}

export const getCountryCodes = () => {
  const countries = Country.getAllCountries();

  const countryCodes = countries
    .map((country) => {
      if (!country.flag || !country.phonecode) return null;

      return {
        countryName: country.name,
        code: (country.phonecode.startsWith("+")
          ? country.phonecode
          : `+${country.phonecode}`
        )
          ?.split("-")
          ?.join("")
          ?.split(" and ")[0],
        flag: country.flag,
      };
    })
    .filter((countryCode) => countryCode !== null);

  return countryCodes as CountryCodeType[];
};

export const getCountries = () => {
  const countries = Country.getAllCountries();

  const formattedCountries = countries.map((country) => {
    return {
      label: `${country.flag ? `${country.flag} ` : ""}${country.name}`,
      value: country.name,
      countryCode: country.isoCode,
    };
  });

  return formattedCountries;
};

export const getCountryCities = (countryCode: string = "") => {
  const cities = City.getCitiesOfCountry(countryCode);

  const formattedCities = cities
    ? cities.map((city) => {
        return {
          label: city.name,
          value: city.name,
        };
      })
    : [];

  return formattedCities;
};

export const getCities = () => {
  const cities = City.getAllCities();

  const formattedCities = cities
    ? cities.map((city) => {
        return {
          label: city.name,
          value: city.name,
        };
      })
    : [];

  return formattedCities;
};

export const getCountryOptionsAndCityOptions = ({
  searchCountry,
  searchCity,
}: {
  searchCountry: string;
  searchCity: string;
}) => {
  const countries = getCountries();
  const cities = getCities();

  const countryOptions = countries
    .map((country) => ({
      label: country.label,
      value: country.value,
    }))
    .filter((country) =>
      country.value.toLowerCase().startsWith(searchCountry.toLowerCase())
    )
    .slice(0, 10);
  const cityOptions = cities
    .map((city) => ({
      label: city.label,
      value: city.value,
    }))
    .filter((city) =>
      city.value.toLowerCase().startsWith(searchCity.toLowerCase())
    )
    .slice(0, 10);

  return {
    countryOptions,
    cityOptions,
  };
};
