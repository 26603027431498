import { useEffect, useState, startTransition } from "react";
import { useNavigate } from "react-router-dom";
import { NavItemSubMenuProps } from "../NavItem";

interface useNavItemProps {
  multiple?: boolean;
  routePath?: string;
  subMenus?: NavItemSubMenuProps[];
}

const useNavItem = ({
  multiple,
  routePath = "",
  subMenus = [],
}: useNavItemProps) => {
  const navigate = useNavigate();

  // State for active menu item and dropdown visibility
  const [isActive, setIsActive] = useState(false);
  const [isShowDetail, setIsShowDetail] = useState(false);
  const [activeSubMenu, setActiveSubMenu] = useState<string | null>(null);

  const handleNavigate = (pathName: string) => {
    startTransition(() => {
      navigate(pathName);
    });
  };

  const handleShowDetail = () => setIsShowDetail(!isShowDetail);

  const handleMenuClick = (pathName: string) => {
    setIsActive(true);
    handleNavigate(pathName);
  };

  const handleSubMenuClick = (pathName: string) => {
    setActiveSubMenu(pathName);
    setIsActive(true); // Set the parent menu item as active
    handleNavigate(pathName);
  };

  useEffect(() => {
    // Check if the current route path matches the current window location or dynamic route
    if (routePath && window.location.pathname.startsWith(routePath)) {
      setIsActive(true);
    }
    subMenus.forEach((subMenu) => {
      if (subMenu.routePath === window.location.pathname) {
        setActiveSubMenu(subMenu.routePath);
        setIsShowDetail(true);
        setIsActive(true); // Set the parent menu item as active
      }
    });
  }, [routePath, subMenus]);

  return {
    isActive,
    isShowDetail,
    activeSubMenu,
    handleMenuClick,
    handleShowDetail,
    handleSubMenuClick,
  };
};

export default useNavItem;
