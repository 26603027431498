import { format } from "date-fns";
import React from "react";
import { DropdownMenuItem } from "src/components/ui/dropdown-menu";
import { OverviewNotification } from "src/pages/Overview/api/interfaces";
import useNotificationNavigation from "src/pages/Overview/hooks/useNotificationNavigation";

interface NotificationDropdownMenuItemProps {
  notification: OverviewNotification;
}

const NotificationDropdownMenuItem: React.FC<
  NotificationDropdownMenuItemProps
> = ({ notification }) => {
  const { handleNavigateNotification } = useNotificationNavigation({
    notification,
  });

  return (
    <DropdownMenuItem
      className="flex flex-col gap-1.5 items-start border-b py-3"
      onClick={handleNavigateNotification}
    >
      <span className="text-md text-darkBrown-default font-semibold leading-3">
        {notification.content}
      </span>
      <span className="text-sm text-darkBrown-default">
        {format(notification.created_at, "dd/MM/yyyy HH:mm")}
      </span>
    </DropdownMenuItem>
  );
};

export default NotificationDropdownMenuItem;
