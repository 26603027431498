import React from "react";
import SortDropDown from "src/components/SortDropDown";
import LayoutWithSideBar from "src/layouts/LayoutWithSideBar";
import { AccessResource, ActionType } from "src/types/accessControl";

import ConfirmationModal from "src/components/ConfirmationModal";
import GroupSearchInput from "src/components/GroupSearchInput";
import POSCustomTabs from "src/components/POSCustomTabs";
import ScanQRModal from "src/components/ScanQRModal";
import Spinner from "src/components/Spinner";
import AddProductModal from "./AddProductModal";
import CartSection from "./CartSection";
import usePOSHandler, {
  POSCartItemType,
  SelectedPackageProductType,
} from "./hooks/usePOSHandler";
import { PosCartSchemaType } from "./posCartSchema";
import POSContainer from "./POSContainer";
import { TransactionSummarySchemaType } from "./TransactionSummary/transactionSummarySchema";

export interface POSProps {
  formValues?: PosCartSchemaType;
  posCartItems?: POSCartItemType[];
  selectedPackageProducts?: SelectedPackageProductType[];
  transactionSummaryFormValues?: TransactionSummarySchemaType;
  isLoadingDetailData?: boolean;
}

const POS: React.FC<POSProps> = (props) => {
  const {
    isEditOrder,
    transactionId,
    posItems,
    isLoading,
    isSubmitting,
    form,
    sort,
    setSort,
    selectedPOSItem,
    selectedPosOrderItem,
    selectedPosOrderItemIndex,
    posCartItems,
    setPOSCartItems,
    selectedPackageProducts,
    setSelectedPackageProducts,
    isShowScanQRModal,
    isShowAddProductModal,
    isShowRepeatTransactionModal,
    handleClickSubCategory,
    promoCodeSortOptions,
    tabs,
    fetchNextPage,
    hasNextPage,
    handleAddProductToCart,
    handleCloseAddProductModal,
    handleEditOrderItem,
    handleShowRepeatTransactionModal,
    handleCloseRepeatTransactionModal,
    handleGoToPaymentSummary,
    handleSelectSearchedItem,
    handleScanQR,
    handleCloseScanQRModal,
    handleSuccessScanQR,
  } = usePOSHandler({ ...props });

  return (
    <div
    // className="flex gap-5 max-h-screen overflow-hidden"
    >
      <LayoutWithSideBar
        title=""
        resource={AccessResource["POS"]}
        action={ActionType["Allow"]}
        header={
          <div className="flex w-full justify-center">
            <div className="w-2/3">
              <GroupSearchInput
                selectedIds={[]}
                onValueChange={handleSelectSearchedItem}
                type="all"
              />
            </div>
          </div>
        }
        headerAction={
          <div className="flex w-full gap-5 -mb-6">
            <div className="flex-auto">
              <POSCustomTabs
                defaultTab={tabs[0]}
                tabs={tabs}
                onClick={handleClickSubCategory}
              />
            </div>

            <div>
              <SortDropDown
                sort={sort}
                setSort={setSort}
                options={promoCodeSortOptions}
              />
            </div>
          </div>
        }
        children2={
          <CartSection
            isEditOrder={isEditOrder}
            transactionId={transactionId}
            isLoading={props.isLoadingDetailData ?? false}
            isSubmitting={isSubmitting}
            form={form}
            posCartItems={posCartItems}
            selectedPackageProducts={selectedPackageProducts}
            handleEditOrderItem={handleEditOrderItem}
            handleShowRepeatTransactionModal={handleShowRepeatTransactionModal}
            handleGoToPaymentSummary={handleGoToPaymentSummary}
            handleScanQR={handleScanQR}
          />
        }
      >
        {!isLoading ? (
          <POSContainer
            data={posItems}
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
            handleAddProductToCart={handleAddProductToCart}
          />
        ) : (
          <div
            className="flex justify-center items-center"
            style={{ minHeight: "70vh" }}
          >
            <Spinner size="large" />
          </div>
        )}
      </LayoutWithSideBar>

      {selectedPOSItem && (
        <AddProductModal
          isEditOrder={isEditOrder}
          transactionId={transactionId}
          isShow={isShowAddProductModal}
          onClose={handleCloseAddProductModal}
          selectedPOSItem={selectedPOSItem}
          selectedPosOrderItem={selectedPosOrderItem}
          selectedPosOrderItemIndex={selectedPosOrderItemIndex}
          selectedPackageProducts={selectedPackageProducts}
          setSelectedPackageProducts={setSelectedPackageProducts}
          posForm={form}
          setPOSCartItems={setPOSCartItems}
        />
      )}

      <ConfirmationModal
        isShow={isShowRepeatTransactionModal}
        onClose={handleCloseRepeatTransactionModal}
        onSubmit={handleGoToPaymentSummary}
        title="Repeat Transaction?"
        description="You just made a transaction with the same customer. do you still want to continue?"
        cancelButtonTitle="Cancel Order"
        submitButtonTitle="Continue Order"
        cancelButtonPosition="first"
        footerAlignment="between"
      />

      <ScanQRModal
        isShow={isShowScanQRModal}
        onClose={handleCloseScanQRModal}
        onSuccess={handleSuccessScanQR}
      />
    </div>
  );
};

export default POS;
