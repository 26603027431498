import { zodResolver } from "@hookform/resolvers/zod";
import { startTransition, useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { GroupSearchInputOption } from "src/components/GroupSearchInput/hooks/useSearchInput";
import { ProductVariant } from "src/pages/ProductManagement/ProductList/api/interfaces";
import { RoutePaths } from "src/pages/routePaths";
import { checkPOSCart } from "src/services/POS.service";
import { apiErrorHandler } from "src/utils/apiErrorHandler";
import { POSProps } from "..";
import { PosItemDetailProductStock } from "../api/interfaces";
import { useGetPOSItems } from "../api/queries";
import { posCartSchema, PosCartSchemaType } from "../posCartSchema";

export const posSortOptions = [
  {
    label: "Most Purchased Product",
    value: "most-purchased-product",
  },
  {
    label: "Least Purchased Product",
    value: "least-purchased-product",
  },
  {
    label: "Latest Purchased Product",
    value: "latest-purchased-product",
  },
  {
    label: "Variant Name A-Z",
    value: "name-asc",
  },
];

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const posSortOptionsValue = [
  "most-purchased-product",
  "least-purchased-product",
  "latest-purchased-product",
  "name-asc",
] as const;

export type POSSortValueType = (typeof posSortOptionsValue)[number];

export const getPOSSortingParams = (type: POSSortValueType) => {
  let params = {};

  if (type === "most-purchased-product") {
    params = {
      sort_by: "purchased",
      sort_order: "desc",
    };
  } else if (type === "least-purchased-product") {
    params = {
      sort_by: "purchased",
      sort_order: "asc",
    };
  } else if (type === "latest-purchased-product") {
    params = {
      sort_by: "purchase_date",
      sort_order: "desc",
    };
  } else if (type === "name-asc") {
    params = {
      sort_by: "name",
      sort_order: "asc",
    };
  }

  return params;
};

export type POSItemType = "product" | "package";

export type SelectedPOSItemType = {
  id: number;
  type: POSItemType;
};

export type SelectedPackageProductType = {
  id: number;
  packageVariantId: number;
  productName: string;
  variantName: string;
  itemStockUnit: string;
  stocks: PosItemDetailProductStock[];
  quantity: number;
};

export interface POSCartItemType {
  id: number;
  type: "product" | "package";
  sub_category: {
    hex: string;
  };
  image_url: string;
  product:
    | {
        name: string;
      }
    | undefined;
  package:
    | {
        name: string;
      }
    | undefined;
  name: string;
  stocks: {
    warehouse: {
      id: number;
      name: string;
    };
  }[];
  available: number;
  is_purchase: boolean;
  is_rent: boolean;
  item_stock_unit: { name: string };
  products: {
    id: number;
    warehouseName: string;
  }[];
}

export const defaultPOSCartValues = {
  customer: undefined,
  orders: [],
  note: "",
  hasRent: false,
};

interface UsePOSHandlerProps extends POSProps {}

const usePOSHandler = ({
  formValues: formValuesProps,
  posCartItems: posCartItemsProps,
  selectedPackageProducts: selectedPackageProductsProps,
  transactionSummaryFormValues,
}: UsePOSHandlerProps) => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const isEditOrder = formValuesProps !== undefined;
  const transactionId = formValuesProps?.id;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isShowScanQRModal, setIsShowScanQRModal] = useState(false);
  const [subCategory, setSubCategory] = useState<{
    id: number | "*";
    type: "all" | POSItemType;
  }>({
    id: "*",
    type: "all",
  });
  const [sort, setSort] = useState(posSortOptions[0].value);

  const [isShowAddProductModal, setIsShowAddProductModal] = useState(false);
  const [posCartItems, setPOSCartItems] = useState<POSCartItemType[]>([]);

  const [selectedPosOrderItem, setSelectedPOSOrderItem] =
    useState<PosCartSchemaType["orders"][number]>();
  const [selectedPosOrderItemIndex, setSelectedPosOrderItemIndex] = useState<
    number | null
  >(null);

  const [selectedPackageProducts, setSelectedPackageProducts] = useState<
    SelectedPackageProductType[]
  >([]);
  const [selectedPOSItem, setSelectedPOSItem] = useState<{
    id: number;
    type: POSItemType;
  }>();

  const [isShowRepeatTransactionModal, setIsShowRepeatTransactionModal] =
    useState(false);

  const {
    data: dataInfinite,
    fetchNextPage,
    isLoading,
  } = useGetPOSItems({
    offset: 0,
    subCategoryId: subCategory.id,
    subCategoryType: subCategory.type,
    sortBy: sort,
  });

  const data = dataInfinite?.pages?.reduce((prevValue: any, page: any) => {
    return [...prevValue, page];
  }, []);
  const totalCount = Array.isArray(data) ? data[0]?.meta?.total : 0;
  const posSubCategories: { id: number; name: string; type: POSItemType }[] =
    Array.isArray(data) ? data[0]?.sub_categories : [];
  const posItems: any = data?.flatMap((d: any) => d?.data);
  const hasNextPage = totalCount > posItems?.length;

  const form = useForm<PosCartSchemaType>({
    resolver: zodResolver(posCartSchema),
    defaultValues: defaultPOSCartValues,
  });

  const posSubCategoryOptions =
    posSubCategories.map((productSubCategory) => ({
      label: productSubCategory.name,
      value: `${productSubCategory.id}`,
      type: productSubCategory.type,
    })) ?? [];

  const subCategoryTabs = [
    "All",
    ...posSubCategoryOptions.map(
      (posSubCategoryOption) => posSubCategoryOption.label
    ),
  ];

  const handleClickSubCategory = (index: number) => {
    const selectedSubCategoryTab = posSubCategoryOptions[index - 1];

    setSubCategory({
      id: index === 0 ? "*" : parseInt(selectedSubCategoryTab.value),
      type: index === 0 ? "all" : selectedSubCategoryTab.type,
    });
  };

  const handleAddProductToCart = (value: { id: number; type: POSItemType }) => {
    setSelectedPOSItem(value);
    setIsShowAddProductModal(true);
  };

  const handleCloseAddProductModal = () => {
    setIsShowAddProductModal(false);
    setSelectedPOSItem(undefined);
    setSelectedPOSOrderItem(undefined);
    setSelectedPosOrderItemIndex(null);
  };

  const handleEditOrderItem = ({
    orderItem,
    index,
  }: {
    orderItem: PosCartSchemaType["orders"][number];
    index: number;
  }) => {
    setSelectedPOSOrderItem(orderItem);
    setSelectedPosOrderItemIndex(index);
    handleAddProductToCart({
      id: orderItem.id,
      type: orderItem.type,
    });
  };

  const handleShowRepeatTransactionModal = () =>
    setIsShowRepeatTransactionModal(true);
  const handleCloseRepeatTransactionModal = () =>
    setIsShowRepeatTransactionModal(false);

  const handleGoToPaymentSummary = async () => {
    setIsSubmitting(true);

    const formValues = form.watch();

    try {
      const { data } = await checkPOSCart({
        form: formValues,
        edit: isEditOrder ? 1 : 0,
        transactionId,
      });

      if (data.all_available) {
        startTransition(() =>
          navigate(RoutePaths.POSTransactionSummary, {
            state: {
              form: JSON.stringify(formValues),
              posCartItems: JSON.stringify(posCartItems),
              selectedPackageProducts: JSON.stringify(selectedPackageProducts),
              transactionSummaryForm: isEditOrder
                ? JSON.stringify({
                    ...transactionSummaryFormValues,
                    ...formValues,
                  })
                : undefined,
              isEditOrder,
              orderId: formValuesProps?.id,
            },
            replace: true,
          })
        );
      } else {
        apiErrorHandler({ error: "Out of Stocks!" });
      }

      setIsSubmitting(false);
    } catch (error) {
      apiErrorHandler({ error, form });
      setIsSubmitting(false);
    }
  };

  const handleSelectSearchedItem = (option: GroupSearchInputOption) => {
    handleAddProductToCart({
      id: parseInt(`${option.value}`),
      type: option.type,
    });
  };

  const handleScanQR = () => setIsShowScanQRModal(true);
  const handleCloseScanQRModal = () => setIsShowScanQRModal(false);
  const handleSuccessScanQR = (productVariant: ProductVariant) => {
    const pv = productVariant as ProductVariant & { type: POSItemType };
    handleAddProductToCart({
      id: pv.id,
      type: pv.type,
    });
  };

  useEffect(() => {
    if (state) {
      form.reset(JSON.parse(state?.form));
      setPOSCartItems(JSON.parse(state?.posCartItems));
      setSelectedPackageProducts(JSON.parse(state?.selectedPackageProducts));
    }
  }, [state, form]);

  const initialValueForEditOrder = useCallback(() => {
    form.reset(formValuesProps);

    if (posCartItemsProps) setPOSCartItems(posCartItemsProps);
    if (selectedPackageProductsProps)
      setSelectedPackageProducts(selectedPackageProductsProps);
  }, [form, formValuesProps, posCartItemsProps, selectedPackageProductsProps]);

  useEffect(() => {
    initialValueForEditOrder();
  }, [initialValueForEditOrder]);

  return {
    isEditOrder,
    transactionId,
    posItems,
    isLoading,
    isSubmitting,
    form,
    sort,
    setSort,
    selectedPOSItem,
    selectedPosOrderItem,
    selectedPosOrderItemIndex,
    posCartItems,
    setPOSCartItems,
    selectedPackageProducts,
    setSelectedPackageProducts,
    isShowScanQRModal,
    isShowAddProductModal,
    isShowRepeatTransactionModal,
    handleClickSubCategory,
    promoCodeSortOptions: posSortOptions,
    tabs: subCategoryTabs,
    fetchNextPage,
    hasNextPage,
    handleAddProductToCart,
    handleCloseAddProductModal,
    handleEditOrderItem,
    handleShowRepeatTransactionModal,
    handleCloseRepeatTransactionModal,
    handleGoToPaymentSummary,
    handleSelectSearchedItem,
    handleScanQR,
    handleCloseScanQRModal,
    handleSuccessScanQR,
  };
};

export default usePOSHandler;
