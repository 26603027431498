import { ProductVariant } from "src/pages/ProductManagement/ProductList/api/interfaces";
import { PackageVariant } from "src/pages/ProductManagement/ProductPackage/api/interfaces";
import API from ".";

interface Response {
  data: ProductVariant | PackageVariant;
  success: boolean;
  type: "product" | "package";
}

const featureApiRoute = "/qrs";

export const getQRDetail = async (qrValue: string): Promise<Response> => {
  const response = await API.get<Response>(`${featureApiRoute}/${qrValue}`);

  return response.data;
};
