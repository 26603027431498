import React, { ReactNode, useEffect, useRef, useState } from "react";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../ui/tabs";

interface POSCustomTabsProps {
  defaultTab: string;
  tabs: string[];
  contents?: {
    [key: string]: ReactNode;
  };
  arrayContents?: ReactNode[];
  onClick?: (index: number) => void;
}

const POSCustomTabs: React.FC<POSCustomTabsProps> = ({
  defaultTab,
  tabs,
  contents,
  arrayContents,
  onClick = () => {},
}) => {
  const ref = useRef(null);
  const [tabWidth, setTabWidth] = useState(0);

  const contentKeys = contents ? Object.keys(contents) : [];

  useEffect(() => {
    const observer = new ResizeObserver(() => {
      if (ref.current) {
        setTabWidth((ref.current as any).offsetWidth);
      }
    });

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(ref.current);
      }
    };
  }, []);

  return (
    <Tabs ref={ref} defaultValue={defaultTab}>
      <TabsList
        className={`no-scrollbar bg-white overflow-x-scroll overflow-y-hidden py-0`}
        style={{
          maxWidth: tabWidth ? tabWidth - 4 : 0,
        }}
      >
        {tabs.map((tab, index) => (
          <TabsTrigger
            className="py-3.5 data-[state=active]:text-white"
            value={tab}
            key={index}
            onClick={() => onClick(index)}
          >
            {tab}
          </TabsTrigger>
        ))}
      </TabsList>

      <div className="my-5">
        {contents &&
          contentKeys.map((contentKey, index) => (
            <TabsContent value={contentKey} key={index}>
              {contents[contentKey]}
            </TabsContent>
          ))}

        {arrayContents &&
          arrayContents.map((arrayContent, index) => (
            <TabsContent value={tabs[index]} key={index}>
              {arrayContent}
            </TabsContent>
          ))}
      </div>
    </Tabs>
  );
};

export default POSCustomTabs;
