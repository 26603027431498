import NavItem from "./NavItem";

import useSideBar from "./hooks/useSideBar";

interface SideBarProps {
  isOpen: boolean;
}

const SideBar = ({ isOpen }: SideBarProps) => {
  const {
    menus,
    appLogo1,
    appLogo2,
    isChangeLogPage,
    handleNavigateToChangeLog,
    appVersion,
  } = useSideBar();

  return (
    <div
      className={`h-screen pb-8 overflow-y-auto no-scrollbar sticky top-0 left-0 bg-primary transform transition-transform duration-300 w-[220px] lg:w-[280px] ease-in-out border-r !sm-absolute z-50 flex flex-col justify-between items-center ${
        isOpen ? "translate-x-0" : "-translate-x-full"
      }`}
    >
      <div className="flex flex-col">
        <div className="bg-background-100 flex flex-col h-35 items-center justify-center gap-2 px-4 lg:h-[142px] lg:px-12 bg-background-300">
          <img
            className={appLogo2 ? "h-14" : "h-40"}
            src={appLogo1}
            alt="logo1"
          />
          {appLogo2 && <img className="h-14" src={appLogo2} alt="logo2" />}
        </div>

        <div className="flex-1 overflow-y-auto py-4">
          <nav
            className={`grid items-start font-medium ${
              isOpen ? "opacity-100" : "opacity-0"
            } transition-opacity duration-300 ease-in-out gap-3`}
          >
            {menus.map((menu, index) => (
              <NavItem {...menu} key={index} />
            ))}
          </nav>
        </div>
      </div>

      <div
        className={`p-3 px-10 rounded-lg text-center mt-8 cursor-pointer text-primary-100 font-medium hover:bg-secondary ${
          isChangeLogPage ? "bg-secondary" : ""
        }`}
        onClick={handleNavigateToChangeLog}
      >
        <span>{`Version ${appVersion}`}</span>
      </div>
    </div>
  );
};

export default SideBar;
