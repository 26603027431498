import { zodResolver } from "@hookform/resolvers/zod";
import { UseMutationResult } from "@tanstack/react-query";
import { useForm, UseFormReturn } from "react-hook-form";
import {
  ChangeMyPasswordFormType,
  ChangeMyPasswordRequestBody,
  ChangeMyPasswordResponse,
  ChangeOtherPasswordFormType,
  ChangeOtherPasswordRequestBody,
  ChangeOtherPasswordResponse,
} from "src/pages/AdminManagement/AdminList/api/interfaces";
import {
  useChangeMyPassword,
  useChangeOtherPassword,
} from "src/pages/AdminManagement/AdminList/api/mutations";
import { apiErrorHandler } from "src/utils/apiErrorHandler";
import { z } from "zod";
import {
  changeMyPasswordFormSchema,
  changePasswordFormSchema,
} from "../changePasswordSchema";

interface UseChangePasswordModalHandlerProps {
  adminId: number;
  type: "OTHER" | "MINE";
  handleClose: () => void;
}

interface HandleChangeOtherPasswordProps {
  adminId: number;
  form: UseFormReturn<ChangeOtherPasswordFormType, any, undefined>;
  mutation: UseMutationResult<
    ChangeOtherPasswordResponse,
    Error,
    ChangeOtherPasswordRequestBody,
    unknown
  >;

  handleClose: () => void;
}

interface HandleChangeMyPasswordProps {
  adminId: number;
  form: UseFormReturn<ChangeMyPasswordFormType, any, undefined>;
  mutation: UseMutationResult<
    ChangeMyPasswordResponse,
    Error,
    ChangeMyPasswordRequestBody,
    unknown
  >;
  handleClose: () => void;
}

const handleChangeOtherPassword = async ({
  adminId,
  form,
  mutation,
  handleClose,
}: HandleChangeOtherPasswordProps) => {
  const values = form.getValues();

  const payload = {
    id: adminId,
    password: values.password,
    confirmPassword: values.confirmPassword,
  };

  try {
    await mutation.mutateAsync(payload);

    handleClose();
  } catch (error) {
    apiErrorHandler({ error, form });
  }
};

const handleChangeMyPassword = async ({
  adminId,
  form,
  mutation,
  handleClose,
}: HandleChangeMyPasswordProps) => {
  const values = form.getValues();

  const payload = {
    id: adminId,
    oldPassword: values.oldPassword,
    password: values.password,
    confirmPassword: values.confirmPassword,
  };

  try {
    await mutation.mutateAsync(payload);

    handleClose();
  } catch (error) {
    apiErrorHandler({ error, form });
  }
};

const useChangePasswordModalHandler = ({
  adminId,
  type,
  handleClose,
}: UseChangePasswordModalHandlerProps) => {
  const changeOtherPasswordForm = useForm<
    z.infer<typeof changePasswordFormSchema>
  >({
    resolver: zodResolver(changePasswordFormSchema),
  });

  const changeMyPasswordForm = useForm<
    z.infer<typeof changeMyPasswordFormSchema>
  >({
    resolver: zodResolver(changeMyPasswordFormSchema),
  });

  const changeOtherPasswordMutation = useChangeOtherPassword();
  const changeMyPasswordMutation = useChangeMyPassword();

  const form =
    type === "OTHER" ? changeOtherPasswordForm : changeMyPasswordForm;

  const handleCloseModal = () => {
    handleClose();
    form.reset({
      oldPassword: "",
      password: "",
      confirmPassword: "",
    });
  };

  return {
    form,
    handleChangePassword:
      type === "OTHER"
        ? changeOtherPasswordForm.handleSubmit(
            async () =>
              await handleChangeOtherPassword({
                adminId,
                form: changeOtherPasswordForm,
                mutation: changeOtherPasswordMutation,
                handleClose: handleCloseModal,
              })
          )
        : changeMyPasswordForm.handleSubmit(
            async () =>
              await handleChangeMyPassword({
                adminId,
                form: changeMyPasswordForm,
                mutation: changeMyPasswordMutation,
                handleClose: handleCloseModal,
              })
          ),
    handleCloseModal,
  };
};

export default useChangePasswordModalHandler;
