import React from "react";
import LayoutWithSideBar from "src/layouts/LayoutWithSideBar";
import { ActionType } from "src/types/accessControl";
import useWelcomeHandler from "./hooks/useWelcomeHandler";

interface WelcomeProps {}

const Welcome: React.FC<WelcomeProps> = () => {
  const { appName } = useWelcomeHandler();

  return (
    <LayoutWithSideBar
      title="Getting Started"
      resource={null}
      action={ActionType["Allow"]}
    >
      <div className="flex flex-col pb-5">
        <span className="text-lg font-medium">{`Welcome to ${appName}!`}</span>

        <span className="text-md text-darkIndicator-30">
          You currently don’t have permission to access any pages. Please reach
          out to your administrator to request the access you need.
        </span>
      </div>
    </LayoutWithSideBar>
  );
};

export default Welcome;
