import { useForm } from "react-hook-form";
import CustomInput from "../CustomInput";
import { Button } from "../ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../ui/dialog";
import { Form } from "../ui/form";
import {
  createCustomerModalSchema,
  CreateCustomerModalSchemaType,
} from "./createCustomerModalSchema";
import { zodResolver } from "@hookform/resolvers/zod";
import { CustomerAndPartnerSelectionOption } from "./hooks/useCustomerAndPartnerSelectionHandler";
import {
  countryCityDefaultValue,
  defaultCountryCode,
  inputPlaceHolder,
} from "src/constant";
import { useEffect } from "react";
import { getCountries, getCountryCities } from "src/utils/getCountries";

interface CreateCustomerModalProps {
  searchInput: string;
  handleChangeSearchInput: (search: string) => void;
  isShow: boolean;
  onClose: () => void;
  onValueChange: (value: CustomerAndPartnerSelectionOption) => void;
}

const customerDefaultValue = {
  name: "",
  phoneNumber: defaultCountryCode,
  country:
    process.env.REACT_APP_PRE_IS_PRE_FILL_COUNTRY_AND_CITY === "1"
      ? countryCityDefaultValue.country
      : undefined,
  city:
    process.env.REACT_APP_PRE_IS_PRE_FILL_COUNTRY_AND_CITY === "1"
      ? countryCityDefaultValue.city
      : undefined,
  address: undefined,
};

const CreateCustomerModal = ({
  searchInput,
  handleChangeSearchInput,
  isShow,
  onClose,
  onValueChange,
}: CreateCustomerModalProps) => {
  const countries = getCountries();

  const form = useForm<CreateCustomerModalSchemaType>({
    resolver: zodResolver(createCustomerModalSchema),
    defaultValues: {
      ...customerDefaultValue,
      name: searchInput ?? "",
    },
  });

  const formValues = form.getValues();

  const selectedCountry = countries.find(
    (country) => country.value === formValues.country
  );
  const countryCities = getCountryCities(selectedCountry?.countryCode);

  const handleAddCustomer = form.handleSubmit(() => {
    onValueChange({
      label: "",
      value: "",
      name: formValues.name,
      phoneNumber: formValues.phoneNumber,
      country: formValues.country,
      city: formValues.city,
      address: formValues.address,
      type: "customer",
      customerTypeId: "",
      customerType: "",
      typeHex: "",
    });

    handleCloseModal();
  });

  const handleCloseModal = () => {
    onClose();
    form.reset(customerDefaultValue);
  };

  useEffect(() => {
    if (isShow) {
      form.reset({
        ...customerDefaultValue,
        name: searchInput,
      });
      handleChangeSearchInput("");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShow, form]);

  return (
    <Dialog open={isShow} onOpenChange={handleCloseModal}>
      <DialogContent className="min-w-[1000px]">
        <DialogHeader>
          <DialogTitle className="text-md font-semibold text-darkBrown-default">
            Create Customer
          </DialogTitle>
        </DialogHeader>

        <Form {...form}>
          <div className="flex flex-col items-center gap-4 pt-2 pb-5 ">
            <CustomInput
              form={form}
              name="name"
              labelText="Customer Name"
              placeholder="Input name"
            />

            <CustomInput
              form={form}
              name="phoneNumber"
              labelText="Customer Phone Number"
              placeholder={inputPlaceHolder["phone_number"]}
              variant="phone-number"
            />

            <>
              <div className="w-full grid grid-cols-2 gap-4">
                <CustomInput
                  form={form}
                  name="country"
                  labelText="Country"
                  placeholder="Input Country"
                  variant="select"
                  options={countries}
                  onChange={() => {
                    form.setValue("city", "", {
                      shouldValidate: true,
                    });
                    form.setValue("address", "", {
                      shouldValidate: true,
                    });
                  }}
                />

                {countryCities.length > 0 ? (
                  <CustomInput
                    disabled={!formValues.country}
                    form={form}
                    name="city"
                    labelText="City"
                    placeholder="Input City"
                    variant="select"
                    options={countryCities}
                  />
                ) : (
                  <CustomInput
                    disabled={!formValues.country}
                    form={form}
                    name="city"
                    labelText="City"
                    placeholder="Input City"
                  />
                )}
              </div>

              <CustomInput
                form={form}
                name="address"
                labelText="Address (Optional)"
                placeholder="Input Full Address"
              />
            </>
          </div>
        </Form>

        <DialogFooter>
          <Button
            type="submit"
            onClick={handleAddCustomer}
            isLoading={form.formState.isSubmitting}
          >
            Add Customer
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default CreateCustomerModal;
