import { AddCircle } from "iconsax-react";
import React from "react";
import ReactShowMoreText from "react-show-more-text";
import { reactShowMoreTextConfig } from "src/config/reactShowMoreText.config";
import { convertToCurrency } from "src/utils/convertToCurrency";
import { POSItem, POSItemDetailData, Stock } from "./api/interfaces";

interface POSCardProps {
  posItem?: POSItem;
  posItemDetail?: POSItemDetailData;
  isRent?: boolean;
  handleAddProductToCart?: (value: {
    id: number;
    type: "product" | "package";
  }) => void;
  className?: string;
}

const POSCard: React.FC<POSCardProps> = ({
  posItem,
  posItemDetail,
  isRent,
  handleAddProductToCart,
  className,
}) => {
  let itemVariantId: number;
  let itemType: "product" | "package" = "product";
  let imageUrl;
  let subCategoryName;
  let subCategoryColor;
  let itemName;
  let itemVariantName;
  let sellPrice;
  let rentPrice;
  let isPurchase;
  let itemStockUnitName;
  let productAvailableStock = 0;
  let productLowStockReminder = 0;
  let stocks: Stock[] = [];

  if (posItemDetail) {
    itemVariantId = posItemDetail.id;
    itemType = posItemDetail.type as "product" | "package";
    imageUrl = posItemDetail.image_url;
    subCategoryName = posItemDetail.sub_category.name;
    subCategoryColor = posItemDetail.sub_category.hex;
    itemName = posItemDetail.product?.name || posItemDetail.package?.name;
    itemVariantName = posItemDetail.name;
    sellPrice = posItemDetail.sell_price;
    rentPrice = posItemDetail.rent_price;
    isPurchase = posItemDetail.is_purchase || itemType === "package";
    itemStockUnitName = posItemDetail.item_stock_unit.name;
    productAvailableStock = 0;
    productLowStockReminder = 0;
    stocks = [];
  } else if (posItem) {
    itemVariantId = posItem.id;
    itemType = posItem.type as "product" | "package";
    imageUrl = posItem.image_url;
    subCategoryName = posItem.product.sub_category.name;
    subCategoryColor = posItem.product.sub_category.hex;
    itemName = posItem.product.name;
    itemVariantName = posItem.name;
    sellPrice = posItem.sell_price;
    rentPrice = posItem.rent_price;
    isPurchase = posItem.is_purchase || itemType === "package";
    itemStockUnitName = posItem.product.item_stock_unit.name;
    productAvailableStock = posItem?.available ?? 0;
    productLowStockReminder = posItem.product.low_stock_reminder;
    stocks = posItem.stocks ?? [];
  }

  let packageLowStockCount = 0;
  let packageOutOfStockCount = 0;

  for (const stock of stocks) {
    if (stock.available > 0 && stock.available <= stock.low_stock_reminder) {
      packageLowStockCount += 1;
    }
    if (stock.available === 0) {
      packageOutOfStockCount += 1;
    }
  }

  const isOnlyDetail = !posItem && posItemDetail;

  const name = `${itemVariantName} - ${itemName}`;
  const price = `${
    isPurchase && !isRent
      ? convertToCurrency(sellPrice)
      : convertToCurrency(rentPrice)
  } / ${itemStockUnitName}`;
  const isOutOfStock = !isOnlyDetail
    ? itemType === "product"
      ? productAvailableStock === 0
      : false
    : false;

  return (
    <div
      className={`rounded-md overflow-hidden border-primary-100 relative select-none ${
        !isOutOfStock && handleAddProductToCart ? "cursor-pointer" : ""
      }${className}`}
      onClick={() =>
        !isOutOfStock && handleAddProductToCart
          ? handleAddProductToCart({ id: itemVariantId, type: itemType })
          : {}
      }
      style={{ borderWidth: 1.5 }}
    >
      <img
        src={imageUrl}
        alt="preview"
        className="w-full h-48 object-contain border-primary-100"
        style={{ borderBottomWidth: 1.5 }}
      />

      <div className="flex flex-col gap-1 bg-white p-2 pb-4">
        <div className="flex flex-col gap-1">
          <div className="flex items-center justify-between">
            <span
              className="font-normal text-darkBrown-default"
              style={{ fontSize: "11px" }}
            >
              {subCategoryName}
            </span>

            {!isOutOfStock && handleAddProductToCart && (
              <AddCircle className="text-primary-70" size={22} />
            )}
          </div>

          <div className="text-xs font-semibold text-primary-100 leading-4">
            <ReactShowMoreText
              {...reactShowMoreTextConfig}
              lines={2}
              width={undefined}
              more={""}
            >
              {name}
            </ReactShowMoreText>
          </div>

          <div className="flex flex-col">
            {!handleAddProductToCart && (
              <div className="mt-1 -mb-1">
                <span
                  className="font-semibold text-darkBrown-default"
                  style={{ fontSize: "11px" }}
                >
                  {`${
                    itemType === "product"
                      ? isPurchase && !isRent
                        ? "Sell"
                        : "Rent"
                      : "Package"
                  } Price`}
                </span>
              </div>
            )}

            <span
              className="font-normal text-darkBrown-default"
              style={{ fontSize: "11px" }}
            >
              {price}
            </span>
          </div>
        </div>

        {!isOnlyDetail && (
          <div className="flex flex-col" style={{ fontSize: "11px" }}>
            {(itemType === "product"
              ? productAvailableStock > 0 &&
                productAvailableStock <= productLowStockReminder
              : packageLowStockCount > 0) && (
              <span className="font-normal text-yellowIndicator-30 text-nowrap">
                {itemType === "product"
                  ? "Low Stock"
                  : `${packageLowStockCount} Items with Low Stock`}
              </span>
            )}
            {(itemType === "product"
              ? productAvailableStock === 0 &&
                productAvailableStock <= productLowStockReminder
              : packageOutOfStockCount > 0) && (
              <span className="font-normal text-redIndicator-40 text-nowrap">
                {itemType === "product"
                  ? "Out of Stock"
                  : `${packageOutOfStockCount} Items with Out of Stock`}
              </span>
            )}
          </div>
        )}
      </div>

      <div
        className="w-full h-2.5 absolute left-0 bottom-0"
        style={{ backgroundColor: subCategoryColor }}
      />
    </div>
  );
};

export default POSCard;
