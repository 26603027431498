import { startTransition, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  PaginationType,
  SetPaginationCountType,
} from "src/components/TablePaginationLimitChanger/hooks/useTablePaginationLimitChanger";
import { useSetPaginationCount } from "src/hooks/useSetPaginationCount";
import { RoutePaths } from "src/pages/routePaths";
import { useGetProductList } from "../api/queries";
import { useGetAccessPermission } from "src/hooks/useGetAccessPermission";
import { AccessResource, ActionType } from "src/types/accessControl";
import { SelectOptionsType } from "src/types";

export interface FilterType {
  productCategory: SelectOptionsType;
  productSubCategory: SelectOptionsType;
  productName: SelectOptionsType;
  productVariant: SelectOptionsType;
  availability: SelectOptionsType;
  minPrice: number | null;
  maxPrice: number | null;
  productStatus: SelectOptionsType;
  availableStock: SelectOptionsType;
}

export const productListFilterDefaultValue = {
  productCategory: [],
  productSubCategory: [],
  productName: [],
  productVariant: [],
  availability: [],
  minPrice: null,
  maxPrice: null,
  productStatus: [],
  availableStock: [],
};

export const sortOptions = [
  {
    label: "Most Available Stock",
    value: "most-available",
  },
  {
    label: "Least Available Stock",
    value: "least-available",
  },
  {
    label: "Product Name A-Z",
    value: "name-asc",
  },
  {
    label: "Product Name Z-A",
    value: "name-desc",
  },
];

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const productAndPackageSortValue = [
  "most-available",
  "least-available",
  "name-asc",
  "name-desc",
] as const;

export type ProductAndPackageSortValueType =
  (typeof productAndPackageSortValue)[number];

export const getProductAndPackageSortingParams = (
  type: ProductAndPackageSortValueType
) => {
  let params = {};

  if (type === "most-available") {
    params = {
      sort_by: "stock",
      sort_order: "desc",
    };
  } else if (type === "least-available") {
    params = {
      sort_by: "stock",
      sort_order: "asc",
    };
  } else if (type === "name-asc") {
    params = {
      sort_by: "name",
      sort_order: "asc",
    };
  } else if (type === "name-desc") {
    params = {
      sort_by: "name",
      sort_order: "desc",
    };
  }

  return params;
};

interface UseProductListHandlerProps {
  pagination: PaginationType;
  setCount: SetPaginationCountType;
}

const useProductListHandler = ({
  pagination,
  setCount,
}: UseProductListHandlerProps) => {
  const navigate = useNavigate();

  const isAllowToCreate = useGetAccessPermission({
    resource: AccessResource["Product Management - Product List"],
    action: ActionType["Create"],
  });

  const [searchByProductNameOrProductSKU, setSearchByProductNameOrProductSKU] =
    useState("");
  const [sort, setSort] = useState(sortOptions[0].value);
  const [filter, setFilter] = useState<FilterType>(
    productListFilterDefaultValue
  );

  const { data, isLoading } = useGetProductList({
    ...pagination,
    productNameOrProductSKU: searchByProductNameOrProductSKU,
    sortBy: sort,
    ...filter,
  });

  const handleAdd = () =>
    startTransition(() =>
      navigate(RoutePaths.ProductManagementCreateProductList)
    );

  useSetPaginationCount({ meta: data?.meta, setCount });

  return {
    isLoading,
    isAllowToCreate,
    productList: data?.data,
    totalCount: data?.meta.total ?? 0,
    handleAdd,
    setSearchByProductNameOrProductSKU,
    sort,
    setSort,
    sortOptions,
    filter,
    setFilter,
    hasFilter:
      searchByProductNameOrProductSKU !== "" ||
      Object.values(filter).some((filter) =>
        Array.isArray(filter) ? filter.length > 0 : filter
      ),
  };
};

export default useProductListHandler;
