import { AddSquare, Trash } from "iconsax-react";
import { UseFormReturn } from "react-hook-form";
import CheckBoxInput from "src/components/CheckBoxInput";
import Spinner from "src/components/Spinner";
import { convertToCurrency } from "src/utils/convertToCurrency";
import CustomInput from "../../components/CustomInput";
import { Button } from "../../components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../components/ui/dialog";
import { Form } from "../../components/ui/form";
import AddProductModalPackageProductList from "./AddProductModalPackageProductList";
import POSCard from "./POSCard";
import useAddProductModalHandler from "./hooks/useAddProductModalHandler";
import {
  POSCartItemType,
  SelectedPackageProductType,
} from "./hooks/usePOSHandler";
import { PosCartSchemaType } from "./posCartSchema";
import ConfirmationModal from "src/components/ConfirmationModal";

interface AddProductModalProps {
  isEditOrder: boolean;
  transactionId: number | undefined;
  isShow: boolean;
  onClose: () => void;
  selectedPOSItem: {
    id: number;
    type: "product" | "package";
  };
  selectedPosOrderItem: PosCartSchemaType["orders"][number] | undefined;
  selectedPosOrderItemIndex: number | null;
  selectedPackageProducts: SelectedPackageProductType[];
  setSelectedPackageProducts: React.Dispatch<
    React.SetStateAction<SelectedPackageProductType[]>
  >;
  posForm: UseFormReturn<PosCartSchemaType, any, undefined>;
  setPOSCartItems: React.Dispatch<React.SetStateAction<POSCartItemType[]>>;
}

const AddProductModal = ({
  isEditOrder,
  transactionId,
  isShow,
  onClose,
  selectedPOSItem,
  selectedPosOrderItem,
  selectedPosOrderItemIndex,
  selectedPackageProducts,
  setSelectedPackageProducts,
  posForm,
  setPOSCartItems,
}: AddProductModalProps) => {
  const {
    isShowOverBudgetAlert,
    isAllowToEditPrice,
    warehouseOptions,
    filteredWarehouseOptions,
    posItemDetail,
    isLoading,
    form,
    formValues,
    isAllowForRent,
    isPackage,
    stockLessThanFour,
    disabledAddFromOtherWarehouse,
    totalPrice,
    totalPackageProductsPrice,
    packageRemainingPrice,
    packageTotalExcessPrice,
    handleCloseOverBudgetAlert,
    handleAgreeOverBudgetAlert,
    handleAddProduct,
    handleAddFromOtherWarehouse,
    handleDeleteWarehouse,
    handleCloseModal,
    handleAddMoreItem,
    handleMarkAsRentCallBack,
  } = useAddProductModalHandler({
    isEditOrder,
    transactionId,
    selectedPOSItem,
    selectedPosOrderItem,
    selectedPosOrderItemIndex,
    onClose,
    posForm,
    setPOSCartItems,
    setSelectedPackageProducts,
  });

  const packageProducts = formValues.products;

  return (
    <Dialog open={isShow} onOpenChange={handleCloseModal}>
      <DialogContent className="md:min-w-[700px] lg:min-w-[1000px] xl:min-w-[1400px]">
        <DialogHeader>
          <DialogTitle className="text-md font-semibold text-darkBrown-default">
            Add Product
          </DialogTitle>
        </DialogHeader>

        {!isLoading && posItemDetail ? (
          <div className="flex flex-col gap-4 pt-2 pb-5">
            <Form {...form}>
              <div className="flex items-start gap-5">
                <POSCard
                  className="min-w-56"
                  posItemDetail={posItemDetail}
                  isRent={formValues.markAsRent}
                />

                <div className="flex flex-col gap-5 w-full">
                  {isPackage && (
                    <div className={`grid grid-cols-${1} gap-5 w-full`}>
                      <CustomInput
                        disabled={!isAllowToEditPrice}
                        form={form}
                        variant="currency"
                        name="price"
                        labelText="Edit Price"
                        placeholder="Input price"
                      />
                    </div>
                  )}

                  {!isPackage && (
                    <>
                      {stockLessThanFour ? (
                        posItemDetail?.stocks?.map((stock, index) => {
                          const warehouseName = stock.warehouse.name;
                          const hasStock = stock.available > 0;
                          const availableStock = stock.available;

                          return (
                            <div
                              className="flex items-center gap-2"
                              key={index}
                            >
                              <div
                                className={`flex flex-1 gap-2 ${
                                  index === 0 ? "pt-8" : ""
                                }`}
                              >
                                <div className="flex-1 w-full">
                                  <span className="font-medium text-darkIndicator-50">
                                    {warehouseName}
                                  </span>
                                </div>

                                <span
                                  className={`text-sm font-normal italic ${
                                    hasStock
                                      ? "text-darkIndicator-50"
                                      : "text-redIndicator-50"
                                  }`}
                                >
                                  {hasStock
                                    ? `${availableStock} Stock(s) left`
                                    : "Out Of Stock"}
                                </span>
                              </div>

                              <div>
                                <CustomInput
                                  form={form}
                                  name={`warehouses.${index}.quantity`}
                                  labelText={
                                    index === 0 ? "Select Quantity" : undefined
                                  }
                                  variant="quantity-changer"
                                  maxValue={availableStock}
                                />
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <div className="flex flex-col items-start gap-5 w-full">
                          <div className="flex flex-col w-full gap-5">
                            <div className="absolute">
                              <span className="text-sm font-normal">
                                Select Warehouse
                              </span>
                            </div>

                            {formValues.warehouses.map((warehouse, index) => {
                              const selectedStock = posItemDetail?.stocks?.find(
                                (stock) =>
                                  `${stock.warehouse.id}` === `${warehouse.id}`
                              );
                              const hasStock =
                                (selectedStock?.available ?? 0) > 0;
                              const availableStock =
                                selectedStock?.available ?? 0;

                              let options = [...filteredWarehouseOptions];

                              if (selectedStock) {
                                options = [
                                  warehouseOptions.find(
                                    (warehouseOption) =>
                                      `${warehouseOption.value}` ===
                                      `${warehouse.id}`
                                  )!,
                                  ...filteredWarehouseOptions,
                                ];
                              }

                              return (
                                <div
                                  className="flex justify-end gap-5 w-full"
                                  key={index}
                                >
                                  <div
                                    className={`flex-1 w-full ${
                                      index === 0 ? "pt-8" : ""
                                    }`}
                                  >
                                    <CustomInput
                                      form={form}
                                      name={`warehouses.${index}.id`}
                                      placeholder="Select Warehouse"
                                      variant="select"
                                      options={options}
                                    />
                                  </div>

                                  <div className="flex justify-between min-w-56">
                                    <div className="flex flex-col">
                                      <CustomInput
                                        form={form}
                                        name={`warehouses.${index}.quantity`}
                                        labelText={
                                          index === 0
                                            ? "Select Quantity"
                                            : undefined
                                        }
                                        variant="quantity-changer"
                                        maxValue={availableStock}
                                      />

                                      {selectedStock && (
                                        <div className="mt-1.5">
                                          <span
                                            className={`text-sm font-normal italic ${
                                              hasStock
                                                ? "text-darkIndicator-50"
                                                : "text-redIndicator-50"
                                            }`}
                                          >
                                            {hasStock
                                              ? `${availableStock} Stock(s) left`
                                              : "Out Of Stock"}
                                          </span>
                                        </div>
                                      )}
                                    </div>

                                    {formValues.warehouses.length > 1 && (
                                      <Button
                                        className={index === 0 ? "mt-8" : ""}
                                        variant="link"
                                        type="button"
                                        onClick={() =>
                                          handleDeleteWarehouse(index)
                                        }
                                      >
                                        <Trash
                                          size={21}
                                          className="text-redIndicator-40"
                                        />
                                      </Button>
                                    )}
                                  </div>
                                </div>
                              );
                            })}
                          </div>

                          {filteredWarehouseOptions.length > 0 && (
                            <Button
                              disabled={disabledAddFromOtherWarehouse}
                              className="mt-4"
                              variant="outline"
                              onClick={handleAddFromOtherWarehouse}
                            >
                              <AddSquare className="mr-2" size={16} /> Add From
                              Other Warehouse
                            </Button>
                          )}
                        </div>
                      )}

                      {isAllowForRent && (
                        <CheckBoxInput
                          form={form}
                          name="markAsRent"
                          labelText="Mark as Rent"
                          size="large"
                          onCheck={handleMarkAsRentCallBack}
                        />
                      )}

                      {isAllowToEditPrice && (
                        <CustomInput
                          form={form}
                          variant="currency"
                          name="price"
                          labelText="Edit Price"
                          placeholder="Input price"
                        />
                      )}

                      <CustomInput
                        form={form}
                        variant="currency"
                        name="discount"
                        labelText="Discount"
                        placeholder="Input Discount Amount (Rp)"
                      />
                    </>
                  )}

                  {isPackage && (
                    <div className="grid gap-4">
                      <hr className="w-full -mb-2" />

                      <span className="text-md font-medium text-darkIndicator-60">
                        Package Price & Quantity
                      </span>

                      <div className="flex items-center gap-3">
                        <span className="text-sm font-normal text-darkIndicator-60 text-nowrap">
                          Package Quantity
                        </span>

                        <CustomInput
                          form={form}
                          name="quantity"
                          variant="quantity-changer"
                          minValue={1}
                        />
                      </div>

                      <div className="grid grid-cols-2 items-center gap-6">
                        <div className="grid gap-1 bg-[#F7F6DE] p-4 rounded break-all">
                          {[
                            {
                              label: "Total Product Price",
                              value: totalPackageProductsPrice,
                            },
                            {
                              label: "Remaining Price",
                              value: packageRemainingPrice,
                            },
                          ].map((summary, index) => (
                            <div
                              className="flex flex-row justify-between"
                              key={index}
                            >
                              <span className="text-sm font-medium">
                                {summary.label}
                              </span>

                              <span className="text-sm font-medium">
                                {convertToCurrency(summary.value)}
                              </span>
                            </div>
                          ))}

                          {Boolean(packageTotalExcessPrice) && (
                            <span className="text-sm font-medium italic text-redIndicator-50">
                              {`Total Product Price is exceed ${convertToCurrency(
                                packageTotalExcessPrice
                              )}`}
                            </span>
                          )}
                        </div>

                        <CustomInput
                          form={form}
                          variant="currency"
                          name="discount"
                          labelText="Remaining Price Discount"
                          placeholder="Input Discount Amount (Rp)"
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {isPackage && (
                <div className="p-4 border rounded-lg">
                  <div className="flex items-center justify-between mb-3">
                    <span className="text-lg font-semibold text-darkIndicator-50">
                      Product List
                    </span>

                    <Button variant="outline" onClick={handleAddMoreItem}>
                      <AddSquare className="mr-2" size={16} /> Add More Item
                    </Button>
                  </div>

                  {packageProducts.map((packageProduct, index) => {
                    return (
                      <AddProductModalPackageProductList
                        isAllowToEditPrice={isAllowToEditPrice}
                        form={form}
                        index={index}
                        packageVariantId={selectedPOSItem.id}
                        selectedPackageProducts={selectedPackageProducts}
                        setSelectedPackageProducts={setSelectedPackageProducts}
                        packageProduct={packageProduct}
                        key={index}
                      />
                    );
                  })}
                </div>
              )}
            </Form>
          </div>
        ) : (
          <div className="min-h-24 flex items-center justify-center">
            <Spinner size="large" />
          </div>
        )}

        <DialogFooter>
          <div className="flex items-center justify-end gap-5">
            <span className="text-sm font-semibold text-darkBrown-default">{`Total price ${convertToCurrency(
              totalPrice
            )}`}</span>
            <Button
              type="submit"
              onClick={() => handleAddProduct()}
              isLoading={form.formState.isSubmitting || isLoading}
            >
              Add Product
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>

      <ConfirmationModal
        isShow={isShowOverBudgetAlert}
        onClose={handleCloseOverBudgetAlert}
        onSubmit={handleAgreeOverBudgetAlert}
        title="Overbudget Product"
        description="The product selected is overbudget. do you still want to continue?"
        cancelButtonTitle="Cancel"
        submitButtonTitle="Add Product"
        cancelButtonPosition="first"
        footerAlignment="between"
      />
    </Dialog>
  );
};

export default AddProductModal;
