import { useEffect, useState } from "react";
import { useCurrentUser } from "src/pages/SignIn/api/queries";
import { capitalize } from "src/utils/commons";

const useLayoutSidebar = () => {
  const { data } = useCurrentUser();

  const adminId = data?.data?.id ?? 0;

  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const [isShowChangeMyPasswordModal, setIsShowChangeMyPasswordModal] =
    useState(false);
  const [isOpenNotification, setIsOpenNotification] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const toggleNotification = () => {
    setIsOpenNotification(!isOpenNotification);
  };

  const getCurrentPathname = () => {
    let pathName = window.location.pathname;

    pathName = pathName?.split("/")[1];
    pathName = pathName?.split("-")?.join(" ");

    switch (pathName) {
      case "pos":
        pathName = "POS";
        break;

      default:
        pathName = capitalize(pathName);
        break;
    }

    return pathName;
  };

  const handleChangeMyPassword = () => setIsShowChangeMyPasswordModal(true);
  const handleCancelChangeMyPassword = () =>
    setIsShowChangeMyPasswordModal(false);

  useEffect(() => {
    const handleResize = () => {
      // 768px is tailwind md size
      if (window.innerWidth <= 768) {
        setSidebarOpen(false);
      } else {
        if (isSidebarOpen === false) {
          setSidebarOpen(true);
        }
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    adminId,
    isSidebarOpen,
    isOpenNotification,
    toggleSidebar,
    toggleNotification,
    pathname: getCurrentPathname(),
    isShowChangeMyPasswordModal,
    handleChangeMyPassword,
    handleCancelChangeMyPassword,
  };
};

export default useLayoutSidebar;
