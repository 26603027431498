import { QueryCache, QueryClient } from "@tanstack/react-query";
import { toast } from "sonner";
import { ErrorType } from "src/types/apiResponse";

export const ErrorToast = (error: any | Error) => {
  const errorMessage =
    (error as ErrorType)?.response?.data?.message ?? error.message;

  if (!["Token expired", "No credential provided"].includes(errorMessage)) {
    window.scrollTo(0, 0);

    toast.error(errorMessage, {
      position: "top-center",
      style: {
        backgroundColor: "#FFE7E7",
        color: "#A85E5E",
      },
    });
  }
};

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error: any | Error) => ErrorToast(error),
  }),
});

export default queryClient;

export const reactQueryOptions = {
  retry: 3,
  staleTime: 1000,
};
