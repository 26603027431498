import { useCurrentUser } from "src/pages/SignIn/api/queries";
import { SideBarList } from "../SideBarList";
import { NavItemProps } from "../NavItem";
import { appAsset } from "src/appAsset";
import { useNavigate } from "react-router-dom";
import { RoutePaths } from "src/pages/routePaths";

const useSideBar = () => {
  const navigate = useNavigate();

  const { data } = useCurrentUser();

  const { appLogo1, appLogo2 } = appAsset;

  const adminAccess = data?.data?.accesses ?? [];
  const appVersion = data?.data?.app_version ?? "";

  const adminFeatureAccessResources = adminAccess.map(
    (access) => access.resource
  );

  const allowedSideBarList = SideBarList.map((sideBarList) => {
    if (!(sideBarList?.multiple ?? false)) {
      if (
        adminFeatureAccessResources.includes(sideBarList?.accessResource ?? "")
      ) {
        return sideBarList;
      } else {
        return null;
      }
    }

    const subMenus =
      sideBarList.subMenus?.filter((subMenu) => {
        return adminFeatureAccessResources.includes(subMenu.accessResource);
      }) ?? [];

    if (!sideBarList.accessResource && subMenus?.length === 0) {
      return null;
    }

    return {
      ...sideBarList,
      subMenus,
    };
  }).filter(Boolean) as NavItemProps[];

  const isChangeLogPage = window.location.pathname.startsWith(
    RoutePaths.ChangeLog
  );
  const handleNavigateToChangeLog = () => navigate(RoutePaths.ChangeLog);

  return {
    menus: allowedSideBarList,
    appLogo1,
    appLogo2,
    isChangeLogPage,
    handleNavigateToChangeLog,
    appVersion,
  };
};

export default useSideBar;
