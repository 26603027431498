import { Trash } from "lucide-react";
import { UseFormReturn } from "react-hook-form";
import CheckBoxInput from "src/components/CheckBoxInput";
import CustomInput from "src/components/CustomInput";
import { Button } from "src/components/ui/button";
import { convertToCurrency } from "src/utils/convertToCurrency";
import { AddProductModalSchemaType } from "./addProductModalSchema";
import useAddProductModalPackageProductListHandler from "./hooks/useAddProductModalPackageProductListHandler";
import { SelectedPackageProductType } from "./hooks/usePOSHandler";

interface AddProductModalPackageProductListProps {
  isAllowToEditPrice: boolean;
  form: UseFormReturn<AddProductModalSchemaType, any, undefined>;
  index: number;
  packageVariantId: number;
  selectedPackageProducts: SelectedPackageProductType[];
  setSelectedPackageProducts: React.Dispatch<
    React.SetStateAction<SelectedPackageProductType[]>
  >;
  packageProduct: AddProductModalSchemaType["products"][number];
}

const AddProductModalPackageProductList = ({
  isAllowToEditPrice,
  form,
  index,
  packageVariantId,
  selectedPackageProducts,
  setSelectedPackageProducts,
  packageProduct,
}: AddProductModalPackageProductListProps) => {
  const {
    productVariantListOptions,
    isLoadingProductVariantListData,
    setSearchProductVariantList,
    warehouseOptions,
    selectedPackageProduct,
    availableStock,
    selectedStock,
    hasStock,
    totalItemPrice,
    isRentOnly,
    onChangeWarehouseCallback,
    onChangeProductItemCallback,
    handleDeleteProductList,
    handleMarkAsRentCallBack,
  } = useAddProductModalPackageProductListHandler({
    form,
    index,
    packageVariantId,
    selectedPackageProducts,
    setSelectedPackageProducts,
    packageProduct,
  });

  const formValues = form.watch();

  const productRecommendedQuantity = selectedPackageProduct?.quantity ?? null;

  return (
    <div className="flex gap-4">
      <div className="min-w-80 max-w-80">
        <div className="flex flex-col gap-1">
          <CustomInput
            form={form}
            name={`products.${index}.id`}
            labelText="Item name"
            placeholder="Select Item name"
            variant="select"
            options={productVariantListOptions}
            isLoading={isLoadingProductVariantListData}
            onSearch={setSearchProductVariantList}
            onChange={onChangeProductItemCallback}
          />

          <span className="text-xs font-normal text-darkIndicator-50">{`${
            packageProduct.markAsRent ? "Rent" : "Purchase"
          } Item`}</span>
        </div>
      </div>

      <div className="flex flex-col gap-2 min-w-44">
        <CustomInput
          form={form}
          name={`products.${index}.warehouseId`}
          labelText="Warehouse"
          placeholder="Select Warehouse"
          variant="select"
          options={warehouseOptions}
          onChange={onChangeWarehouseCallback}
        />

        {selectedStock && (
          <span
            className={`text-sm font-normal italic ${
              hasStock ? "text-darkIndicator-50" : "text-redIndicator-50"
            }`}
          >
            {hasStock ? `${availableStock} Stock(s) left` : "Out Of Stock"}
          </span>
        )}
      </div>

      <div className="flex flex-col gap-2">
        <div className={index !== 0 ? "pt-8" : undefined}>
          <CustomInput
            form={form}
            name={`products.${index}.quantity`}
            labelText={index === 0 ? "Select Quantity" : undefined}
            variant="quantity-changer"
            maxValue={availableStock}
          />
        </div>

        {typeof productRecommendedQuantity === "number" &&
          productRecommendedQuantity > 0 && (
            <span
              className={`text-sm font-normal italic text-nowrap ${
                hasStock ? "text-darkIndicator-50" : "text-redIndicator-50"
              }`}
            >
              {`Recommend Qty: ${productRecommendedQuantity}`}
            </span>
          )}
      </div>

      <CustomInput
        disabled={!isAllowToEditPrice}
        form={form}
        variant="currency"
        name={`products.${index}.price`}
        labelText="Price per Item"
        placeholder="Input Price"
      />

      <CustomInput
        form={form}
        variant="currency"
        name={`products.${index}.discount`}
        labelText="Discount"
        placeholder="Input Discount Amount (Rp)"
      />

      <div className="flex pt-8 gap-4">
        <div className="flex flex-col text-nowrap">
          <div className="-mt-8">
            <span className="text-sm font-normal">Total Item Price</span>
          </div>

          <div className="pt-3.5">
            <span className="text-sm font-normal">
              {convertToCurrency(totalItemPrice)}
            </span>
          </div>
        </div>

        <div className="mt-3 -mr-3">
          <CheckBoxInput
            disabled={isRentOnly}
            form={form}
            name={`products.${index}.markAsRent`}
            labelText="Mark as Rent"
            noWrap
            onCheck={(checked) => handleMarkAsRentCallBack({ checked, index })}
          />
        </div>

        {formValues.products.length > 1 && (
          <Button
            variant="link"
            type="button"
            onClick={() => handleDeleteProductList(index)}
          >
            <Trash size={21} className="text-redIndicator-40" />
          </Button>
        )}
      </div>
    </div>
  );
};

export default AddProductModalPackageProductList;
