import {
  BusinessOperationDetailRequestBody,
  BusinessOperationDetailResponse,
  BusinessOperationParams,
  BusinessOperationResponse,
  CreateBusinessOperationFormType,
  CreateBusinessOperationResponse,
  DeleteBusinessOperationRequestBody,
  DeleteBusinessOperationResponse,
  EditBusinessOperationFormType,
  EditBusinessOperationResponse,
} from "src/pages/MasterData/BusinessOperation/api/interfaces";
import API from ".";
import { getPagination } from "src/utils/getPagination";

const featureApiRoute = "/business-operations";

export const getBusinessOperation = async ({
  limit,
  offset,
  businessOperationName,
}: BusinessOperationParams): Promise<BusinessOperationResponse> => {
  const paginationParam = getPagination({ limit, offset });

  const response = await API.get<BusinessOperationResponse>(featureApiRoute, {
    params: {
      ...paginationParam,
      ...(businessOperationName ? { name: businessOperationName } : {}),
    },
  });

  return response.data;
};

export const getBusinessOperationDetail = async ({
  id,
}: BusinessOperationDetailRequestBody): Promise<BusinessOperationDetailResponse> => {
  const response = await API.get<BusinessOperationDetailResponse>(
    `${featureApiRoute}/${id}`
  );

  return response.data;
};

export const createBusinessOperation = async (
  form: CreateBusinessOperationFormType
): Promise<CreateBusinessOperationResponse> => {
  const requestBody = {
    name: form.name,
    phone_number: form.phoneNumber,
    country: form.country,
    city: form.city,
    address: form.address,
    description: form.description,
  };

  const response = await API.post<CreateBusinessOperationResponse>(
    featureApiRoute,
    requestBody
  );

  return response.data;
};

export const editBusinessOperation = async ({
  id,
  ...form
}: EditBusinessOperationFormType): Promise<EditBusinessOperationResponse> => {
  const requestBody = {
    name: form.name,
    phone_number: form.phoneNumber,
    country: form.country,
    city: form.city,
    address: form.address,
    description: form.description,
  };

  const response = await API.put<EditBusinessOperationResponse>(
    `${featureApiRoute}/${id}`,
    requestBody
  );

  return response.data;
};

export const deleteBusinessOperation = async ({
  id,
}: DeleteBusinessOperationRequestBody): Promise<DeleteBusinessOperationResponse> => {
  const response = await API.delete<DeleteBusinessOperationResponse>(
    `${featureApiRoute}/${id}`
  );

  return response.data;
};
