interface UsePaginationLimitChangerProps {
  setLimit: (value: string) => void;
  handleResetPagination: () => void;
}

export const paginationLimitList = [10, 100, 500, 1000];

const usePaginationLimitChanger = ({
  setLimit,
  handleResetPagination,
}: UsePaginationLimitChangerProps) => {
  const handleChangeLimit = (value: string) => {
    handleResetPagination();
    setLimit(value);
  };

  return { paginationLimitList, handleChangeLimit };
};

export default usePaginationLimitChanger;
