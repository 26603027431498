import React from "react";
import { Button } from "../ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../ui/dialog";
import ChangePasswordForm from "./ChangePasswordForm";
import useChangePasswordModalHandler from "./hooks/useChangePasswordHandler";

interface ChangePasswordProps {
  isShow: boolean;
  onClose: () => void;
  adminId: number;
  type: "OTHER" | "MINE";
}

const ChangePasswordModal: React.FC<ChangePasswordProps> = ({
  isShow,
  onClose,
  adminId,
  type,
}) => {
  const { form, handleChangePassword, handleCloseModal } =
    useChangePasswordModalHandler({
      adminId,
      type,
      handleClose: onClose,
    });

  return (
    <Dialog open={isShow} onOpenChange={handleCloseModal}>
      <DialogContent className="max-w-fit">
        <DialogHeader>
          <DialogTitle className="text-md font-semibold text-darkBrown-default">
            Change Password
          </DialogTitle>
        </DialogHeader>

        <div className="flex flex-col gap-4 pt-2 w-[860px]">
          <ChangePasswordForm form={form} type={type} />
        </div>

        <DialogFooter>
          <Button
            onClick={handleChangePassword}
            isLoading={form.formState.isSubmitting}
          >
            Submit
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default ChangePasswordModal;
