import { UseFormReturn } from "react-hook-form";
import { ErrorToast } from "src/config/reactQuery.config";
import { ErrorType } from "src/types/apiResponse";
import { snakeToCamel } from "./snackToCamel";

interface ApiErrorHandlerProps {
  error: any;
  form?: UseFormReturn<any, any, undefined>;
}

export const apiErrorHandler = ({ error: err, form }: ApiErrorHandlerProps) => {
  const error = err as ErrorType;
  const errorMessage = error?.response?.data?.message;

  if (errorMessage && errorMessage !== "Token expired") {
    ErrorToast(error);
  } else {
    const errorMessages = error?.response?.data?.messages;

    if (errorMessages && form) {
      for (const errorMessage of errorMessages) {
        form.setError(snakeToCamel(errorMessage.field), {
          message: errorMessage.message,
        });
      }
    }
  }
};
