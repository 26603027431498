import API from ".";

interface ReqBody {
  image: File;
  domain: string;
  type: string;
  field: string;
}

interface Response {
  success: boolean;
  key: string;
}

const featureApiRoute = "/uploads";

export const uploadFile = async (reqBody: ReqBody): Promise<Response> => {
  let formData = new FormData();

  formData.append("image", reqBody.image);
  formData.append("domain", reqBody.domain);
  formData.append("type", reqBody.type);
  formData.append("field", reqBody.field);

  const response = await API.post<Response>(featureApiRoute, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });

  return response.data;
};
