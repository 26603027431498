import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { ProductVariant } from "src/pages/ProductManagement/ProductList/api/interfaces";
import { getQRDetail } from "src/services/QR.service";
import { apiErrorHandler } from "src/utils/apiErrorHandler";
import { z } from "zod";
import { scanQRModalSchema } from "../scanQRModalSchema";

interface UseScanQRModalProps {
  onClose: () => void;
  onSuccess: (productVariant: ProductVariant) => void;
}

const useScanQRModal = ({ onClose, onSuccess }: UseScanQRModalProps) => {
  const form = useForm<z.infer<typeof scanQRModalSchema>>({
    resolver: zodResolver(scanQRModalSchema),
  });

  const handleCloseModal = () => {
    form.setValue("qrValue", "");
    onClose();
  };

  const handleScanQR = async (barcode: string) => {
    form.setValue("qrValue", barcode, { shouldValidate: false });

    try {
      const data = await getQRDetail(barcode);

      onSuccess(data.data as ProductVariant);
      handleCloseModal();
    } catch (error) {
      apiErrorHandler({ error, form });
    }
  };

  return { form, handleScanQR, handleCloseModal };
};

export default useScanQRModal;
