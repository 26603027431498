import { format } from "date-fns";
import {
  OrderListContinueToPickUpOrDeliveryRequestBody,
  OrderListContinueToPickUpOrDeliveryResponse,
  OrderListDetailParams,
  OrderListDetailResponse,
  OrderListGenerateRecipeRequestBody,
  OrderListGenerateRecipeResponse,
  OrderListGetEditOrderDetailRequestBody,
  OrderListGetEditOrderDetailResponse,
  OrderListMarkAsCompleteOrderRequestBody,
  OrderListMarkAsCompleteOrderResponse,
  OrderListMarkAsPickUpOrDeliveryRequestBody,
  OrderListMarkAsPickUpOrDeliveryResponse,
  OrderListParams,
  OrderListResponse,
  OrderListSetFixedDateRequestBody,
  OrderListSetFixedDateResponse,
  OrderListSwitchToDeliveryRequestBody,
  OrderListSwitchToDeliveryResponse,
  OrderListSwitchToPickUpRequestBody,
  OrderListSwitchToPickUpResponse,
} from "src/pages/OrderManagement/OrderList/api/interfaces";
import {
  getOrderListSortingParams,
  OrderListSortValueType,
} from "src/pages/OrderManagement/OrderList/hooks/useOrderListHandler";
import { convertArrayParamsToString } from "src/utils/convertArrayParamsToString";
import { getPagination } from "src/utils/getPagination";
import API from ".";
import { formatInputPhoneNumberValue } from "src/utils/formatPhoneNumber";

const featureRouteApi = "/orders";

export const getOrderList = async ({
  limit,
  offset,
  searchValue,
  searchType,
  dateFilterValue,
  dateFilterType,
  sortBy,
  transactionStatuses,
  orderType,
  productName,
  productVariant,
  packageName,
  packageVariant,
  businessOperation,
  transactionCategory,
  paymentStatus,
  paymentType,
  customerType,
  customerCategory,
}: OrderListParams): Promise<OrderListResponse> => {
  const paginationParam = getPagination({ limit, offset });

  const getSearchFilter = () => {
    if (searchValue) {
      if (searchType?.startsWith("Search by Customer / Partner Name")) {
        return { qn: searchValue };
      } else if (searchType?.startsWith("Search by Recipient Name")) {
        return { qrc: searchValue };
      } else if (searchType === "Search by Invoice Number") {
        return { qinv: searchValue };
      }
    }
  };

  const getDateFilter = () => {
    if (dateFilterValue?.from) {
      if (dateFilterType === "Filter By Delivery / Pickup Date") {
        return {
          start_onfulfill_date: format(
            dateFilterValue.from ?? new Date(),
            "yyyy-MM-dd"
          ),
          end_onfulfill_date: dateFilterValue.to
            ? format(dateFilterValue.to, "yyyy-MM-dd")
            : null,
        };
      } else if (dateFilterType === "Filter By Transaction Date") {
        return {
          start_order_date: format(
            dateFilterValue.from ?? new Date(),
            "yyyy-MM-dd"
          ),
          end_order_date: dateFilterValue.to
            ? format(dateFilterValue.to, "yyyy-MM-dd")
            : null,
        };
      }
    }
  };

  const response = await API.get<OrderListResponse>(featureRouteApi, {
    params: {
      ...paginationParam,
      ...getSearchFilter(),
      ...getDateFilter(),
      transaction_statuses: convertArrayParamsToString(transactionStatuses),
      ...(orderType.length > 0
        ? {
            order_methods: convertArrayParamsToString(
              orderType.map((option) => option.value)
            ),
          }
        : {}),
      ...(productName.length > 0
        ? {
            product_ids: convertArrayParamsToString(
              productName.map((option) => option.value)
            ),
          }
        : {}),
      ...(productVariant.length > 0
        ? {
            product_variant_ids: convertArrayParamsToString(
              productVariant.map((option) => option.value)
            ),
          }
        : {}),
      ...(packageName.length > 0
        ? {
            package_ids: convertArrayParamsToString(
              packageName.map((option) => option.value)
            ),
          }
        : {}),
      ...(packageVariant.length > 0
        ? {
            package_variant_ids: convertArrayParamsToString(
              productVariant.map((option) => option.value)
            ),
          }
        : {}),
      ...(businessOperation.length > 0
        ? {
            business_operation_ids: convertArrayParamsToString(
              businessOperation.map((option) => option.value)
            ),
          }
        : {}),
      ...(transactionCategory.length > 0
        ? {
            transaction_flows: convertArrayParamsToString(
              transactionCategory.map((option) => option.value)
            ),
          }
        : {}),
      ...(paymentStatus.length > 0
        ? {
            payment_statuses: convertArrayParamsToString(
              paymentStatus.map((option) => option.value)
            ),
          }
        : {}),
      ...(paymentType.length > 0
        ? {
            payment_types: convertArrayParamsToString(
              paymentType.map((option) => option.value)
            ),
          }
        : {}),
      ...(customerType.length > 0
        ? {
            customer_type_ids: convertArrayParamsToString(
              customerType.map((option) => option.value)
            ),
          }
        : {}),
      ...(customerCategory.length > 0
        ? {
            buyer_types: convertArrayParamsToString(
              customerCategory.map((option) => option.value)
            ),
          }
        : {}),
      ...(sortBy
        ? getOrderListSortingParams(sortBy as OrderListSortValueType)
        : {}),
    },
  });

  return response.data;
};

export const getOrderListDetail = async ({
  id,
}: OrderListDetailParams): Promise<OrderListDetailResponse> => {
  const response = await API.get<OrderListDetailResponse>(
    `${featureRouteApi}/${id}`
  );

  return response.data;
};

export const orderListContinueToPickUpOrDelivery = async ({
  id,
}: OrderListContinueToPickUpOrDeliveryRequestBody): Promise<OrderListContinueToPickUpOrDeliveryResponse> => {
  const response = await API.patch<OrderListContinueToPickUpOrDeliveryResponse>(
    `${featureRouteApi}/${id}/status`
  );

  return response.data;
};

export const orderListGenerateRecipes = async ({
  recipeIds,
}: OrderListGenerateRecipeRequestBody): Promise<OrderListGenerateRecipeResponse> => {
  const response = await API.get<OrderListGenerateRecipeResponse>(
    `${featureRouteApi}/items`,
    {
      params: {
        order_ids: convertArrayParamsToString(recipeIds),
      },
    }
  );

  return response.data;
};

export const orderListSetFixedDate = async ({
  orderId,
  orderMethod,

  deliveryDate,
  recipientId,
  name,
  phoneNumber,
  country,
  city,
  address,
  giftNotes,

  pickUpDate,
  returnDate,
  pickUpNotes,

  isNeedAdditionalPayment,
  additionalFeeId,
  additionalFeeAmount,
  paymentMethodId,
  paymentChannelId,
}: OrderListSetFixedDateRequestBody): Promise<OrderListSetFixedDateResponse> => {
  const requestBody =
    orderMethod === "delivery"
      ? {
          order_method: orderMethod,
          delivery_date: deliveryDate
            ? format(deliveryDate, "yyyy-MM-dd HH:mm:ss")
            : null,
          recipient_id: recipientId ?? null,
          name,
          phone_number: phoneNumber
            ? formatInputPhoneNumberValue(phoneNumber)
            : null,
          country,
          city,
          address,
          gift_notes: giftNotes,
        }
      : {
          order_method: orderMethod,
          pickup_date: pickUpDate
            ? format(pickUpDate, "yyyy-MM-dd HH:mm:ss")
            : null,
          return_date: returnDate
            ? format(returnDate, "yyyy-MM-dd HH:mm:ss")
            : null,
          pickup_notes: pickUpNotes,
          additional_fee_id: isNeedAdditionalPayment ? additionalFeeId : null,
          additional_fee_amount: isNeedAdditionalPayment
            ? additionalFeeAmount
            : null,
          payment_method_id: isNeedAdditionalPayment ? paymentMethodId : null,
          payment_channel_id: isNeedAdditionalPayment ? paymentChannelId : null,
        };

  const response = await API.patch<OrderListSetFixedDateResponse>(
    `${featureRouteApi}/${orderId}/fixed-date`,
    requestBody
  );

  return response.data;
};

export const orderListSwitchToPickUp = async ({
  orderId,
  pickUpDate,
  returnDate,
  refundAmount,
  refundNotes,
}: OrderListSwitchToPickUpRequestBody): Promise<OrderListSwitchToPickUpResponse> => {
  const requestBody = {
    pickup_detail: {
      pickup_date: format(pickUpDate, "yyyy-MM-dd HH:mm:ss"),
      return_date: returnDate
        ? format(returnDate, "yyyy-MM-dd HH:mm:ss")
        : null,
      refund_amount: refundAmount,
      refund_notes: refundNotes,
    },
  };

  const response = await API.patch<OrderListSwitchToPickUpResponse>(
    `${featureRouteApi}/${orderId}/order-method`,
    requestBody
  );

  return response.data;
};

export const orderListSwitchToDelivery = async ({
  orderId,
  deliveryDate,
  returnDate,
  recipientId,
  name,
  phoneNumber,
  country,
  city,
  address,
  giftNotes,
  additionalFeeId,
  additionalFeeAmount,
  paymentMethodId,
  paymentChannelId,
}: OrderListSwitchToDeliveryRequestBody): Promise<OrderListSwitchToDeliveryResponse> => {
  const requestBody = {
    delivery_detail: {
      delivery_date: format(deliveryDate, "yyyy-MM-dd HH:mm:ss"),
      return_date: returnDate
        ? format(returnDate, "yyyy-MM-dd HH:mm:ss")
        : null,
      recipient_id: recipientId,
      name,
      phone_number: phoneNumber
        ? formatInputPhoneNumberValue(phoneNumber)
        : null,
      country,
      city,
      address,
      gift_notes: giftNotes ?? "",
      additional_fee_id: additionalFeeId,
      amount: additionalFeeAmount,
      payment_method_id: paymentMethodId,
      payment_channel_id: paymentChannelId,
    },
  };

  const response = await API.patch<OrderListSwitchToPickUpResponse>(
    `${featureRouteApi}/${orderId}/order-method`,
    requestBody
  );

  return response.data;
};

export const orderListMarkAsPickUpOrDelivery = async ({
  id,
}: OrderListMarkAsPickUpOrDeliveryRequestBody): Promise<OrderListMarkAsPickUpOrDeliveryResponse> => {
  const response = await API.patch<OrderListMarkAsPickUpOrDeliveryResponse>(
    `${featureRouteApi}/${id}/status`
  );

  return response.data;
};

export const orderListMarkAsCompleteOrder = async ({
  id,
}: OrderListMarkAsCompleteOrderRequestBody): Promise<OrderListMarkAsCompleteOrderResponse> => {
  const response = await API.patch<OrderListMarkAsCompleteOrderResponse>(
    `${featureRouteApi}/${id}/status`
  );

  return response.data;
};

export const orderListGetEditOrderDetail = async ({
  orderId,
}: OrderListGetEditOrderDetailRequestBody): Promise<OrderListGetEditOrderDetailResponse> => {
  const response = await API.get<OrderListGetEditOrderDetailResponse>(
    `/transactions/${orderId}/meta`,
    {
      params: {
        edit: 1,
      },
    }
  );

  return response.data;
};
