import { Command as CommandPrimitive } from "cmdk";
import { AddCircle } from "iconsax-react";
import React from "react";
import { cn } from "src/lib/utils";
import Spinner from "../Spinner";
import { Button } from "../ui/button";
import {
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "../ui/command";
import useSearchInput, {
  CustomerAndPartnerSelectionOption,
} from "./hooks/useCustomerAndPartnerSelectionHandler";
import CreateCustomerModal from "./CreateCustomerModal";
import { capitalize } from "src/utils/commons";
import { USER_TYPE_BACKGROUND } from "src/constant";

export interface CustomerAndPartnerSelectionProps {
  onValueChange: (value: CustomerAndPartnerSelectionOption) => void;
}

const CustomerAndPartnerSelection: React.FC<
  CustomerAndPartnerSelectionProps
> = ({ onValueChange }) => {
  const {
    handleChangeSearchInput,
    inputRef,
    searchInput,
    isOpen,
    setIsOpen,
    isShowCreateCustomerModal,
    isLoading,
    handleBlur,
    handleSelectOption,
    searchOptions,
    hasMoreData,
    handleLoadMore,
    handleCreateCustomerModal,
    handleCloseCreateCustomerModal,
  } = useSearchInput({
    onValueChange,
  });

  return (
    <div
      className={`flex flex-1 w-full items-center border border-input bg-white rounded-lg relative `}
    >
      <CommandPrimitive
        // onKeyDown={handleKeyDown}
        className="border-0 w-full flex-1 outline-none h-full"
      >
        <CommandInput
          ref={inputRef}
          value={searchInput}
          onValueChange={isLoading ? undefined : handleChangeSearchInput}
          onBlur={handleBlur}
          onFocus={() => setIsOpen(true)}
          placeholder="Select customer or partner name or phone number"
          className="text-sm"
          noBorderBottom
          noIcon
        />

        <div className="relative mt-1">
          <div
            className={cn(
              "animate-in fade-in-0 zoom-in-95 absolute top-0 z-10 w-full rounded-xl bg-white outline-none",
              isOpen ? "block" : "hidden"
            )}
          >
            <CommandList asChild className="rounded-lg ring-1 ring-slate-200">
              {isLoading ? (
                <CommandPrimitive.Loading>
                  <div className="flex items-center justify-center p-2">
                    <Spinner />
                  </div>
                </CommandPrimitive.Loading>
              ) : null}
              {searchOptions.length > 0 && !isLoading ? (
                <CommandGroup>
                  {searchOptions.map((option) => {
                    // const isSelected = selected?.value === option.value;

                    const label = option.label;
                    const value = option.value;
                    const userType = option.type;
                    const customerType = option.customerType;
                    const customerTypeHex = option.typeHex;

                    return (
                      <CommandItem
                        key={value}
                        value={label}
                        onMouseDown={(event) => {
                          event.preventDefault();
                          event.stopPropagation();
                        }}
                        onSelect={() => handleSelectOption(option)}
                        className={cn(
                          "w-full flex justify-between gap-4 py-3 mb-1"
                        )}
                      >
                        <span className="font-normal text-sm">{label}</span>

                        <div
                          className="font-normal text-xs px-1 rounded"
                          style={{
                            backgroundColor:
                              userType === "customer"
                                ? customerTypeHex
                                : userType === "partner"
                                ? USER_TYPE_BACKGROUND.Partner
                                : USER_TYPE_BACKGROUND.Vendor,
                          }}
                        >
                          {userType === "customer"
                            ? customerType
                            : capitalize(userType)}
                        </div>
                      </CommandItem>
                    );
                  })}
                </CommandGroup>
              ) : null}

              {!isLoading ? (
                <CommandPrimitive.Empty className="select-none rounded-sm px-2 py-3 text-center text-sm">
                  No Match Found
                </CommandPrimitive.Empty>
              ) : null}

              {hasMoreData && (
                <CommandPrimitive.List className="flex items-center justify-center mb-2 mt-1">
                  <div className="p-2 cursor-pointer" onClick={handleLoadMore}>
                    <span className="text-center font-medium text-darkIndicator-40 select-none">
                      Load More
                    </span>
                  </div>
                </CommandPrimitive.List>
              )}

              {searchInput && (
                <CommandPrimitive.List>
                  <div className="flex items-center justify-between mt-1 border-t-2 rounded-xl px-3.5 py-1">
                    <div className="flex-1 text-wrap break-all">
                      <span className="font-normal text-sm">{`${searchInput} not available?`}</span>
                    </div>

                    <Button
                      variant="link"
                      className="font-normal -mr-3.5"
                      onClick={handleCreateCustomerModal}
                    >
                      <AddCircle size={18} className="mr-1" /> Create New
                      Customer Data
                    </Button>
                  </div>
                </CommandPrimitive.List>
              )}
            </CommandList>
          </div>
        </div>
      </CommandPrimitive>

      <CreateCustomerModal
        searchInput={searchInput}
        handleChangeSearchInput={handleChangeSearchInput}
        isShow={isShowCreateCustomerModal}
        onClose={handleCloseCreateCustomerModal}
        onValueChange={onValueChange}
      />
    </div>
  );
};

export default CustomerAndPartnerSelection;
