import {
  ProductListDetailRequestBody,
  ProductListDetailResponse,
  ProductListParams,
  ProductListResponse,
  CreateProductListFormType,
  CreateProductListResponse,
  DeleteProductListResponse,
  DeleteProductRequestBody,
  EditProductListFormType,
  EditProductListResponse,
  ProductVariantListParams,
  ProductVariantListResponse,
  ProductListDetailStockPurchaseHistoryParams,
  ProductListDetailStockPurchaseHistoryResponse,
  ProductListDetailStockAdjustmentHistoryParams,
  ProductListDetailStockAdjustmentHistoryResponse,
  ProductListDetailStockParams,
  ProductListDetailStockResponse,
} from "src/pages/ProductManagement/ProductList/api/interfaces";
import { getPagination } from "src/utils/getPagination";
import API from ".";
import { convertArrayParamsToString } from "src/utils/convertArrayParamsToString";
import {
  getProductAndPackageSortingParams,
  ProductAndPackageSortValueType,
} from "src/pages/ProductManagement/ProductList/hooks/useProductListHandler";
import { format } from "date-fns";

const featureRouteApi = "/products";

const convertFormData = ({
  form,
  isEdit,
}: {
  form: CreateProductListFormType | EditProductListFormType;
  isEdit?: boolean;
}) => {
  return {
    name: form.name,
    product_category_id: form.categoryId,
    product_sub_category_id: form.subCategoryId,
    item_stock_unit_id: form.itemStockUnitId,
    low_stock_reminder: form.lowStockReminder,
    variants: (form.variants as EditProductListFormType["variants"]).map(
      (variant) => ({
        ...(isEdit && { product_variant_id: variant.id ?? null }),
        name: variant.name,
        sku: variant.sku,
        is_purchase: variant.isPurchase,
        is_rent: variant.isRent,
        is_not_for_sale: variant.isNotForSale,
        not_for_sale_until: variant.notForSaleUntil
          ? format(variant.notForSaleUntil, "yyyy-MM-dd")
          : "",
        sell_price: variant.sellPrice ? parseInt(`${variant.sellPrice}`) : 0,
        rent_price: variant.rentPrice ? parseInt(`${variant.rentPrice}`) : 0,
        description: variant.description,
        key_image: variant.keyImage,
      })
    ),
  };
};

export const getProductList = async ({
  limit,
  offset,
  productNameOrProductSKU,
  sortBy,
  productCategory,
  productSubCategory,
  productName,
  productVariant,
  availability,
  minPrice,
  maxPrice,
  productStatus,
  availableStock,
}: ProductListParams): Promise<ProductListResponse> => {
  const paginationParam = getPagination({ limit, offset });

  const response = await API.get<ProductListResponse>(featureRouteApi, {
    params: {
      ...paginationParam,
      ...(productCategory.length > 0
        ? {
            product_category_ids: convertArrayParamsToString(
              productCategory.map((option) => option.value)
            ),
          }
        : {}),
      ...(productSubCategory.length > 0
        ? {
            product_sub_category_ids: convertArrayParamsToString(
              productSubCategory.map((option) => option.value)
            ),
          }
        : {}),
      ...(productName.length > 0
        ? {
            product_ids: convertArrayParamsToString(
              productName.map((option) => option.value)
            ),
          }
        : {}),
      ...(productVariant.length > 0
        ? {
            product_variant_ids: convertArrayParamsToString(
              productVariant.map((option) => option.value)
            ),
          }
        : {}),
      ...(availability.length > 0
        ? {
            availability: convertArrayParamsToString(
              availability.map((option) => option.value)
            ),
          }
        : {}),
      ...(productStatus.length > 0
        ? {
            product_status: convertArrayParamsToString(
              productStatus.map((option) => option.value)
            ),
          }
        : {}),
      ...(availableStock.length > 0
        ? {
            stock: convertArrayParamsToString(
              availableStock.map((option) => option.value)
            ),
          }
        : {}),
      ...(maxPrice !== null ? { sell_range_max: maxPrice } : {}),
      ...(minPrice !== null ? { sell_range_min: minPrice } : {}),
      ...(productNameOrProductSKU ? { qn: productNameOrProductSKU } : {}),
      ...(sortBy
        ? getProductAndPackageSortingParams(
            sortBy as ProductAndPackageSortValueType
          )
        : {}),
    },
  });

  return response.data;
};

export const getProductVariantList = async ({
  limit,
  offset,
  name,
  sku,
  productName,
  selectedVariantIds,
}: ProductVariantListParams): Promise<ProductVariantListResponse> => {
  const paginationParam = getPagination({ limit, offset });

  const response = await API.get<ProductVariantListResponse>(
    "/product-variants",
    {
      params: {
        ...paginationParam,
        ...(name ? { name } : {}),
        ...(sku ? { sku } : {}),
        ...(productName ? { product_name: productName } : {}),
        ...(selectedVariantIds && selectedVariantIds?.length > 0
          ? { selected_variant_ids: selectedVariantIds }
          : {}),
      },
    }
  );

  return response.data;
};

export const getProductListDetail = async ({
  id,
}: ProductListDetailRequestBody): Promise<ProductListDetailResponse> => {
  const response = await API.get<ProductListDetailResponse>(
    `${featureRouteApi}/${id}`
  );

  return response.data;
};

export const getProductDetailStock = async ({
  productId,
}: ProductListDetailStockParams): Promise<ProductListDetailStockResponse> => {
  const response = await API.get<ProductListDetailStockResponse>(
    "/product-stocks/warehouses",
    {
      params: {
        product_id: productId,
      },
    }
  );

  return response.data;
};

export const getProductListDetailStockAdjustmentHistory = async ({
  limit,
  offset,
  productId,
}: ProductListDetailStockAdjustmentHistoryParams): Promise<ProductListDetailStockAdjustmentHistoryResponse> => {
  const paginationParam = getPagination({ limit, offset });

  const response =
    await API.get<ProductListDetailStockAdjustmentHistoryResponse>(
      "/product-stocks/logs",
      {
        params: {
          ...paginationParam,
          product_id: productId,
        },
      }
    );

  return response.data;
};

export const getProductListDetailStockPurchaseHistory = async ({
  limit,
  offset,
  productId,
}: ProductListDetailStockPurchaseHistoryParams): Promise<ProductListDetailStockPurchaseHistoryResponse> => {
  const paginationParam = getPagination({ limit, offset });

  const response = await API.get<ProductListDetailStockPurchaseHistoryResponse>(
    "/product-stocks/purchases",
    {
      params: {
        ...paginationParam,
        product_id: productId,
      },
    }
  );

  return response.data;
};

export const createProductList = async (
  form: CreateProductListFormType
): Promise<CreateProductListResponse> => {
  const requestBody = convertFormData({ form });

  const response = await API.post<CreateProductListResponse>(
    featureRouteApi,
    requestBody
  );

  return response.data;
};

export const editProductList = async ({
  id,
  ...form
}: EditProductListFormType): Promise<EditProductListResponse> => {
  const requestBody = convertFormData({ form, isEdit: true });

  const response = await API.put<CreateProductListResponse>(
    `${featureRouteApi}/${id}`,
    requestBody
  );

  return response.data;
};

export const deleteProductList = async ({
  id,
}: DeleteProductRequestBody): Promise<DeleteProductListResponse> => {
  const response = await API.delete<DeleteProductListResponse>(
    `${featureRouteApi}/${id}`
  );

  return response.data;
};
