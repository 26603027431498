import React from "react";
import { Button } from "../ui/button";
import { ChevronDown, ChevronUp } from "lucide-react";
import { setHours, setMinutes } from "date-fns";

interface TimePickerProps {
  timeValue: string;
  setTimeValue: React.Dispatch<React.SetStateAction<string>>;
  selectedDate: Date;
  setDatePicker: React.Dispatch<React.SetStateAction<Date | undefined>>;
}

const TimePicker: React.FC<TimePickerProps> = ({
  timeValue,
  setTimeValue,
  selectedDate,
  setDatePicker,
}) => {
  const hourValue = timeValue.split(":")[0];
  const minuteValue = timeValue.split(":")[1];

  const getSetTimeToSelectedDate = (newTimeValue: string) => {
    const [hours, minutes] = newTimeValue
      .split(":")
      .map((str) => parseInt(str, 10));
    const newSelectedDate = setHours(
      setMinutes(selectedDate ?? new Date(), minutes),
      hours
    );
    setDatePicker(newSelectedDate);
  };

  const handleIncreaseHour = () => {
    const hourNewValue = parseInt(hourValue) + 1;
    let newTimeValue = "";

    if (hourNewValue <= 23) {
      newTimeValue = `${
        hourNewValue >= 10 ? hourNewValue : `0${hourNewValue}`
      }:${minuteValue}`;
    } else {
      newTimeValue = `00:${minuteValue}`;
    }

    setTimeValue(newTimeValue);
    getSetTimeToSelectedDate(newTimeValue);
  };

  const handleDecreaseHour = () => {
    const hourNewValue = parseInt(hourValue) - 1;
    let newTimeValue = "";

    if (hourNewValue === -1) {
      newTimeValue = `23:${minuteValue}`;
    } else {
      newTimeValue = `${
        hourNewValue >= 10 ? hourNewValue : `0${hourNewValue}`
      }:${minuteValue}`;
    }

    setTimeValue(newTimeValue);
    getSetTimeToSelectedDate(newTimeValue);
  };

  const handleIncreaseMinute = () => {
    const minuteNewValue = parseInt(minuteValue) + 1;
    let newTimeValue = "";

    if (minuteNewValue <= 59) {
      newTimeValue = `${hourValue}:${
        minuteNewValue >= 10 ? minuteNewValue : `0${minuteNewValue}`
      }`;
    } else {
      newTimeValue = `${hourValue}:00`;
    }

    setTimeValue(newTimeValue);
    getSetTimeToSelectedDate(newTimeValue);
  };

  const handleDecreaseMinute = () => {
    const minuteNewValue = parseInt(minuteValue) - 1;
    let newTimeValue = "";

    if (minuteNewValue === -1) {
      newTimeValue = `${hourValue}:59`;
    } else {
      newTimeValue = `${hourValue}:${
        minuteNewValue >= 10 ? minuteNewValue : `0${minuteNewValue}`
      }`;
    }

    setTimeValue(newTimeValue);
    getSetTimeToSelectedDate(newTimeValue);
  };

  const handleHourChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newHour = e.target.value;

    if (/^\d{0,2}$/.test(newHour)) {
      if (newHour.length === 1) {
        newHour = `0${newHour}`;
      }

      if (parseInt(newHour) > 23) {
        newHour = "23";
      }

      const newTimeValue = `${newHour}:${minuteValue}`;
      setTimeValue(newTimeValue);
      getSetTimeToSelectedDate(newTimeValue);
    }
  };

  const handleMinuteChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newMinute = e.target.value;

    if (/^\d{0,2}$/.test(newMinute)) {
      if (newMinute.length === 1) {
        newMinute = `0${newMinute}`;
      }

      if (parseInt(newMinute) > 59) {
        newMinute = "59";
      }

      const newTimeValue = `${hourValue}:${newMinute}`;
      setTimeValue(newTimeValue);
      getSetTimeToSelectedDate(newTimeValue);
    }
  };

  return (
    <div className="time-picker-container min-w-24">
      <div className="flex items-center justify-center gap-3 pt-4 pb-2 border-t-2 mt-4">
        <div className="flex flex-col items-center gap-2">
          <Button
            className="!h-6 !w-6 !bg-purple-80 !text-white rounded-sm"
            onClick={handleIncreaseHour}
          >
            <ChevronUp size={18} />
          </Button>

          <div>
            <input
              type="text"
              value={hourValue}
              onChange={handleHourChange}
              maxLength={2}
              className="w-12 text-center outline-none"
              placeholder="HH"
            />
          </div>

          <Button
            className="!h-6 !w-6 !bg-purple-80 !text-white rounded-sm"
            onClick={handleDecreaseHour}
          >
            <ChevronDown size={18} />
          </Button>
        </div>

        <div>
          <span className="font-normal">:</span>
        </div>

        <div className="flex flex-col items-center gap-2">
          <Button
            className="!h-6 !w-6 !bg-purple-80 !text-white rounded-sm"
            onClick={handleIncreaseMinute}
          >
            <ChevronUp size={18} />
          </Button>

          <div>
            <input
              type="text"
              value={minuteValue}
              onChange={handleMinuteChange}
              maxLength={2}
              className="w-12 text-center outline-none"
              placeholder="MM"
            />
          </div>

          <Button
            className="!h-6 !w-6 !bg-purple-80 !text-white rounded-sm"
            onClick={handleDecreaseMinute}
          >
            <ChevronDown size={18} />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TimePicker;
