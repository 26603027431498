const locale = "id-ID";
const currency = "IDR";

export const convertToCurrency = (amount: number = 0): string => {
  const formatter = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
    minimumFractionDigits: 0,
  });

  return formatter.format(amount);
};

export const convertCurrencyToString = (formattedString: string) => {
  const formatter = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
    minimumFractionDigits: 0,
  });

  const parts = formatter.formatToParts(1234.5);
  const currencySymbol = parts?.find((part) => part.type === "currency")?.value;
  const groupingSeparator = parts?.find((part) => part.type === "group")?.value;

  let cleanedString = formattedString
    .replace(new RegExp(`\\${currencySymbol}`, "g"), "") // Remove currency symbol
    .replace(new RegExp(`\\${groupingSeparator}`, "g"), ""); // Remove grouping separators

  return cleanedString;
};

export const displayCurrencyInShort = (amount: number): string => {
  const isNegative = amount < 0;
  const absAmount = Math.abs(amount);

  let formattedAmount: string;

  if (absAmount >= 1e12) {
    formattedAmount = `${(absAmount / 1e12).toFixed(1)}T`; // Trillion
  } else if (absAmount >= 1e9) {
    formattedAmount = `${(absAmount / 1e9).toFixed(1)}B`; // Billion
  } else if (absAmount >= 1e6) {
    formattedAmount = `${(absAmount / 1e6).toFixed(1)}M`; // Million
  } else {
    formattedAmount = convertToCurrency(absAmount); // If less than a million
  }

  return `${absAmount >= 1e6 ? "Rp " : ""}${
    isNegative ? "-" : ""
  }${formattedAmount}`;
};
