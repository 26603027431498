import { format } from "date-fns";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  POSCartItemType,
  SelectedPackageProductType,
} from "src/pages/POS/hooks/usePOSHandler";
import { PosCartSchemaType } from "src/pages/POS/posCartSchema";
import { TransactionSummarySchemaType } from "src/pages/POS/TransactionSummary/transactionSummarySchema";
import { apiErrorHandler } from "src/utils/apiErrorHandler";
import { formatPhoneNumberForInputValue } from "src/utils/formatPhoneNumber";
import { useGetOrderListEditOrderDetail } from "../api/mutations";

const useEditOrderHandler = () => {
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [formValues, setFormValues] = useState<PosCartSchemaType>();
  const [posCartItems, setPOSCartItems] = useState<POSCartItemType[]>([]);
  const [selectedPackageProducts, setSelectedPackageProducts] = useState<
    SelectedPackageProductType[]
  >([]);

  const [transactionSummaryFormValues, setTransactionSummaryFormValues] =
    useState<TransactionSummarySchemaType>();

  const orderListGetEditOrderDetailMutation = useGetOrderListEditOrderDetail();

  const getPOSOrderDetail = useCallback(() => {
    if (id) {
      const payload = { orderId: parseInt(`${id}`) };

      orderListGetEditOrderDetailMutation.mutateAsync(payload, {
        onSuccess: ({ data }) => {
          const formValuesTemp: PosCartSchemaType = {
            id: parseInt(id),
            businessOperationId: data.items[0].business_operation.id,
            businessOperationName: data.items[0].business_operation.name,
            customer: {
              id: `${data.buyer.id}`,
              name: data.buyer.name,
              phoneNumber: data.buyer.phone_number
                ? formatPhoneNumberForInputValue(data.buyer.phone_number)
                : "",
              country: data.buyer.country,
              city: data.buyer.city,
              address: data.buyer.address ?? undefined,
              type: data.buyer?.type ?? "customer",
              customerTypeId: data.buyer.customer_type?.id
                ? `${data.buyer.customer_type?.id}`
                : null,
              customerType: data.buyer.customer_type?.name ?? null,
              hex: data.buyer.customer_type?.hex ?? null,
            },
            orders: data.items.map((item) => ({
              id: parseInt(
                `${item.product_variant?.id ?? item.package_variant?.id}`
              ),
              type: item.type,
              note: item.notes ?? "",
              businessOperationId: item.business_operation.id,
              businessOperationName: item.business_operation.name,
              markAsRent: item?.marked_rent ?? false,
              price: `${
                item.type === "product"
                  ? item.adjusted_price
                  : item.edited_price
              }`,
              discount: item.discount ? `${item.discount}` : "0",
              quantity: item.type === "product" ? "0" : `${item.quantity}`,
              warehouses: undefined, // No need
              products:
                item.variant_products?.map((variantProduct) => ({
                  id: `${variantProduct.product_variant.id}`,
                  warehouseId: `${variantProduct.warehouse.id}`,
                  warehouseName: variantProduct.warehouse.name,
                  quantity: `${variantProduct.quantity}`,
                  price: `${variantProduct.adjusted_price}`,
                  discount: `${variantProduct.discount}`,
                  markAsRent: variantProduct.marked_rent,
                })) ?? [],
              warehouse: {
                id: item.warehouse?.id ?? 0,
                quantity: `${item.quantity}`,
              },
            })),
            note: data.notes ?? "",
            hasRent: data.hasRent,
          };
          setFormValues(formValuesTemp);
          setPOSCartItems(
            data.items.map((item) => ({
              id: parseInt(
                `${item.product_variant?.id ?? item.package_variant?.id}`
              ),
              name:
                item.product_variant?.name ?? item.package_variant?.name ?? "",
              type: item.type,
              sub_category: {
                hex:
                  item.product_variant?.product.product_sub_category.hex ??
                  item.package_variant?.package.package_sub_category.hex ??
                  "",
              },
              image_url:
                item.product_variant?.image_url ??
                item.package_variant?.image_url ??
                "",
              product: item.product_variant
                ? {
                    id: item.product_variant.product.id,
                    name: item.product_variant.product.name,
                    low_stock_reminder:
                      item.product_variant.product.lowStockReminder,
                  }
                : undefined,
              package: item.package_variant
                ? {
                    id: item.package_variant.package.id,
                    name: item.package_variant.package.name,
                  }
                : undefined,
              stocks:
                item.stocks?.map((stock) => ({
                  warehouse: {
                    id: stock.warehouse.id,
                    name: stock.warehouse.name,
                  },
                })) ?? [],
              available: item.available,
              is_rent: item.is_rent,
              is_purchase: item.is_purchase,
              item_stock_unit: {
                name:
                  item.product_variant?.product.item_stock_unit.name ??
                  item.package_variant?.package.item_stock_unit.name ??
                  "",
              },
              products:
                item.variant_products?.map((variantProduct) => ({
                  id: variantProduct.warehouse.id,
                  warehouseName: variantProduct.warehouse.name,
                })) ?? [],
            }))
          );

          setSelectedPackageProducts(
            data.items
              .filter((item) => item.type === "package")
              .flatMap(
                (item) =>
                  item.variant_products?.map((variantProduct) => ({
                    id: variantProduct.product_variant.id,
                    packageVariantId: parseInt(`${item.package_variant?.id}`),
                    productName: variantProduct.product_variant.product.name,
                    variantName: variantProduct.product_variant.name,
                    itemStockUnit:
                      variantProduct.product_variant.product.item_stock_unit
                        .name,
                    stocks: variantProduct.stocks.map((stock) => ({
                      available: stock.available,
                      warehouse_id: stock.warehouse.id,
                      product_variant_id: variantProduct.product_variant.id,
                      warehouse: stock.warehouse,
                    })),
                    quantity: variantProduct.quantity,
                  })) ?? []
              )
          );

          const isEstimatedDeliveryDate =
            data.estimation_start_date !== null &&
            data.estimation_end_date !== null;
          const isAddPickupInformation =
            data.order_method === "pickup" &&
            (data.pickup_date !== null || isEstimatedDeliveryDate);
          const isOnlyEstimationPickupDate =
            data.order_method === "pickup" && isEstimatedDeliveryDate;

          setTransactionSummaryFormValues({
            ...formValuesTemp,
            orderDate: new Date(format(data.order_date, "yyyy-MM-dd")),
            orderMethod: data.order_method,
            additionalFees: data.additional_fees.map((additionalFee) => ({
              id: additionalFee.additional_fee.id,
              businessOperationId: additionalFee.business_operation?.id,
              name: additionalFee.additional_fee.name,
              businessOperationName: additionalFee.business_operation?.name,
              price: additionalFee.fee ? `${additionalFee.fee}` : "0",
            })),
            isEstimatedDeliveryDate,
            isAddPickupInformation,
            isOnlyEstimationPickupDate,
            isUseBuyerData: false,
            estimationDate:
              data.estimation_start_date !== null &&
              data.estimation_end_date !== null
                ? {
                    from: new Date(data.estimation_start_date),
                    to: new Date(data.estimation_end_date),
                  }
                : null,
            pickUpDate: data.pickup_date ? new Date(data.pickup_date) : null,
            returnDate: data.return_date ? new Date(data.return_date) : null,
            pickUpNote: data.pickup_notes ?? "",
            recipient: isEstimatedDeliveryDate
              ? null
              : data?.recipient
              ? {
                  idTemp: null,
                  id: data?.recipient?.id ?? null,
                  name: data.recipient.name,
                  phoneNumber: data.recipient.phone_number
                    ? formatPhoneNumberForInputValue(
                        data.recipient.phone_number
                      )
                    : "",
                  country: data.recipient.country ?? "",
                  city: data.recipient.city ?? "",
                  address: data.recipient.address ?? "",
                  isSave: false,
                  deliveryDate: new Date(data.delivery_date),
                  returnDate: data.return_date
                    ? new Date(data.return_date)
                    : null,
                  note: data.notes ?? "",
                }
              : null,
            promoCodes: data.promocodes.map((pc) => pc.id),
            deposit: data.deposit ? `${data.deposit}` : "0",
            totalPaid: data.total_paid ?? 0,
            totalPrice: data.total_price ?? 0,
            paymentMethodId: data.payment_method.id,
            paymentMethod: data.payment_method,
            paymentChannelId: data.payment_channel.id,
            paymentChannel: data.payment_channel,
            selectedPromoCodes:
              data.promocodes?.map((pc) => ({
                id: pc.id,
                name: pc.name,
                discount: pc.discount,
                discount_type: pc.discount_type,
              })) ?? [],
            appliedPromoCodes:
              data.applied_promocodes?.map((appliedPromoCode) => ({
                id: appliedPromoCode.id,
                applieds: appliedPromoCode.applieds,
                discount: appliedPromoCode.discount,
                name: appliedPromoCode.name,
              })) ?? [],
          });
          setIsLoading(false);
        },
        onError: (error) => {
          apiErrorHandler({ error });
          setIsLoading(false);
        },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    getPOSOrderDetail();
  }, [getPOSOrderDetail]);

  return {
    isLoading,
    formValues,
    posCartItems,
    selectedPackageProducts,
    transactionSummaryFormValues,
  };
};

export default useEditOrderHandler;
