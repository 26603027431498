import { useQuery } from "@tanstack/react-query";
import { reactQueryOptions } from "src/config/reactQuery.config";
import { getCurrentUser } from "src/services/Auth.service";

export const useCurrentUser = () => {
  return useQuery({
    ...reactQueryOptions,
    queryKey: ["me"],
    queryFn: () => getCurrentUser(),
    retry: false,
  });
};
