import { Command as CommandPrimitive } from "cmdk";
import { ArrowDown2 } from "iconsax-react";
import React from "react";
import { cn } from "src/lib/utils";
import Spinner from "../Spinner";
import {
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "../ui/command";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";
import useSearchInput, { GroupSearchInputOption } from "./hooks/useSearchInput";
import { capitalize } from "src/utils/commons";

export interface GroupSearchInputProps {
  selectedIds: string[];
  onValueChange: (value: GroupSearchInputOption) => void;
  type: "product" | "package" | "all";
}

const GroupSearchInput: React.FC<GroupSearchInputProps> = ({
  selectedIds,
  onValueChange,
  type,
}) => {
  const {
    handleChangeSearchInput,
    searchTypeOptions,
    inputRef,
    searchType,
    setSearchType,
    searchInput,
    isOpen,
    setIsOpen,
    isLoading,
    handleBlur,
    handleSelectOption,
    searchOptions,
    hasMoreData,
    handleLoadMore,
  } = useSearchInput({
    selectedIds,
    onValueChange,
    type,
  });

  const selectedSearchTypeOption = searchTypeOptions.find(
    (option) => option.label === searchType
  );
  const placeholder = selectedSearchTypeOption?.placeholder;

  return (
    <div
      className={`flex flex-1 items-center border border-input bg-white rounded-lg relative `}
    >
      <CommandPrimitive
        // onKeyDown={handleKeyDown}
        className="border-0 w-full flex-1 outline-none h-full"
      >
        <CommandInput
          ref={inputRef}
          value={searchInput}
          onValueChange={isLoading ? undefined : handleChangeSearchInput}
          onBlur={handleBlur}
          onFocus={() => setIsOpen(true)}
          placeholder={placeholder}
          className="text-base pr-52"
          noBorderBottom
        />

        <div className="relative mt-1">
          <div
            className={cn(
              "animate-in fade-in-0 zoom-in-95 absolute top-0 z-10 w-full rounded-xl bg-white outline-none",
              isOpen ? "block" : "hidden"
            )}
          >
            <CommandList asChild className="rounded-lg ring-1 ring-slate-200">
              {isLoading ? (
                <CommandPrimitive.Loading>
                  <div className="flex items-center justify-center p-2">
                    <Spinner />
                  </div>
                </CommandPrimitive.Loading>
              ) : null}
              {searchOptions.length > 0 && !isLoading ? (
                <CommandGroup>
                  {searchOptions.map((option) => {
                    // const isSelected = selected?.value === option.value;

                    const userInput = searchInput;

                    function highlightMatchingText(fullString: string) {
                      if (!fullString) return fullString;

                      // Escape special HTML characters in userInput
                      const escapedUserInput = userInput.replace(
                        /[&<>"']/g,
                        function (char) {
                          return "&#" + char.charCodeAt(0) + ";";
                        }
                      );

                      // Create a regular expression to find the userInput in the fullString
                      const regex = new RegExp(`(${escapedUserInput})`, "i");

                      // Replace the matching text with bolded text
                      const highlightedString = fullString.replace(
                        regex,
                        "<b>$1</b>"
                      );

                      return (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: highlightedString,
                          }}
                        />
                      );
                    }

                    const value =
                      searchType === searchTypeOptions[0]?.label
                        ? option.variant
                        : option.productName;

                    const list = [
                      {
                        label: capitalize(type),
                        value: option.productName,
                      },
                      {
                        label: "Variant",
                        value: option.variant,
                      },
                      {
                        label: "Category",
                        value: option.category,
                      },
                      {
                        label: "Sub - Category",
                        value: option.subCategory,
                      },
                    ];

                    return (
                      <CommandItem
                        key={option.value}
                        value={value}
                        onMouseDown={(event) => {
                          event.preventDefault();
                          event.stopPropagation();
                        }}
                        onSelect={() => handleSelectOption(option)}
                        className={cn(
                          "w-full grid grid-cols-4 gap-4 py-3 mb-1"
                          // !isSelected ? "pl-8" : null
                        )}
                      >
                        {list.map((l, idx) => (
                          <div className="flex flex-col" key={idx}>
                            <span className="font-normal text-sm">
                              {highlightMatchingText(l.value)}
                            </span>
                            <span className="font-medium text-xs text-darkIndicator-30">
                              {l.label}
                            </span>
                          </div>
                        ))}
                      </CommandItem>
                    );
                  })}
                </CommandGroup>
              ) : null}
              {!isLoading ? (
                <CommandPrimitive.Empty className="select-none rounded-sm px-2 py-3 text-center text-sm">
                  No Match Found
                </CommandPrimitive.Empty>
              ) : null}

              {hasMoreData && (
                <CommandPrimitive.List className="flex items-center justify-center mb-2 mt-1">
                  <div className="p-2 cursor-pointer" onClick={handleLoadMore}>
                    <span className="text-center font-medium text-darkIndicator-40 select-none">
                      Load More
                    </span>
                  </div>
                </CommandPrimitive.List>
              )}
            </CommandList>
          </div>
        </div>
      </CommandPrimitive>

      <DropdownMenu>
        <DropdownMenuTrigger className="outline-none absolute right-0">
          <div className="flex items-center border border-input bg-white rounded-lg p-3 select-none max-h-12">
            <span className="font-normal me-4">
              {selectedSearchTypeOption?.label}
            </span>

            <ArrowDown2 />
          </div>
        </DropdownMenuTrigger>

        <DropdownMenuContent>
          <DropdownMenuRadioGroup
            value={searchType}
            onValueChange={setSearchType}
          >
            {searchTypeOptions.map((option, index) => {
              const isSelected = option.label === searchType;

              return (
                <DropdownMenuRadioItem
                  className={`${
                    isSelected ? "bg-lightBrown-default" : ""
                  } mb-1`}
                  value={option.label}
                  key={index}
                >
                  {option.label}
                </DropdownMenuRadioItem>
              );
            })}
          </DropdownMenuRadioGroup>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};

export default GroupSearchInput;
