// "use client";

// import * as React from "react";
// import { ChevronLeft, ChevronRight } from "lucide-react";
// import { DayPicker } from "react-day-picker";

// import { cn } from "src/lib/utils";
// import { buttonVariants } from "src/components/ui/button";

// export type CalendarProps = React.ComponentProps<typeof DayPicker>;

// function Calendar({
//   className,
//   classNames,
//   showOutsideDays = true,
//   ...props
// }: CalendarProps) {
//   return (
//     <DayPicker
//       showOutsideDays={showOutsideDays}
//       className={cn("p-3", className)}
//       classNames={{
//         months: "flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0",
//         month: "space-y-4",
//         caption: "flex justify-center pt-1 relative items-center",
//         caption_label: "text-sm font-medium",
//         nav: "space-x-1 flex items-center",
//         nav_button: cn(
//           buttonVariants({ variant: "outline" }),
//           "h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100"
//         ),
//         nav_button_previous: "absolute left-1",
//         nav_button_next: "absolute right-1",
//         table: "w-full border-collapse space-y-1",
//         head_row: "flex",
//         head_cell:
//           "text-muted-foreground rounded-md w-9 font-normal text-[0.8rem]",
//         row: "flex w-full mt-2",
//         cell: "h-9 w-9 text-center text-sm p-0 relative [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-outside)]:bg-accent/50 [&:has([aria-selected])]:bg-lightPurple-20 first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20",
//         day: cn(
//           buttonVariants({ variant: "ghost" }),
//           "h-9 w-9 p-0 font-normal aria-selected:opacity-100"
//         ),
//         day_selected:
//           "bg-purple-80 text-primary-foreground hover:bg-purple-80 hover:text-primary-foreground focus:bg-purple-80 focus:text-primary-foreground",
//         day_range_start: "!bg-purple-80 !text-white",
//         day_range_end: "day-range-end !bg-purple-80 !text-white",
//         day_today: "bg-accent text-accent-foreground",
//         day_outside:
//           "day-outside text-muted-foreground opacity-50 aria-selected:bg-accent/50 aria-selected:text-muted-foreground aria-selected:opacity-30",
//         day_disabled: "text-muted-foreground opacity-50",
//         day_range_middle:
//           "aria-selected:bg-lightPurple-20 aria-selected:text-accent-foreground",
//         day_hidden: "invisible",
//         ...classNames,
//       }}
//       components={{
//         IconLeft: ({ ...props }) => <ChevronLeft className="h-4 w-4" />,
//         IconRight: ({ ...props }) => <ChevronRight className="h-4 w-4" />,
//       }}
//       {...props}
//     />
//   );
// }
// Calendar.displayName = "Calendar";

// export { Calendar };

"use client";

import {
  addDays,
  addMonths,
  addYears,
  differenceInCalendarDays,
  format,
  isAfter,
  isBefore,
  subYears,
} from "date-fns";
import { ChevronLeft, ChevronRight } from "lucide-react";
import * as React from "react";
import {
  DateRange,
  DayPicker,
  isDateRange,
  labelNext,
  labelPrevious,
  useDayPicker,
  type DayPickerProps,
} from "react-day-picker";
import { Button, buttonVariants } from "src/components/ui/button";
import { cn } from "src/lib/utils";

export type CalendarPickerMode = "days" | "months" | "years";

export type CalendarProps = DayPickerProps & {
  /**
   * In the year view, the number of years to display at once.
   * @default 12
   */
  yearRange?: number;
  minDate?: Date | null;
  maxDate?: Date | null;
  pickerMode?: CalendarPickerMode;
  selected: Date | DateRange;
  onSelect: (value: any) => void;
  autoPreFill?: boolean;
};

function Calendar({
  className,
  classNames,
  showOutsideDays = true,
  yearRange = 12,
  numberOfMonths,
  minDate,
  maxDate,
  pickerMode = "days",
  autoPreFill,
  ...props
}: CalendarProps) {
  const [isInit, setIsInit] = React.useState(true);
  const [navView, setNavView] = React.useState<CalendarPickerMode>(pickerMode);
  const [activeYear, setActiveYear] = React.useState<number>(
    new Date().getFullYear()
  );
  const [displayYears, setDisplayYears] = React.useState<{
    from: number;
    to: number;
  }>(
    React.useMemo(() => {
      const currentYear = new Date().getFullYear();
      return {
        from: currentYear - Math.floor(yearRange / 2 - 1),
        to: currentYear + Math.ceil(yearRange / 2),
      };
    }, [yearRange])
  );

  const {
    mode,
    selected,
    onSelect,
    onNextClick,
    onPrevClick,
    startMonth,
    endMonth,
  } = props;

  const isDayMode = pickerMode === "days";
  const isMonthMode = pickerMode === "months";
  const isYearMode = pickerMode === "years";

  const columnsDisplayed = isMonthMode || isYearMode ? 1 : numberOfMonths;

  let disabledDate = undefined;

  if (minDate) {
    disabledDate = { before: minDate };
  }
  if (maxDate) {
    disabledDate = { after: maxDate };
  }

  React.useEffect(() => {
    if (
      !isInit &&
      !(selected as DateRange)?.from &&
      !(selected as DateRange)?.to
    ) {
      setIsInit(false);
    }
  }, [isInit, selected]);

  React.useEffect(() => {
    if (isDayMode) {
      if (
        isInit &&
        (selected as DateRange)?.from &&
        !(selected as DateRange)?.to &&
        autoPreFill
      ) {
        onSelect({
          from: (selected as DateRange).from,
          to: addDays((selected as DateRange).from ?? new Date(), 6),
        });
        setIsInit(false);
      }
    }
  }, [isInit, autoPreFill, isDayMode, onSelect, selected]);

  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={cn("p-3", className)}
      style={{
        width: (isDayMode ? 248.8 : 320) * (columnsDisplayed ?? 1) + "px",
      }}
      classNames={{
        // cell: "h-9 w-9 text-center text-sm p-0 relative [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-outside)]:bg-accent/50 [&:has([aria-selected])]:bg-lightPurple-20 first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20",
        // day: cn(
        //   buttonVariants({ variant: "ghost" }),
        //   "h-9 w-9 p-0 font-normal aria-selected:opacity-100"
        // ),
        // day_selected:
        //   "bg-purple-80 text-primary-foreground hover:bg-purple-80 hover:text-primary-foreground focus:bg-purple-80 focus:text-primary-foreground",
        // day_range_start: "!bg-purple-80 !text-white",
        // day_range_end: "day-range-end !bg-purple-80 !text-white",
        // day_today: "bg-accent text-accent-foreground",
        // day_outside:
        //   "day-outside text-muted-foreground opacity-50 aria-selected:bg-accent/50 aria-selected:text-muted-foreground aria-selected:opacity-30",
        // day_disabled: "text-muted-foreground opacity-50",
        // day_range_middle:
        //   "aria-selected:bg-lightPurple-20 aria-selected:text-accent-foreground",
        // day_hidden: "invisible",

        months: "flex flex-row relative",
        month_caption: "flex justify-center h-7 mx-10 relative items-center",
        weekdays: "flex flex-row",
        weekday: "text-muted-foreground w-8 font-normal text-[0.8rem]",
        month: "gap-y-4 overflow-x-hidden w-full",
        caption: "flex justify-center pt-1 relative items-center",
        caption_label: "text-sm font-medium truncate",
        button_next: cn(
          buttonVariants({
            variant: "outline",
            className:
              "absolute right-0 h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100",
          })
        ),
        button_previous: cn(
          buttonVariants({
            variant: "outline",
            className:
              "absolute left-0 h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100",
          })
        ),
        nav: "flex items-start",
        month_grid: "mt-4",
        week: "flex w-full mt-2",
        day: "p-0 size-8 text-sm flex-1 flex items-center justify-center has-[button]:hover:!bg-accent rounded-md has-[button]:hover:aria-selected:!bg-purple-80 has-[button]:hover:text-accent-foreground has-[button]:hover:aria-selected:text-white",
        day_button: cn(
          buttonVariants({ variant: "ghost" }),
          "size-8 p-0 font-normal transition-none hover:bg-transparent hover:text-inherit aria-selected:opacity-100"
        ),
        range_start: "day-range-start rounded-s-md !bg-purple-80 !text-white",
        range_end: "day-range-end rounded-e-md !bg-purple-80 !text-white",
        selected:
          "bg-purple-80 text-white hover:bg-purple-80 hover:text-primary-foreground focus:bg-purple-80 focus:text-primary-foreground",
        today: "bg-accent text-accent-foreground",
        outside:
          "day-outside text-muted-foreground opacity-50 aria-selected:bg-accent/50 aria-selected:text-muted-foreground aria-selected:opacity-30",
        disabled: "text-muted-foreground opacity-50 cursor-not-allowed",
        range_middle:
          "aria-selected:bg-accent hover:aria-selected:!bg-accent rounded-none aria-selected:text-accent-foreground hover:aria-selected:text-accent-foreground",
        hidden: "invisible",
        ...classNames,
      }}
      components={{
        Chevron: ({ orientation }) => {
          const Icon = orientation === "left" ? ChevronLeft : ChevronRight;
          return <Icon className="h-4 w-4" />;
        },
        Nav: ({ className, children, ...props }) => {
          const { nextMonth, previousMonth, goToMonth } = useDayPicker();

          const isPreviousDisabled = (() => {
            if (isYearMode) {
              return (
                (startMonth &&
                  differenceInCalendarDays(
                    new Date(displayYears.from - 1, 0, 1),
                    startMonth
                  ) < 0) ||
                (endMonth &&
                  differenceInCalendarDays(
                    new Date(displayYears.from - 1, 0, 1),
                    endMonth
                  ) > 0)
              );
            }
            return !previousMonth;
          })();

          const isNextDisabled = (() => {
            if (isYearMode) {
              return (
                (startMonth &&
                  differenceInCalendarDays(
                    new Date(displayYears.to + 1, 0, 1),
                    startMonth
                  ) < 0) ||
                (endMonth &&
                  differenceInCalendarDays(
                    new Date(displayYears.to + 1, 0, 1),
                    endMonth
                  ) > 0)
              );
            }
            return !nextMonth;
          })();

          const handlePreviousClick = React.useCallback(() => {
            if (!previousMonth) return;

            if (isMonthMode) {
              setActiveYear(
                parseInt(
                  format(subYears(new Date(activeYear, 0, 0), 0), "yyyy")
                )
              );
            } else if (isYearMode) {
              setDisplayYears((prev) => ({
                from: prev.from - (prev.to - prev.from + 1),
                to: prev.to - (prev.to - prev.from + 1),
              }));
              onPrevClick?.(
                new Date(
                  displayYears.from - (displayYears.to - displayYears.from),
                  0,
                  1
                )
              );
              return;
            }
            goToMonth(previousMonth);
            onPrevClick?.(previousMonth);
          }, [previousMonth, goToMonth]);

          const handleNextClick = React.useCallback(() => {
            if (!nextMonth) return;

            if (isMonthMode) {
              setActiveYear(
                parseInt(
                  format(addYears(new Date(activeYear, 0, 0), 2), "yyyy")
                )
              );
            } else if (isYearMode) {
              setDisplayYears((prev) => ({
                from: prev.from + (prev.to - prev.from + 1),
                to: prev.to + (prev.to - prev.from + 1),
              }));
              onNextClick?.(
                new Date(
                  displayYears.from + (displayYears.to - displayYears.from),
                  0,
                  1
                )
              );
              return;
            }
            goToMonth(nextMonth);
            onNextClick?.(nextMonth);
          }, [goToMonth, nextMonth]);

          return (
            <nav className={cn("flex items-center", className)} {...props}>
              <Button
                variant="outline"
                className="absolute left-0 h-7 w-7 bg-transparent p-0 opacity-80 hover:opacity-100"
                type="button"
                tabIndex={isPreviousDisabled ? undefined : -1}
                disabled={isPreviousDisabled}
                aria-label={
                  navView === "years"
                    ? `Go to the previous ${
                        displayYears.to - displayYears.from + 1
                      } years`
                    : labelPrevious(previousMonth)
                }
                onClick={handlePreviousClick}
              >
                <ChevronLeft className="h-4 w-4" />
              </Button>

              <Button
                variant="outline"
                className="absolute right-0 h-7 w-7 bg-transparent p-0 opacity-80 hover:opacity-100"
                type="button"
                tabIndex={isNextDisabled ? undefined : -1}
                disabled={isNextDisabled}
                aria-label={
                  navView === "years"
                    ? `Go to the next ${
                        displayYears.to - displayYears.from + 1
                      } years`
                    : labelNext(nextMonth)
                }
                onClick={handleNextClick}
              >
                <ChevronRight className="h-4 w-4" />
              </Button>
            </nav>
          );
        },
        CaptionLabel: ({ children }) =>
          isMonthMode ? (
            <div className="d-flex items-center justify-center h-7 w-full truncate text-center">
              <span className="text-md font-medium">{activeYear}</span>
            </div>
          ) : (
            <Button
              className="h-7 w-full truncate text-sm font-medium"
              variant="ghost"
              size="sm"
              onClick={() =>
                setNavView((prev) => (prev === "days" ? "years" : "days"))
              }
            >
              {isDayMode && children}
              {isYearMode && `${displayYears.from} - ${displayYears.to}`}
            </Button>
          ),
        MonthGrid: ({ className, children, ...props }) => {
          const { goToMonth } = useDayPicker();
          if (isYearMode) {
            return (
              <div
                className={cn("grid grid-cols-4 gap-y-2", className)}
                {...props}
              >
                {Array.from(
                  { length: displayYears.to - displayYears.from + 1 },
                  (_, i) => {
                    const isBeforeYear =
                      differenceInCalendarDays(
                        new Date(displayYears.from + i, 12, 31),
                        startMonth!
                      ) < 0;

                    const isAfterYear =
                      differenceInCalendarDays(
                        new Date(displayYears.from + i, 0, 0),
                        endMonth!
                      ) > 0;

                    const isDisabled = isBeforeYear || isAfterYear;

                    const currentYear = displayYears.from + i;
                    const currentYearStr = `${currentYear}`;
                    const currentYearDate = new Date(currentYear, 0);

                    return (
                      <Button
                        key={i}
                        className={cn(
                          "h-7 w-full text-sm font-normal text-foreground",

                          (selected && isDateRange(selected)
                            ? currentYearStr ===
                                (selected.from
                                  ? format(selected.from, "yyyy")
                                  : "") ||
                              currentYearStr ===
                                (selected.to ? format(selected.to, "yyyy") : "")
                            : currentYearStr === format(selected, "yyyy")) &&
                            "!bg-purple-80 !text-white",

                          isDateRange(selected) &&
                            selected.from &&
                            selected.to &&
                            currentYear >
                              parseInt(
                                format(selected.from ?? new Date(), "yyyy")
                              ) &&
                            currentYear <
                              parseInt(
                                format(selected.to ?? new Date(), "yyyy")
                              ) &&
                            "!bg-lightPurple-20 rounded-none"

                          // displayYears.from + i === new Date().getFullYear() &&
                          //   "bg-accent font-medium text-accent-foreground"
                        )}
                        variant="ghost"
                        onClick={() => {
                          if (isDayMode) {
                            setNavView("days");
                          }
                          if (isYearMode) {
                            if (mode === "single") {
                              onSelect(currentYearDate);
                            } else if (mode === "range") {
                              if (!(selected as DateRange).from) {
                                onSelect({
                                  from: currentYearDate,
                                  to: autoPreFill
                                    ? addYears(currentYearDate, 6)
                                    : undefined,
                                });
                              } else {
                                if (
                                  `${currentYearDate}` ===
                                  `${(selected as DateRange).from}`
                                ) {
                                  onSelect({
                                    from: undefined,
                                    to: undefined,
                                  });
                                } else if (
                                  isBefore(
                                    currentYearDate,
                                    (selected as DateRange).from ?? new Date()
                                  )
                                ) {
                                  onSelect({
                                    from: currentYearDate,
                                    to: (selected as DateRange).to,
                                  });
                                } else {
                                  onSelect({
                                    from: (selected as DateRange).from,
                                    to: currentYearDate,
                                  });
                                }
                              }
                            }
                          }

                          goToMonth(
                            new Date(
                              displayYears.from + i,
                              new Date().getMonth()
                            )
                          );
                        }}
                        disabled={navView === "years" ? isDisabled : undefined}
                      >
                        {displayYears.from + i}
                      </Button>
                    );
                  }
                )}
              </div>
            );
          } else if (isMonthMode) {
            return (
              <div
                className={cn("grid grid-cols-3 gap-y-3", className)}
                {...props}
              >
                {[...Array(12)].map((_, i) => {
                  // const isBeforeYear =
                  //   differenceInCalendarDays(
                  //     new Date(displayMonths.from + i, 12, 31),
                  //     startMonth!
                  //   ) < 0;

                  // const isAfterYear =
                  //   differenceInCalendarDays(
                  //     new Date(displayYears.from + i, 0, 0),
                  //     endMonth!
                  //   ) > 0;

                  // const isDisabled = isBeforeYear || isAfterYear;

                  const currentMonth = i + 1;
                  const currentMonthDate = new Date(
                    activeYear,
                    currentMonth - 1,
                    1
                  );
                  const currentMonthYearStr = format(
                    currentMonthDate,
                    "yyyy-MM"
                  );

                  return (
                    <Button
                      key={i}
                      className={cn(
                        "h-9 px-2 w-full text-sm font-normal text-foreground",

                        (selected && isDateRange(selected)
                          ? currentMonthYearStr ===
                              (selected.from
                                ? format(selected.from, "yyyy-MM")
                                : "") ||
                            currentMonthYearStr ===
                              (selected.to
                                ? format(selected.to, "yyyy-MM")
                                : "")
                          : currentMonthYearStr ===
                            format(selected, "yyyy-MM")) &&
                          "!bg-purple-80 !text-white",

                        isDateRange(selected) &&
                          selected.from &&
                          selected.to &&
                          isAfter(
                            format(
                              new Date(activeYear, currentMonth, 0),
                              "yyyy-MM"
                            ),
                            format(selected.from, "yyyy-MM")
                          ) &&
                          isBefore(
                            format(
                              new Date(activeYear, currentMonth, 0),
                              "yyyy-MM"
                            ),
                            format(selected.to, "yyyy-MM")
                          ) &&
                          "!bg-lightPurple-20 rounded-none"

                        // displayYears.from + i === new Date().getFullYear() &&
                        //   "bg-accent font-medium text-accent-foreground"
                      )}
                      variant="ghost"
                      onClick={() => {
                        if (mode === "single") {
                          onSelect(currentMonthDate);
                        } else if (mode === "range") {
                          if (!(selected as DateRange).from) {
                            onSelect({
                              from: currentMonthDate,
                              to: autoPreFill
                                ? addMonths(currentMonthDate, 11)
                                : undefined,
                            });
                          } else {
                            if (
                              `${currentMonthDate}` ===
                              `${(selected as DateRange).from}`
                            ) {
                              onSelect({
                                from: undefined,
                                to: undefined,
                              });
                            } else if (
                              isBefore(
                                currentMonthDate,
                                (selected as DateRange).from ?? new Date()
                              )
                            ) {
                              onSelect({
                                from: currentMonthDate,
                                to: (selected as DateRange).to,
                              });
                            } else {
                              onSelect({
                                from: (selected as DateRange).from,
                                to: currentMonthDate,
                              });
                            }
                          }
                        }

                        goToMonth(
                          new Date(displayYears.from + i, new Date().getMonth())
                        );
                      }}
                      // disabled={navView === "months" ? isDisabled : undefined}
                    >
                      {format(currentMonthDate, "MMMM")}
                    </Button>
                  );
                })}
              </div>
            );
          }
          return (
            <table className={className} {...props}>
              {children}
            </table>
          );
        },
      }}
      numberOfMonths={columnsDisplayed}
      disabled={disabledDate}
      {...props}
    />
  );
}
Calendar.displayName = "Calendar";

export { Calendar };
