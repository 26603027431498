import { TriangleAlert } from "lucide-react";
import React from "react";
import { Avatar, AvatarFallback, AvatarImage } from "src/components/ui/avatar";
import { Button } from "src/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "src/components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "src/components/ui/dropdown-menu";
import useAdminDropdownMenu from "./hooks/useAdminDropdownMenu";

interface AdminDropdownMenuProps {
  handleChangeMyPassword: () => void;
}

const AdminDropdownMenu: React.FC<AdminDropdownMenuProps> = ({
  handleChangeMyPassword,
}) => {
  const {
    admin,
    handleLogout,
    isDialogOpen,
    setIsDialogOpen,
    handleDialogOpen,
    handleDialogClose,
  } = useAdminDropdownMenu();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost">
          <div className="flex gap-4">
            <Avatar className="hidden h-9 w-9 sm:flex bg-primary">
              <AvatarImage src="/avatars/01.png" alt="Avatar" />
              <AvatarFallback className="uppercase">
                {admin.name.substring(0, 2)}
              </AvatarFallback>
            </Avatar>

            <div className="text-left flex justify-center items-center">
              <p className="text-sm 2xl:text-xl font-medium">{admin.name}</p>
            </div>
          </div>
        </Button>
      </DropdownMenuTrigger>

      <DropdownMenuContent className="w-56">
        <DropdownMenuLabel>My Account</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuGroup>
          <DropdownMenuItem onClick={handleChangeMyPassword}>
            Change Password
          </DropdownMenuItem>
        </DropdownMenuGroup>
        <DropdownMenuSeparator />

        <DropdownMenuItem onClick={handleDialogOpen}>Log out</DropdownMenuItem>
      </DropdownMenuContent>

      <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <DialogContent className="min-w-[800px] 2xl:min-w-[1200px]">
          <DialogHeader>
            <DialogTitle className="border-b text-base 2xl:text-xl font-bold pb-4">
              Logout Confirmation
            </DialogTitle>
            <DialogDescription className="pt-4" asChild>
              <div className="flex items-center ">
                <div className="bg-primary-600 rounded-full p-4 m-4 w-[146px] h-[146px] flex items-center justify-center">
                  <TriangleAlert className="w-[118px] h-[118px] text-redIndicator-50" />
                </div>
                <div className="">
                  <h1 className="text-lg 2xl:text-2xl text-black font-bold mt-4">
                    Are you sure?
                  </h1>
                </div>
              </div>
            </DialogDescription>
          </DialogHeader>
          <div className="flex justify-end gap-4 mt-4">
            <Button
              className="font-semibold"
              variant="outline"
              onClick={handleDialogClose}
            >
              Cancel
            </Button>
            <Button
              className="border-redIndicator-40 font-semibold"
              onClick={handleLogout}
            >
              Log out
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </DropdownMenu>
  );
};

export default AdminDropdownMenu;
