import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { useForm, UseFormReturn } from "react-hook-form";
import { ErrorToast } from "src/config/reactQuery.config";
import { useGetAccessPermission } from "src/hooks/useGetAccessPermission";
import { addToCart } from "src/services/POS.service";
import { AccessResource, ActionType } from "src/types/accessControl";
import { apiErrorHandler } from "src/utils/apiErrorHandler";
import {
  addProductModalSchema,
  AddProductModalSchemaType,
} from "../addProductModalSchema";
import { useGetPOSItemDetail } from "../api/queries";
import { PosCartSchemaType } from "../posCartSchema";
import { POSCartItemType, SelectedPackageProductType } from "./usePOSHandler";

interface UseAddProductModalHandlerProps {
  isEditOrder: boolean;
  transactionId: number | undefined;
  selectedPOSItem: {
    id: number;
    type: "product" | "package";
  };
  selectedPosOrderItem: PosCartSchemaType["orders"][number] | undefined;
  selectedPosOrderItemIndex: number | null;
  onClose: () => void;
  posForm: UseFormReturn<PosCartSchemaType, any, undefined>;
  setPOSCartItems: React.Dispatch<React.SetStateAction<POSCartItemType[]>>;
  setSelectedPackageProducts: React.Dispatch<
    React.SetStateAction<SelectedPackageProductType[]>
  >;
}

const posAddProductModalDefaultValue = {
  businessOperationId: undefined,
  businessOperationName: "",
  markAsRent: false,
  warehouses: [],
  price: "0",
  discount: "0",
  quantity: "1",
  products: [],
};

export const getPOSPackagePricing = ({
  products,
  price,
  discount,
  quantity,
}: {
  products: AddProductModalSchemaType["products"];
  price: string;
  discount: string;
  quantity: number;
}) => {
  const totalPackageProductsPrice = products
    .map((product) => {
      const price = parseInt(product.price, 0);
      const quantity = parseInt(product.quantity, 0);
      const discount = parseInt(product.discount, 0);

      return Math.max(Math.max(price * quantity, 0) - discount, 0);
    })
    .reduce((a, b) => a + b, 0);

  const packageRemainPrice =
    parseInt(`${price}`, 0) * quantity -
    totalPackageProductsPrice -
    parseInt(`${discount}`, 0);
  const packageRemainingPrice = Math.max(packageRemainPrice, 0);

  const packageTotalExcessPrice =
    packageRemainPrice > 0 ? 0 : Math.abs(packageRemainPrice);

  return {
    totalPackageProductsPrice,
    packageRemainingPrice,
    packageTotalExcessPrice,
  };
};

const useAddProductModalHandler = ({
  isEditOrder,
  transactionId,
  selectedPOSItem,
  selectedPosOrderItem,
  selectedPosOrderItemIndex,
  onClose,
  posForm,
  setPOSCartItems,
  setSelectedPackageProducts,
}: UseAddProductModalHandlerProps) => {
  const [isShowOverBudgetAlert, setIsShowOverBudgetAlert] = useState(false);

  const isAllowToEditPrice = useGetAccessPermission({
    resource: AccessResource["POS | Edit Product Price"],
    action: ActionType["Allow"],
  });

  const { data, isLoading } = useGetPOSItemDetail({
    id: selectedPOSItem.id,
    type: selectedPOSItem.type,
    edit: isEditOrder ? 1 : 0,
    transactionId,
  });

  const form = useForm<AddProductModalSchemaType>({
    resolver: zodResolver(addProductModalSchema),
    defaultValues: posAddProductModalDefaultValue,
  });

  const posItemDetail = data?.data;
  const formValues = form.getValues();
  const posFormValues = posForm.getValues();

  const warehousesValues = formValues?.warehouses ?? [];

  const stockLessThanFour =
    (posItemDetail?.stocks?.length ?? 0) > 0 &&
    (posItemDetail?.stocks?.length ?? 0) <= 3;

  const isAllowForRent = posItemDetail?.is_purchase && posItemDetail?.is_rent;
  const isRentOnly = !posItemDetail?.is_purchase && posItemDetail?.is_rent;
  const isPackage = selectedPOSItem.type === "package";

  const itemPrice = isRentOnly
    ? posItemDetail?.rent_price
    : posItemDetail?.sell_price;

  const {
    totalPackageProductsPrice,
    packageRemainingPrice,
    packageTotalExcessPrice,
  } = getPOSPackagePricing({
    products: formValues.products ?? [],
    price: formValues.price,
    discount: formValues.discount,
    quantity: parseInt(formValues.quantity),
  });

  let isAgreeForOverBudget = false;
  const handleCloseOverBudgetAlert = () => setIsShowOverBudgetAlert(false);
  const handleAgreeOverBudgetAlert = () => {
    isAgreeForOverBudget = true;

    setTimeout(() => {
      setIsShowOverBudgetAlert(false);
      handleAddProduct();
    }, 100);
  };

  let totalPrice = 0;

  if (selectedPOSItem.type === "product") {
    totalPrice = Math.max(
      parseInt(formValues.price, 0) *
        warehousesValues
          .map((warehouse) => parseInt(`${warehouse.quantity}`, 0))
          .reduce((a, b) => a + b, 0) -
        parseInt(`${formValues.discount}`, 0),
      0
    );
  } else if (selectedPOSItem.type === "package") {
    totalPrice = Math.max(
      parseInt(formValues.price) * parseInt(`${formValues.quantity}`, 0),
      0
    );
  }

  const disabledAddFromOtherWarehouse = warehousesValues
    .map((warehouse) => `${warehouse.id}`)
    .includes("0");

  const handleAddProduct = form.handleSubmit(async () => {
    const totalQuantity = warehousesValues
      .map((warehouse) => parseInt(`${warehouse.quantity}`, 0))
      .reduce((a, b) => a + b, 0);

    if (
      selectedPOSItem.type === "product"
        ? totalQuantity === 0
        : parseInt(`${formValues.quantity}`, 0) === 0 ||
          formValues.products.length === 0
    ) {
      ErrorToast({ message: "Quantity cannot be 0" });
    } else if (
      selectedPOSItem.type === "package" &&
      packageTotalExcessPrice > 0 &&
      !isAgreeForOverBudget
    ) {
      setIsShowOverBudgetAlert(true);
    } else {
      try {
        const { data } = await addToCart({
          posFormValues,
          formValues: {
            ...formValues,
            warehouses: formValues.warehouses.filter(
              (warehouse) => parseInt(`${warehouse.quantity}`, 0) > 0
            ),
          },
          id: selectedPOSItem.id,
          type: selectedPOSItem.type,
          edit: isEditOrder ? 1 : 0,
          transactionId,
        });

        if (data.all_available) {
          let newPOSFormOrders = [...posFormValues.orders];

          const filteredWarehouses = warehousesValues.filter(
            (warehouse) => parseInt(`${warehouse.quantity}`, 0) > 0
          );

          if (selectedPOSItem.type === "product") {
            for (const warehouse of filteredWarehouses) {
              const existingDataIndex = selectedPosOrderItemIndex ?? -1;
              const isExist = existingDataIndex !== -1;

              const newData = {
                id: selectedPOSItem.id,
                type: selectedPOSItem.type,
                note: selectedPosOrderItem?.note ?? "",
                ...formValues,
                quantity: warehouse.quantity,
                warehouses: undefined,
                warehouse,
              } as PosCartSchemaType["orders"][number];

              if (
                isExist &&
                newPOSFormOrders[existingDataIndex].warehouse?.id ===
                  newData.warehouse?.id
              ) {
                newPOSFormOrders[existingDataIndex] = newData;
              } else {
                const sameProductInChartIndex = posFormValues.orders.findIndex(
                  (order) => {
                    const keys = [
                      "id",
                      "businessOperationId",
                      "markAsRent",
                      "price",
                      "discount",
                      "warehouse",
                    ] as (keyof PosCartSchemaType["orders"][number])[];

                    const isAllSame = keys
                      .map((key) =>
                        key === "warehouse"
                          ? newData["warehouse"]["id"] ===
                            order["warehouse"]["id"]
                          : newData[key] === order[key]
                      )
                      .every((value) => value === true);

                    return isAllSame;
                  },
                  -1
                );
                const hasSameProductInCart = sameProductInChartIndex !== -1;

                if (hasSameProductInCart) {
                  newPOSFormOrders[sameProductInChartIndex] = {
                    ...newPOSFormOrders[sameProductInChartIndex],
                    warehouse: {
                      ...newPOSFormOrders[sameProductInChartIndex].warehouse,
                      quantity: `${
                        parseInt(
                          newPOSFormOrders[sameProductInChartIndex].warehouse
                            .quantity
                        ) + parseInt(newData.warehouse.quantity)
                      }`,
                    },
                  };
                } else {
                  newPOSFormOrders.push(newData);
                }
              }

              setPOSCartItems((prevState) => {
                let newArr = [...prevState];

                const existingDataIndex = prevState.findIndex(
                  (posCartItem) =>
                    posCartItem.id === selectedPOSItem.id &&
                    posCartItem.type === selectedPOSItem.type,
                  -1
                );
                const isExist = existingDataIndex !== -1;

                if (posItemDetail) {
                  const newData = {
                    id: posItemDetail.id,
                    name: posItemDetail.name,
                    type: posItemDetail.type,
                    sub_category: {
                      hex: posItemDetail.sub_category.hex,
                    },
                    image_url: posItemDetail.image_url,
                    product: posItemDetail.product,
                    package: posItemDetail.package,
                    stocks:
                      posItemDetail.stocks?.map((stock) => ({
                        warehouse: {
                          id: stock.warehouse.id,
                          name: stock.warehouse.name,
                        },
                      })) ?? [],
                    available: posItemDetail.available,
                    is_rent: posItemDetail.is_rent,
                    is_purchase: posItemDetail.is_purchase,
                    item_stock_unit: {
                      name: posItemDetail.item_stock_unit.name,
                    },
                    products: [],
                  };

                  if (!isExist) {
                    newArr.push(newData);
                  } else {
                    newArr[existingDataIndex] = newData;
                  }
                }

                return newArr;
              });
            }
          } else if (selectedPOSItem.type === "package") {
            const existingDataIndex = selectedPosOrderItemIndex ?? -1;
            const isExist = existingDataIndex !== -1;

            const newData = {
              id: selectedPOSItem.id,
              type: selectedPOSItem.type,
              note: selectedPosOrderItem?.note ?? "",
              ...formValues,
              warehouses: undefined,
              warehouse: {
                id: 0,
                name: "",
                quantity: formValues.quantity,
              },
            } as PosCartSchemaType["orders"][number];

            if (isExist) {
              newPOSFormOrders[existingDataIndex] = newData;
            } else {
              const samePackageInChartIndex = posFormValues.orders.findIndex(
                (order) => {
                  const keys = [
                    "id",
                    "businessOperationId",
                    "price",
                    "discount",
                  ] as (keyof PosCartSchemaType["orders"][number])[];

                  let isAllSame = false;

                  const isAllPackageDataSame = keys
                    .map((key) => newData[key] === order[key])
                    .every((value) => value === true);

                  if (isAllPackageDataSame) {
                    if (newData.products.length === order.products.length) {
                      const productKeys = [
                        "id",
                        "quantity",
                        "warehouseId",
                        "price",
                        "discount",
                        "markAsRent",
                      ] as (keyof PosCartSchemaType["orders"][number]["products"][number])[];

                      const isAllProductSame = newData.products
                        .map((product, index) => {
                          const isAllProductDataSame = productKeys
                            .map(
                              (key) =>
                                product[key] === order.products[index][key]
                            )
                            .every((value) => value === true);

                          return isAllProductDataSame;
                        })
                        .every((value) => value === true);

                      if (isAllProductSame) {
                        isAllSame = true;
                      }
                    }
                  }

                  return isAllSame;
                },
                -1
              );
              const hasSameProductInCart = samePackageInChartIndex !== -1;

              if (hasSameProductInCart) {
                newPOSFormOrders[samePackageInChartIndex] = {
                  ...newPOSFormOrders[samePackageInChartIndex],
                  quantity: `${
                    parseInt(
                      newPOSFormOrders[samePackageInChartIndex].quantity
                    ) + parseInt(newData.quantity)
                  }`,
                };
              } else {
                newPOSFormOrders.push(newData);
              }
            }

            setPOSCartItems((prevState) => {
              let newArr = [...prevState];

              const existingDataIndex = prevState.findIndex(
                (posCartItem) =>
                  posCartItem.id === selectedPOSItem.id &&
                  posCartItem.type === selectedPOSItem.type,
                -1
              );
              const isExist = existingDataIndex !== -1;

              if (posItemDetail) {
                const newData = {
                  id: posItemDetail.id,
                  name: posItemDetail.name,
                  type: posItemDetail.type,
                  sub_category: {
                    hex: posItemDetail.sub_category.hex,
                  },
                  image_url: posItemDetail.image_url,
                  product: posItemDetail.product,
                  package: posItemDetail.package,
                  stocks:
                    posItemDetail.stocks?.map((stock) => ({
                      warehouse: {
                        id: stock.warehouse.id,
                        name: stock.warehouse.name,
                      },
                    })) ?? [],
                  available: posItemDetail.available,
                  is_rent: posItemDetail.is_rent,
                  is_purchase: posItemDetail.is_purchase,
                  item_stock_unit: {
                    name: posItemDetail.item_stock_unit.name,
                  },
                  products: formValues.products.map((product) => {
                    const selectedPosDetailProduct =
                      posItemDetail?.product_variants?.find(
                        (p) => `${p.id}` === `${product.id}`
                      );

                    return {
                      id: parseInt(`${product.id}`, 0),
                      warehouseName:
                        selectedPosDetailProduct?.stocks?.find(
                          (stock) =>
                            `${stock.warehouse_id}` === `${product.warehouseId}`
                        )?.warehouse?.name ?? "",
                    };
                  }),
                };

                if (!isExist) {
                  newArr.push(newData);
                } else {
                  newArr[existingDataIndex] = newData;
                }
              }

              return newArr;
            });
          }

          posForm.setValue("hasRent", data.has_rent, {
            shouldValidate: true,
          });
          posForm.setValue("orders", newPOSFormOrders, {
            shouldValidate: true,
          });

          handleCloseModal();
        } else {
          apiErrorHandler({ error: "Out of Stocks!" });
        }
      } catch (error) {
        apiErrorHandler({ error });
      }
    }
  });

  const handleAddFromOtherWarehouse = () => {
    let newArr = [...warehousesValues];

    newArr.push({
      id: 0,
      quantity: "0",
    });

    form.setValue("warehouses", newArr, { shouldValidate: true });
  };

  const handleDeleteWarehouse = (index: number) => {
    let newWarehouses = [...form.getValues().warehouses];

    delete newWarehouses[index];

    newWarehouses = newWarehouses.filter(Boolean);

    form.setValue("warehouses", newWarehouses, {
      shouldValidate: true,
    });
  };

  const handleCloseModal = () => {
    onClose();
    form.reset(posAddProductModalDefaultValue);
  };

  useEffect(() => {
    if (posItemDetail) {
      if (stockLessThanFour) {
        form.reset({
          ...posAddProductModalDefaultValue,
          price: `${itemPrice}`,
          ...(selectedPosOrderItem ? selectedPosOrderItem : {}),
          warehouses:
            posItemDetail.stocks?.map((stock) => {
              const previousValue =
                stock.warehouseId === selectedPosOrderItem?.warehouse?.id;

              return {
                id: stock.warehouseId,
                quantity: previousValue
                  ? selectedPosOrderItem.warehouse.quantity
                  : "0",
              };
            }) ?? [],
          products: [],
        });
      } else {
        form.reset({
          ...posAddProductModalDefaultValue,

          ...(selectedPosOrderItem
            ? {
                ...selectedPosOrderItem,
                warehouses:
                  selectedPosOrderItem.warehouses ??
                  ([{ id: 0, quantity: "0" }] as any),
              }
            : {
                price: `${itemPrice}`,
                products: posItemDetail.product_variants?.map((product) => {
                  const onlyHasOneWarehouse = product.stocks.length === 1;

                  return {
                    id: `${product.id}`,
                    warehouseId: onlyHasOneWarehouse
                      ? `${product.stocks[0].warehouse_id}`
                      : undefined,
                    warehouseName: onlyHasOneWarehouse
                      ? `${product.stocks[0].warehouse.name}`
                      : undefined,
                    quantity: onlyHasOneWarehouse
                      ? `${
                          product.stocks[0].available >= product.quantity
                            ? product.quantity
                            : product.stocks[0].available
                        }`
                      : `${product.quantity}`,
                    price: `${
                      product.is_purchase
                        ? product.sell_price
                        : product.rent_price
                    }`,
                    discount: "0",
                    markAsRent: false,
                  };
                }),
                warehouses: [{ id: 0, quantity: "0" }],
              }),
        });

        if ((posItemDetail.product_variants ?? [])?.length > 0) {
          for (const product of posItemDetail.product_variants ?? []) {
            setSelectedPackageProducts((prevState) => {
              let newArr = [...prevState];

              const packageVariantId = parseInt(`${selectedPOSItem.id}`);

              const isExist = prevState.find(
                (selectedPackageProduct) =>
                  selectedPackageProduct.id === product.id &&
                  selectedPackageProduct.packageVariantId === packageVariantId
              );

              if (!isExist) {
                newArr.push({
                  id: product.id,
                  packageVariantId,
                  productName: product.product.name,
                  variantName: product.name,
                  itemStockUnit: product.product?.item_stock_unit?.name,
                  stocks: product.stocks,
                  quantity: product.quantity,
                });
              }

              return newArr;
            });
          }
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [posItemDetail, selectedPosOrderItem, stockLessThanFour, itemPrice, form]);

  const warehouseOptions =
    posItemDetail?.stocks?.map((stock) => ({
      label: stock.warehouse.name,
      value: `${stock.warehouse?.id}`,
    })) ?? [];

  const filteredWarehouseOptions = warehouseOptions.filter(
    (warehouseOption) =>
      !warehousesValues
        .map((warehouse) => `${warehouse.id}`)
        .includes(`${warehouseOption.value}`)
  );

  const handleAddMoreItem = () => {
    let newArr = [...formValues.products];

    newArr.push({
      id: undefined as any,
      warehouseId: "0",
      warehouseName: "",
      quantity: "0",
      price: "0",
      discount: "0",
      markAsRent: false,
    });

    form.setValue("products", newArr, { shouldValidate: true });
  };

  const handleMarkAsRentCallBack = (checked: boolean) => {
    if (checked) {
      const rentPrice = posItemDetail?.rent_price ?? 0;

      form.setValue("price", `${rentPrice}`, { shouldValidate: true });
    } else {
      const sellPrice = posItemDetail?.sell_price ?? 0;

      form.setValue("price", `${sellPrice}`, { shouldValidate: true });
    }
  };

  return {
    isShowOverBudgetAlert,
    isAllowToEditPrice,
    warehouseOptions,
    filteredWarehouseOptions,
    posItemDetail,
    isLoading,
    form,
    formValues,
    isAllowForRent,
    isPackage,
    stockLessThanFour,
    disabledAddFromOtherWarehouse,
    totalPrice,
    totalPackageProductsPrice,
    packageRemainingPrice,
    packageTotalExcessPrice,
    handleCloseOverBudgetAlert,
    handleAgreeOverBudgetAlert,
    handleAddProduct,
    handleAddFromOtherWarehouse,
    handleDeleteWarehouse,
    handleCloseModal,
    handleAddMoreItem,
    handleMarkAsRentCallBack,
  };
};

export default useAddProductModalHandler;
