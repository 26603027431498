// import { useMutation } from "@tanstack/react-query";
// import { signIn } from "src/services/Auth.service";
// import { SignInFormType } from "../hooks/useLoginHandler";
// import { SignInResponse } from "./interfaces";

import { useMutation } from "@tanstack/react-query";
import queryClient from "src/config/reactQuery.config";
import {
  changeMyPassword,
  changeOtherPassword,
  createAdminList,
  deleteAdminList,
  editAdminList,
  toggleActiveStatusAdminList,
} from "src/services/AdminList.service";
import {
  ChangeMyPasswordRequestBody,
  ChangeMyPasswordResponse,
  ChangeOtherPasswordRequestBody,
  ChangeOtherPasswordResponse,
  CreateAdminListFormType,
  CreateAdminListResponse,
  DeleteAdminListResponse,
  DeleteAdminRequestBody,
  EditAdminListFormType,
  EditAdminListResponse,
  ToggleActiveStatusAdminListResponse,
  ToggleActiveStatusAdminRequestBody,
} from "./interfaces";

export const useCreateAdminList = () => {
  return useMutation<CreateAdminListResponse, Error, CreateAdminListFormType>({
    mutationFn: (value) => createAdminList(value),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["admin-list"] });
    },
  });
};

export const useEditAdminList = () => {
  return useMutation<EditAdminListResponse, Error, EditAdminListFormType>({
    mutationFn: (value) => editAdminList(value),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["admin-list"] });
    },
  });
};

export const useDeleteAdminList = () => {
  return useMutation<DeleteAdminListResponse, Error, DeleteAdminRequestBody>({
    mutationFn: (value) => deleteAdminList(value),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["admin-list"] });
    },
  });
};

export const useToggleActiveStatusAdminList = () => {
  return useMutation<
    ToggleActiveStatusAdminListResponse,
    Error,
    ToggleActiveStatusAdminRequestBody
  >({
    mutationFn: (value) => toggleActiveStatusAdminList(value),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["admin-list"] });
    },
  });
};

export const useChangeOtherPassword = () => {
  return useMutation<
    ChangeOtherPasswordResponse,
    Error,
    ChangeOtherPasswordRequestBody
  >({
    mutationFn: (value) => changeOtherPassword(value),
  });
};

export const useChangeMyPassword = () => {
  return useMutation<
    ChangeMyPasswordResponse,
    Error,
    ChangeMyPasswordRequestBody
  >({
    mutationFn: (value) => changeMyPassword(value),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["me"] });
    },
  });
};
