import { isCountryCodeOnly } from "src/validators";
import { getCountryCodes } from "./getCountries";

export const formatPhoneNumber = (phoneNumber?: string | null): string => {
  if (!phoneNumber) return "";

  const countryCodes = getCountryCodes();

  const countryCode =
    countryCodes.find((countryCode) => phoneNumber.startsWith(countryCode.code))
      ?.code ?? "+62";

  const phoneNumberWithoutCountryCode = phoneNumber?.split(countryCode)[1];

  return `(${countryCode}) ${formatInputPhoneNumber(
    phoneNumberWithoutCountryCode
  )}`;
};

export const formatPhoneNumberForInputValue = (
  phoneNumber?: string | null
): string => {
  if (!phoneNumber) return "";

  const countryCodes = getCountryCodes();

  const countryCode =
    countryCodes.find((countryCode) => phoneNumber.startsWith(countryCode.code))
      ?.code ?? "+62";

  const phoneNumberWithoutCountryCode =
    phoneNumber?.split(countryCode)[1] ?? "";

  return `${countryCode}${formatInputPhoneNumber(
    phoneNumberWithoutCountryCode
  )}`;
};

export const formatInputPhoneNumber = (input?: string | null): string => {
  if (!input) return "";

  const cleaned = input.replace(/[^+\d]/g, "");

  if (cleaned.length === 0) return "";
  else if (cleaned.length <= 3) return cleaned;
  else if (cleaned.length <= 6)
    return cleaned.replace(/(\d{3})(\d{0,3})/, "$1-$2");
  else if (cleaned.length <= 8)
    return cleaned.replace(/(\d{3})(\d{3})(\d{0,2})/, "$1-$2-$3");
  else if (cleaned.length <= 9) {
    // For 9 digits: xxx-xxx-xxx
    return cleaned.replace(/(\d{3})(\d{3})(\d{0,3})/, "$1-$2-$3");
  } else if (cleaned.length <= 10) {
    // For 10 digits: xxx-xxxx-xxx
    return cleaned.replace(/(\d{3})(\d{4})(\d{0,3})/, "$1-$2-$3");
  } else if (cleaned.length <= 11) {
    // For 11 digits: xxx-xxxx-xxxx
    return cleaned.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
  } else if (cleaned.length <= 12) {
    // For 12 digits: xxx-xxxxx-xxxx
    return cleaned.replace(/(\d{3})(\d{5})(\d{4})/, "$1-$2-$3");
  } else {
    // For more than 12 digits: -xxxx-xxxx-xxxx-xxxx-...
    let formatted = cleaned
      .slice(0, 12)
      .replace(/(\d{3})(\d{5})(\d{4})/, "$1-$2-$3");
    let remaining = cleaned.slice(12);

    while (remaining.length > 0) {
      formatted += "-" + remaining.slice(0, 4);
      remaining = remaining.slice(4);
    }

    return formatted;
  }
};

export const formatInputPhoneNumberValue = (input: string = ""): string => {
  const cleaned = input.replace(/\D/g, "");

  if (cleaned === "" || isCountryCodeOnly(`+${cleaned}`)) return "";

  return `+${cleaned}`;
};
