interface GetDiscountProps {
  discount: number;
  discountType: "percentage" | "amount";
}

export const capitalize = (value?: string, splitSnakeCase?: boolean) => {
  if (!value) return "";

  let convertedValue = value;

  if (splitSnakeCase) {
    convertedValue = convertedValue.split("_").join(" ");
  }

  return convertedValue
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};

export const numberWithCommas = (value?: number | string | null) => {
  if (value === undefined || value === null) return "0";

  return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const getCurrency = (value?: number | string) => {
  if (value === undefined) return "";

  return `Rp ${numberWithCommas(value).replace(/,/g, ".")}`;
};

export const getDiscount = ({ discount, discountType }: GetDiscountProps) => {
  let promotionDiscount = "";

  if (discountType === "amount") {
    promotionDiscount = getCurrency(discount);
  } else {
    promotionDiscount = `${discount}%`;
  }

  return promotionDiscount;
};
