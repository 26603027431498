import { Loader2 } from "lucide-react";
import React from "react";

interface SpinnerProps {
  className?: string;
  size?: "small" | "medium" | "large";
}

const Spinner: React.FC<SpinnerProps> = ({ className, size = "small" }) => {
  let spinnerSize = "";

  switch (size) {
    case "small":
      spinnerSize = "h-4 w-4";
      break;
    case "medium":
      spinnerSize = "h-6 w-6";
      break;
    case "large":
      spinnerSize = "h-8 w-8";
      break;

    default:
      break;
  }

  return <Loader2 className={`${spinnerSize} animate-spin ${className}`} />;
};

export default Spinner;
