import React from "react";
import { LuArrowUpDown } from "react-icons/lu";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";

interface SortDropDownProps {
  sort: string;
  setSort: React.Dispatch<React.SetStateAction<string>>;
  options: {
    label: string;
    value: string;
  }[];
  iconOnly?: boolean;
}

const SortDropDown: React.FC<SortDropDownProps> = ({
  sort,
  setSort,
  options,
  iconOnly,
}) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <div className="flex items-center border border-darkBrown-70 bg-lightBrown-default text-darkBrown-70 rounded-lg p-3.5 select-none">
          {!iconOnly && <span className="font-normal me-2">Sort</span>}

          <LuArrowUpDown size={22} />
        </div>
      </DropdownMenuTrigger>

      <DropdownMenuContent>
        <DropdownMenuRadioGroup value={sort} onValueChange={setSort}>
          {options.map((option, index) => {
            const isSelected = option.value === sort;

            return (
              <DropdownMenuRadioItem
                className={`${isSelected ? "bg-lightBrown-default" : ""}`}
                value={option.value}
                key={index}
              >
                {option.label}
              </DropdownMenuRadioItem>
            );
          })}
        </DropdownMenuRadioGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default SortDropDown;
