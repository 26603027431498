export enum ActionType {
  Allow = "allow",
  Create = "create",
  Read = "read",
  Update = "update",
  Delete = "delete",
}

export enum AccessResource {
  "Overview" = "overview",
  // "Notification - New Order Created" = "notification-new_order_created",
  // "Notification - Waiting for Confirmation" = "notification-waiting_for_confirmation",
  // "Notification - Need to Return Item" = "notification-need_to_return_item",
  // "Notification - Order out of Delivery" = "notification-order_out_of_delivery",
  // "Notification - Order has Pickup" = "notification-order_has_pickup",
  // "Notification - Product Low on Stock" = "notification-product_low_on_stock",
  // "Notification - Product New Stock Updated" = "notification-product_new_stock_updated",
  "POS" = "pos",
  "POS | Create Back Date Order" = "pos-create_back_date_order",
  "POS | Edit Product Price" = "pos-edit_product_price",
  "Report | Overview Report" = "report-overview_report",
  "Report | Sales Report" = "report-sales_report",
  "Report | Product Usage Report" = "report-product_usage_report",
  "Report | Expenses & Income Report" = "report-expense_income_report",
  "Report | PnL Report" = "report-pnl_report",
  "Promo Code" = "promocode",
  "Promo Code | Create Back Date Order" = "",
  "Promo Code | Edit Product Price" = "",
  "Promo Code | View Partner / Vendor Phone Number" = "",
  "Order Management - Order List" = "order_management-order_list",
  "Order Management - Order List | Switch Order Type to Pickup or Delivery" = "order_list-switch_order_method",
  "Order Management - Order List | Set Fixed Date for Estimated Order" = "order_list-set_fixed_date",
  "Order Management - Customer Transaction List" = "order_management-customer_transaction_list",
  "Order Management - Customer Transaction List | Generate Single Receipt" = "customer_transaction-generate_single_receipt",
  "Order Management - Customer Transaction List | Generate Single Invoice" = "customer_transaction-generate_single_invoice",
  "Order Management - Customer Transaction List | Generate Multiple Invoice" = "customer_transaction-generate_multiple_invoice",
  "Order Management - Customer Transaction List | Add Transfer Proof" = "customer_transaction-add_transfer_proof",
  "Order Management - Customer Transaction List | Confirm Payment" = "customer_transaction-adjust_confirmed_payment",
  "Order Management - Customer Transaction List | Add Finance Notes" = "customer_transaction-add_finance_note",
  "Order Management - Customer Transaction List | Cancel Order" = "customer_transaction-cancel_order",
  "Order Management - Customer Transaction List | Deposit - To pay remaining payment & generate invoice remaining payment" = "customer_transaction-deposit",
  "Order Management - Customer Transaction List | Mark Order as Sub Out" = "customer_transaction-mark_order_sub_out",
  "Order Management - Customer Transaction List | Edit Transaction" = "customer_transaction-edit_transaction",
  "Order Management - Sub Out Transaction List" = "order_management-sub_out_transaction",
  "Order Management - Return Item List" = "order_management-return_item_list",
  "User Management - Customer List" = "user_management-customer_list",
  "User Management - Partner or Vendor List" = "user_management-partner_or_vendor_list",
  "User Management - Supplier List" = "user_management-supplier_list",
  "Product Management - Stock Purchase" = "product_management-stock_purchase",
  "Product Management - Inventory" = "product_management-inventory",
  "Product Management - Inventory | Adjust Stock Inventory" = "product_management-adjust_stock_inventory",
  "Product Management - Product List" = "product_management-product_list",
  "Product Management - Product Package" = "product_management-product_package",
  "Product Management - Warehouse" = "product_management-warehouse",
  "Product Management - Warehouse | Adjust Stock Warehouse" = "product_management-adjust_stock_warehouse",
  "Admin Management - Admin List" = "admin_management-admin_list",
  "Admin Management - Admin Action Log" = "admin_management-admin_action_log",
  "Admin Management - Admin Role" = "admin_management-admin_role",
  "Master Data - Additional Fee" = "master_data-additional_fee",
  "Master Data - Business Operation" = "master_data-business_operation",
  "Master Data - Customer Type" = "master_data-customer_type",
  "Master Data - Item Stock Unit" = "master_data-item_stock_unit",
  "Master Data - Package Category" = "master_data-package_category",
  "Master Data - Product Category" = "master_data-product_category",
  "Master Data - Supplier List" = "master_data-supplier",
  "Master Data - Vendor or Partner List" = "master_data-partner",
  "Master Data - Warehouse Data" = "master_data-warehouse",
  "Master Data - Expense and Income Category" = "master_data-expense_and_income",
  "Other Access - Allow to View Partner / Vendor Phone Number" = "other_access-view_partner_phone_number",
  "Other Access - Allow to View Supplier Phone Number" = "other_access-view_supplier_phone_number",
}
