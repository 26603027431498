import { Command as CommandPrimitive } from "cmdk";
import {
  AddCircle,
  Clock,
  Eye,
  EyeSlash,
  GalleryEdit,
  MinusCirlce,
} from "iconsax-react";
import { Check, ChevronDown } from "lucide-react";
import React, { useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { CgColorPicker } from "react-icons/cg";
import { useDebounce } from "src/hooks/useDebounce";
import { cn } from "src/lib/utils";
import { uploadFile } from "src/services/UploadFile.service";
import { apiErrorHandler } from "src/utils/apiErrorHandler";
import { numberWithCommas } from "src/utils/commons";
import {
  convertCurrencyToString,
  convertToCurrency,
} from "src/utils/convertToCurrency";
import { formatInputPhoneNumber } from "src/utils/formatPhoneNumber";
import { getCountryCodes } from "src/utils/getCountries";
import { camelToSnake } from "src/utils/snackToCamel";
import DateRangePicker from "../SearchInput/DateRangePicker";
import Spinner from "../Spinner";
import { Button } from "../ui/button";
import { ColorPicker } from "../ui/color-picker";
import {
  Command,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "../ui/command";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../ui/form";
import { Input, InputProps } from "../ui/input";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { Textarea } from "../ui/textarea";
import useCustomInputHandler from "./hooks/useCustomInputHandler";
import { MultiSelect } from "../ui/multi-select";

interface CustomInputProps extends InputProps {
  form: any;
  name: string;
  labelText?: string;
  description?: string;
  textArea?: boolean;
  maxCharacters?: number;
  variant?:
    | "input"
    | "select"
    | "color-picker"
    | "date"
    | "date-time"
    | "image"
    | "currency"
    | "phone-number"
    | "quantity-changer"
    | "time";
  dateType?: "SINGLE" | "RANGE";
  options?: { label: string; value: string | number }[];
  labelClassName?: string;
  isLoading?: boolean;
  imageDomain?: string;
  searchValue?: string;
  onSearch?: (search: string) => void;
  details?: string[];
  minValue?: number;
  maxValue?: number;
  quantityChangerSize?: "small" | "default";
  minDate?: Date | null;
  maxDate?: Date | null;
  isMultipleSelection?: boolean;
}

const colorTemplates = [
  "#A1C0AC",
  "#DFC7D7",
  "#F7E8F2",
  "#CAC9AF",
  "#F7F6DE",
  "#B67162",
  "#FFE7E2",
  "#FFA1A1",
];

const CustomInput: React.FC<CustomInputProps> = (props) => {
  const {
    form,
    name,
    labelText = "",
    placeholder,
    textArea,
    maxCharacters,
    type,
    variant,
    dateType = "SINGLE",
    options = [],
    labelClassName,
    isLoading,
    imageDomain = "",
    searchValue,
    onSearch,
    details = [],
    minValue = 0,
    maxValue,
    quantityChangerSize,
    isMultipleSelection,
  } = props;

  const { showPassword, togglePasswordVisibility } = useCustomInputHandler();

  const {
    control,
    setValue,
    formState: { errors },
    getValues,
  } = form;

  const values = getValues();

  const [isOpen, setIsOpen] = React.useState(false);
  const [preview, setPreview] = React.useState<string | ArrayBuffer | null>(
    (typeof props?.defaultValue === "string" ? props.defaultValue : "") ?? ""
  );
  const [selectedTime, setSelectedTime] = React.useState({
    hour: "00",
    minute: "00",
  });

  const countryCodes = variant === "phone-number" ? getCountryCodes() : [];

  const hours = Array.from({ length: 24 }, (_, i) =>
    String(i).padStart(2, "0")
  );
  const minutes = Array.from({ length: 60 }, (_, i) =>
    String(i).padStart(2, "0")
  );

  const onDrop = React.useCallback(
    async (acceptedFiles: File[]) => {
      const reader = new FileReader();

      try {
        const data = await uploadFile({
          image: acceptedFiles[0],
          domain: imageDomain,
          type: "image",
          field: name,
        });

        reader.onload = () => setPreview(reader.result);
        reader.readAsDataURL(acceptedFiles[0]);
        form.setValue(name, data.key);
        form.clearErrors(name);
      } catch (error) {
        apiErrorHandler({ error, form });
        setPreview(null);
      }
    },
    [form, name, imageDomain]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  const names = name?.split(".");

  let isError: any = errors;
  let value: any = values;

  if (names.length > 1) {
    for (const key of names) {
      if (isError && typeof isError === "object") {
        isError = isError[camelToSnake(key)];
      } else {
        isError = errors[camelToSnake(name)];
      }

      if (value && typeof value === "object" && key in value) {
        value = value[key];
      } else {
        value = value[key];
        break;
      }
    }
  } else {
    value = values[name];
    isError = errors[camelToSnake(name)];
  }

  useEffect(() => {
    // Init Preview
    if (variant === "image" && !preview) {
      setPreview(typeof value === "string" ? value : "");
    }
  }, [variant, value, preview]);

  useEffect(() => {
    // Init Time
    if (
      variant === "time" &&
      selectedTime.hour === "00" &&
      selectedTime.minute === "00"
    ) {
      if (typeof value === "string") {
        setSelectedTime({
          hour: value.split(":")[0],
          minute: value.split(":")[1],
        });
      }
    }
  }, [variant, selectedTime.hour, selectedTime.minute, value]);

  const formItemClass = `flex-col w-full ${!labelText ? "!space-y-0" : ""}`;

  const selectedSelectionOption = options?.find(
    (option) => `${option.value}` === `${value}`
  );

  const selectedOption = useDebounce({
    value: `${selectedSelectionOption?.label ?? placeholder}`,
    delay: 250,
  });

  if (variant === "select") {
    return (
      <FormField
        control={control}
        name={name}
        render={({ field }) => {
          const handleSelect = (value: string) => {
            if (options) {
              setValue(
                name,
                typeof options[0]?.value === "number"
                  ? parseInt(value, 10)
                  : value,
                { shouldValidate: true }
              );
              setIsOpen(false);
            }
            if (props.onChange) {
              props.onChange(value as any);
            }
          };

          return (
            <FormItem className={formItemClass}>
              <FormLabel className={labelClassName}>{labelText}</FormLabel>

              <FormControl>
                {isMultipleSelection ? (
                  <MultiSelect
                    defaultValue={field.value}
                    selectedOptions={options
                      .map((option) => ({
                        label: option.label,
                        placeholder: option.label,
                        value: `${option.value}`,
                      }))
                      .filter((option) =>
                        field.value.includes(`${option.value}`)
                      )}
                    onValueChange={(value) => {
                      form.setValue(field.name, value);
                    }}
                    options={options.map((option) => ({
                      label: option.label,
                      placeholder: option.label,
                      value: `${option.value}`,
                    }))}
                    placeholder={`Select ${labelText}`}
                    normalHeight
                  />
                ) : (
                  <Popover open={isOpen} onOpenChange={setIsOpen} modal>
                    <PopoverTrigger asChild>
                      <FormControl>
                        <Button
                          variant="link"
                          className={cn(
                            "w-full justify-between bg-white text-black pointer !no-underline border border-input rounded-md text-sm font-normal p-2.5 cursor-pointer overflow-hidden relative",
                            !value && "text-muted-foreground"
                          )}
                          disabled={props.disabled}
                          onClick={() =>
                            isLoading ? undefined : setIsOpen(!isOpen)
                          }
                        >
                          {selectedOption}

                          <div className="bg-white p-2.5 absolute right-0">
                            <ChevronDown className="h-4 w-4 shrink-0 opacity-50" />
                          </div>
                        </Button>
                      </FormControl>
                    </PopoverTrigger>

                    <PopoverContent className="w-[--radix-popover-trigger-width] max-h-[--radix-popover-content-available-height] p-0">
                      <Command className="w-full">
                        <CommandInput
                          placeholder={`Search ${labelText}`}
                          value={searchValue}
                          onValueChange={isLoading ? undefined : onSearch}
                        />

                        <CommandList>
                          {isLoading ? (
                            <CommandPrimitive.Loading>
                              <div className="flex items-center justify-center p-2">
                                <Spinner />
                              </div>
                            </CommandPrimitive.Loading>
                          ) : null}

                          {options.length > 0 && !isLoading ? (
                            <CommandGroup
                              {...(props as any)}
                              value={field.value ? `${field.value}` : undefined}
                              onValueChange={handleSelect}
                            >
                              {options.map((option) => (
                                <CommandItem
                                  value={`${option.label}`}
                                  key={option.value}
                                  onSelect={() => {
                                    handleSelect(`${option.value}`);
                                  }}
                                >
                                  <Check
                                    className={cn(
                                      "mr-2 h-4 w-4",
                                      option.value === value
                                        ? "opacity-100"
                                        : "opacity-0"
                                    )}
                                  />
                                  {option.label}
                                </CommandItem>
                              ))}
                            </CommandGroup>
                          ) : null}
                          {!isLoading ? (
                            <CommandPrimitive.Empty className="select-none rounded-sm px-2 py-3 text-center text-sm">
                              No Match Found
                            </CommandPrimitive.Empty>
                          ) : null}
                        </CommandList>
                      </Command>
                    </PopoverContent>
                  </Popover>
                )}
              </FormControl>

              <FormMessage>{isError?.message}</FormMessage>
            </FormItem>
          );
        }}
      />
    );
  } else if (variant === "color-picker") {
    return (
      <FormField
        control={control}
        name={name}
        render={({ field }) => {
          const handleSelectColorTemplate = (colorTemplate: string) => {
            setValue(name, colorTemplate, { shouldValidate: true });
          };

          const handleSelectColor = (value: string) => {
            setValue(name, value, { shouldValidate: true });
          };

          return (
            <FormItem className={formItemClass}>
              <FormLabel>{labelText}</FormLabel>

              <div className="flex">
                <div className="w-full grid grid-flow-col">
                  {colorTemplates.map((colorTemplate, index) => (
                    <div
                      className="w-10 h-10 rounded-full border cursor-pointer"
                      onClick={() => handleSelectColorTemplate(colorTemplate)}
                      style={{ backgroundColor: colorTemplate }}
                      key={index}
                    />
                  ))}
                </div>

                <FormControl>
                  <div className="flex items-center gap-2 border rounded-lg overflow-hidden pl-3 min-w-56">
                    <div className="flex flex-1 items-center gap-2">
                      <CgColorPicker size={24} />

                      <span className="">
                        {field.value ? field.value : "#FFFFFF"}
                      </span>
                    </div>

                    <ColorPicker
                      {...(props as any)}
                      value={field.value}
                      onChange={handleSelectColor}
                      className="w-24 rounded-l-none border-0"
                    />
                  </div>
                </FormControl>
              </div>

              <FormMessage>{form.formState.errors[name]?.message}</FormMessage>
            </FormItem>
          );
        }}
      />
    );
  } else if (variant === "date" || variant === "date-time") {
    return (
      <FormField
        control={control}
        name={name}
        render={({ field }) => {
          const handleSelectDate = (value: Date) => {
            setValue(name, value, { shouldValidate: true });
          };

          return (
            <FormItem className={formItemClass}>
              {labelText && (
                <FormLabel className={labelClassName}>{labelText}</FormLabel>
              )}

              <FormControl>
                <DateRangePicker
                  {...(props as any)}
                  date={value}
                  setDate={handleSelectDate}
                  placeholder={placeholder ?? "Select date"}
                  type={dateType}
                  className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 disabled:bg-darkIndicator-20"
                  isIncludeTime={variant === "date-time"}
                />
              </FormControl>

              <FormMessage>{isError?.message}</FormMessage>
            </FormItem>
          );
        }}
      />
    );
  } else if (variant === "image") {
    return (
      <FormField
        control={form.control}
        name={name}
        render={() => (
          <FormItem className={formItemClass}>
            <FormLabel className={`${labelClassName}`}>{labelText}</FormLabel>

            <FormControl
              className="flex min-h-[80px] w-full rounded-md"
              style={{
                backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23e5e7eb' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
              }}
            >
              <div
                {...getRootProps()}
                className="flex cursor-pointer flex-col items-center justify-center gap-2"
              >
                {preview && (
                  <img
                    src={preview as string}
                    alt="preview"
                    className="max-h-[80px] rounded-lg"
                  />
                )}
                <GalleryEdit
                  size={24}
                  className={`${
                    preview ? "hidden" : "block"
                  } text-darkIndicator-40`}
                />
                <Input {...getInputProps()} type="file" />

                {!preview && (
                  <span className="text-sm font-normal text-darkIndicator-30 text-center">
                    {placeholder}
                  </span>
                )}
              </div>
            </FormControl>

            <FormMessage>{isError?.message}</FormMessage>
          </FormItem>
        )}
      />
    );
  } else if (variant === "phone-number") {
    return (
      <FormField
        control={control}
        name={name}
        render={({ field }) => {
          const phoneNumberValue = field.value;

          const selectedOption = field.value
            ? countryCodes.find((countryCode) =>
                phoneNumberValue.startsWith(countryCode.code)
              )
            : null;

          const phoneNumberInputValue = phoneNumberValue?.split(
            selectedOption?.code
          )[1];

          const handleSelectCountryCode = (countryCode: string) => {
            return setValue(name, `${countryCode}`, { shouldValidate: true });
          };

          const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            let newValue = e.target.value;

            return setValue(
              name,
              `${selectedOption?.code}${formatInputPhoneNumber(newValue)}`,
              {
                shouldValidate: true,
              }
            );
          };

          return (
            <FormItem className={formItemClass}>
              {labelText && (
                <FormLabel className={labelClassName}>{labelText}</FormLabel>
              )}

              <FormControl>
                <div className="flex gap-4">
                  <Popover open={isOpen} onOpenChange={setIsOpen}>
                    <PopoverTrigger asChild>
                      <FormControl className="min-w-24 flex-1">
                        <span
                          className={cn(
                            "flex w-full justify-between bg-white border border-input rounded-md text-sm font-normal p-2.5 cursor-text",
                            !value && "text-muted-foreground"
                          )}
                          onClick={() =>
                            isLoading ? undefined : setIsOpen(!isOpen)
                          }
                        >
                          {value ? selectedOption?.code : "+62"}

                          <ChevronDown className="ml-1 h-4 w-4 shrink-0 opacity-50" />
                        </span>
                      </FormControl>
                    </PopoverTrigger>

                    <PopoverContent className=" max-h-[--radix-popover-content-available-height] p-0">
                      <Command className="w-full">
                        <CommandInput placeholder="Search" />

                        <CommandList>
                          {isLoading ? (
                            <CommandPrimitive.Loading>
                              <div className="flex items-center justify-center p-2">
                                <Spinner />
                              </div>
                            </CommandPrimitive.Loading>
                          ) : null}

                          <CommandGroup
                            {...(props as any)}
                            value={field.value ? `${field.value}` : undefined}
                          >
                            {countryCodes.map((countryCode) => (
                              <CommandItem
                                value={`(${countryCode.flag} ${countryCode.code}) ${countryCode.countryName}`}
                                key={countryCode.code}
                                onSelect={() => {
                                  handleSelectCountryCode(countryCode.code);
                                }}
                                className="pl-0"
                              >
                                <Check
                                  className={cn(
                                    "mr-2 h-4 w-4",
                                    countryCode.code === value
                                      ? "opacity-100"
                                      : "opacity-0"
                                  )}
                                />
                                {`(${countryCode.flag} ${countryCode.code}) ${countryCode.countryName}`}
                              </CommandItem>
                            ))}
                          </CommandGroup>

                          <CommandPrimitive.Empty className="select-none rounded-sm px-2 py-3 text-center text-sm">
                            No Match Found
                          </CommandPrimitive.Empty>
                        </CommandList>
                      </Command>
                    </PopoverContent>
                  </Popover>

                  <Input
                    {...field}
                    placeholder={placeholder}
                    value={phoneNumberInputValue}
                    maxLength={maxCharacters}
                    onChange={onChange}
                    {...props}
                  />
                </div>
              </FormControl>

              <FormMessage>{isError?.message}</FormMessage>
            </FormItem>
          );
        }}
      />
    );
  } else if (variant === "quantity-changer") {
    return (
      <FormField
        control={control}
        name={name}
        render={({ field }) => {
          const convertedValue =
            parseInt(value?.replace(/[^0-9]/g, ""), 10) || 0;

          const isAbleToDecrease = convertedValue > minValue;
          const isAbleToIncrease = convertedValue !== maxValue;

          const handleDecrease = () => {
            setValue(name, `${isAbleToDecrease ? convertedValue - 1 : 0}`, {
              shouldValidate: true,
            });
          };

          const handleIncrease = () => {
            setValue(
              name,
              `${isAbleToIncrease ? convertedValue + 1 : maxValue}`,
              {
                shouldValidate: true,
              }
            );
          };

          const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            let newValue = e.target.value;
            const value = e.target.value ?? "";
            newValue = value?.replace(/[^0-9.]/g, "");

            return setValue(name, newValue, { shouldValidate: true });
          };

          const iconSize = quantityChangerSize === "small" ? 20 : 28;
          const inputClassSize =
            quantityChangerSize === "small" ? "h-7 text-sm" : "text-lg";
          const inputSizeMoreThanTwo =
            quantityChangerSize === "small" ? 36 : 48;
          const inputSizeLessThanTwo =
            quantityChangerSize === "small" ? 28 : 40;
          const inputSizeMoreThanThree =
            quantityChangerSize === "small" ? 46 : 66;

          return (
            <FormItem className={formItemClass}>
              {labelText && (
                <FormLabel className={labelClassName}>{labelText}</FormLabel>
              )}

              <FormControl>
                <div className="flex items-center">
                  <Button
                    disabled={!isAbleToDecrease}
                    className={`px-2 outline-none ${
                      !isAbleToDecrease ? "text-darkIndicator-20" : ""
                    }`}
                    variant="link"
                    onClick={handleDecrease}
                  >
                    <MinusCirlce size={iconSize} />
                  </Button>

                  <Input
                    {...field}
                    value={numberWithCommas(field.value)}
                    maxLength={maxCharacters}
                    onChange={onChange}
                    min={0}
                    disabled={maxValue === 0}
                    {...props}
                    type="text"
                    className={`px-1 max-w-20 text-center ${inputClassSize}`}
                    style={{
                      width:
                        field?.value?.length <= 2
                          ? inputSizeLessThanTwo
                          : field?.value?.length > 3
                          ? inputSizeMoreThanThree
                          : inputSizeMoreThanTwo,
                    }}
                  />

                  <Button
                    disabled={!isAbleToIncrease}
                    className={`px-2 outline-none ${
                      !isAbleToIncrease ? "text-darkIndicator-20" : ""
                    }`}
                    variant="link"
                    onClick={handleIncrease}
                  >
                    <AddCircle size={iconSize} />
                  </Button>
                </div>
              </FormControl>

              <FormMessage>{isError?.message}</FormMessage>
            </FormItem>
          );
        }}
      />
    );
  } else if (variant === "time") {
    return (
      <FormField
        control={control}
        name={name}
        render={({ field }) => {
          const timeValue = `${selectedTime.hour}:${selectedTime.minute}`;

          const togglePicker = () => {
            if (isOpen) {
              setValue(name, timeValue, { shouldValidate: true });
            }
            setIsOpen(!isOpen);
          };
          const selectTime = (hour: any, minute: any) => {
            setSelectedTime({ hour, minute });
            setValue(name, `${hour}:${minute}`, { shouldValidate: true });
          };

          return (
            <FormItem
              className={`flex ${formItemClass} ${
                labelText ? "py-1.5 gap-1" : ""
              }`}
            >
              {labelText && (
                <FormLabel className={labelClassName}>{labelText}</FormLabel>
              )}

              <FormControl className="min-w-24">
                <div className="time-picker-container min-w-24">
                  <Input
                    {...(props as any)}
                    type="time"
                    value={field.value}
                    // onChange={handleSelectTime}
                    readOnly
                    // value={timeValue}
                    onClick={togglePicker}
                    placeholder={placeholder ?? "Select Time"}
                    className="!flex-none h-10 min-w-24 rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 select:none"
                  />

                  <Clock className="absolute top-3 right-3" size={16} />

                  {isOpen && (
                    <div className="time-picker">
                      <div className="hour-picker">
                        {hours.map((hour) => (
                          <div
                            className="select:none"
                            key={hour}
                            onClick={() =>
                              selectTime(hour, selectedTime.minute)
                            }
                            style={{
                              backgroundColor:
                                hour === selectedTime.hour ? "#ffa1a1" : "",
                            }}
                          >
                            {hour}
                          </div>
                        ))}
                      </div>

                      <div className="minute-picker">
                        {minutes.map((minute) => (
                          <div
                            className="select:none"
                            key={minute}
                            onClick={() =>
                              selectTime(selectedTime.hour, minute)
                            }
                            style={{
                              backgroundColor:
                                minute === selectedTime.minute ? "#ffa1a1" : "",
                            }}
                          >
                            {minute}
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </FormControl>

              <FormMessage className="text-wrap w-24">
                {isError?.message}
              </FormMessage>
            </FormItem>
          );
        }}
      />
    );
  }

  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => {
        const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
          let newValue = e.target.value;

          if (variant === "currency") {
            const value = convertCurrencyToString(e.target.value) ?? "";

            newValue = value?.replace(/[^0-9.]/g, "");

            if (newValue === "") newValue = "0";

            if (newValue && newValue.startsWith("0") && newValue.length > 1) {
              newValue = newValue.slice(1);
            }

            return setValue(name, newValue === "" ? "0" : newValue, {
              shouldValidate: true,
            });
          } else if (type === "number") {
            const value = e.target.value ?? "";

            newValue = value?.replace(/[^0-9.]/g, "");

            return setValue(name, newValue, { shouldValidate: true });
          }

          return setValue(name, newValue, { shouldValidate: true });
        };

        return (
          <FormItem className={formItemClass}>
            {labelText && (
              <FormLabel className={labelClassName}>{labelText}</FormLabel>
            )}

            <FormControl>
              <div className="relative">
                {!textArea ? (
                  <Input
                    {...field}
                    placeholder={placeholder}
                    value={
                      variant === "currency"
                        ? convertToCurrency(field.value)
                        : type === "number"
                        ? numberWithCommas(field.value)
                        : field.value
                    }
                    maxLength={maxCharacters}
                    onChange={onChange}
                    {...(props?.type === "number" && {
                      min: 0,
                    })}
                    {...props}
                    type={
                      type === "password"
                        ? showPassword
                          ? "text"
                          : "password"
                        : type === "number"
                        ? "text"
                        : type
                    }
                  />
                ) : (
                  <Textarea
                    {...field}
                    placeholder={placeholder}
                    value={field.value}
                    maxLength={maxCharacters}
                    {...(props as any)}
                  />
                )}

                {type === "password" && (
                  <Button
                    type="button"
                    onClick={togglePasswordVisibility}
                    className="absolute top-0 right-0 bg-transparent hover:bg-transparent"
                  >
                    {showPassword ? <Eye size={20} /> : <EyeSlash size={20} />}
                  </Button>
                )}
              </div>
            </FormControl>

            {maxCharacters && (
              <span className="text-sm font-normal text-darkIndicator-40">
                <>{`${field?.value?.length ?? 0}/${numberWithCommas(
                  maxCharacters
                )} Characters`}</>
              </span>
            )}

            {details.length > 0 && (
              <div>
                {details.map((detail, index) => (
                  <div className="!-mt-1" key={index}>
                    <span className="text-sm font-normal text-darkIndicator-40">
                      {detail}
                    </span>
                  </div>
                ))}
              </div>
            )}

            <FormMessage>{isError?.message}</FormMessage>
          </FormItem>
        );
      }}
    />
  );
};

export default CustomInput;
