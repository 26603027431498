import {
  OverviewNotificationsParams,
  OverviewNotificationsResponse,
} from "src/pages/Overview/api/interfaces";
import { getPagination } from "src/utils/getPagination";
import API from ".";

const featureApiRoute = "/notifications";

export const getNotifications = async ({
  limit,
  offset,
}: OverviewNotificationsParams): Promise<OverviewNotificationsResponse> => {
  const paginationParam = getPagination({ limit, offset });

  const response = await API.get<OverviewNotificationsResponse>(
    featureApiRoute,
    {
      params: {
        ...paginationParam,
      },
    }
  );

  return response.data;
};
