import {
  AdminListDetailRequestBody,
  AdminListDetailResponse,
  AdminListParams,
  AdminListResponse,
  ChangeMyPasswordRequestBody,
  ChangeMyPasswordResponse,
  ChangeOtherPasswordRequestBody,
  ChangeOtherPasswordResponse,
  CreateAdminListFormType,
  CreateAdminListResponse,
  DeleteAdminListResponse,
  DeleteAdminRequestBody,
  EditAdminListFormType,
  EditAdminListResponse,
  ToggleActiveStatusAdminListResponse,
  ToggleActiveStatusAdminRequestBody,
} from "src/pages/AdminManagement/AdminList/api/interfaces";
import { getPagination } from "src/utils/getPagination";
import API from ".";

const featureRouteApi = "/admins";

export const getAdminList = async ({
  limit,
  offset,
  adminName,
}: AdminListParams): Promise<AdminListResponse> => {
  const paginationParam = getPagination({ limit, offset });

  const response = await API.get<AdminListResponse>(featureRouteApi, {
    params: {
      ...paginationParam,
      ...(adminName ? { username: adminName } : {}),
    },
  });

  return response.data;
};

export const getAdminListDetail = async ({
  id,
  limit,
  offset,
}: AdminListDetailRequestBody): Promise<AdminListDetailResponse> => {
  const paginationParam = getPagination({ limit, offset });

  const response = await API.get<AdminListDetailResponse>(
    `${featureRouteApi}/${id}`,
    {
      params: {
        ...paginationParam,
      },
    }
  );

  return response.data;
};

export const createAdminList = async (
  form: CreateAdminListFormType
): Promise<CreateAdminListResponse> => {
  const requestBody = {
    name: form.name,
    username: form.username,
    password: form.password,
    phone_number: form.phoneNumber,
    email: form.email ?? "",
    admin_role_id: form.adminRoleId,
  };

  const response = await API.post<CreateAdminListResponse>(
    featureRouteApi,
    requestBody
  );

  return response.data;
};

export const editAdminList = async ({
  id,
  ...form
}: EditAdminListFormType): Promise<EditAdminListResponse> => {
  const requestBody = {
    name: form.name,
    username: form.username,
    phone_number: form.phoneNumber,
    email: form.email ?? "",
    admin_role_id: form.adminRoleId,
  };

  const response = await API.put<CreateAdminListResponse>(
    `${featureRouteApi}/${id}`,
    requestBody
  );

  return response.data;
};

export const deleteAdminList = async ({
  id,
}: DeleteAdminRequestBody): Promise<DeleteAdminListResponse> => {
  const response = await API.delete<DeleteAdminListResponse>(
    `${featureRouteApi}/${id}`
  );

  return response.data;
};

export const toggleActiveStatusAdminList = async ({
  id,
}: ToggleActiveStatusAdminRequestBody): Promise<ToggleActiveStatusAdminListResponse> => {
  const response = await API.patch<ToggleActiveStatusAdminListResponse>(
    `/admins/${id}`
  );

  return response.data;
};

export const changeOtherPassword = async ({
  id,
  ...form
}: ChangeOtherPasswordRequestBody): Promise<ChangeOtherPasswordResponse> => {
  const requestBody = {
    new_password: form.password,
    confirm_password: form.confirmPassword,
  };

  const response = await API.patch<ChangeOtherPasswordResponse>(
    `${featureRouteApi}/password/${id}`,
    requestBody
  );

  return response.data;
};

export const changeMyPassword = async ({
  id,
  ...form
}: ChangeMyPasswordRequestBody): Promise<ChangeMyPasswordResponse> => {
  const requestBody = {
    old_password: form.oldPassword,
    new_password: form.password,
    confirm_password: form.confirmPassword,
  };

  const response = await API.patch<ChangeOtherPasswordResponse>(
    `${featureRouteApi}/password/${id}`,
    requestBody
  );

  return response.data;
};
