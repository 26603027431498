import { startTransition, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { appAsset } from "src/appAsset";
import useSideBar from "src/components/SideBar/hooks/useSideBar";

const useWelcomeHandler = () => {
  const navigate = useNavigate();
  const { menus } = useSideBar();

  const { appName } = appAsset;

  useEffect(() => {
    if (menus.length > 0) {
      startTransition(() =>
        navigate(
          (menus[0].multiple
            ? menus[0]!.subMenus![0].routePath
            : menus[0].routePath) ?? "/"
        )
      );
    }
  }, [navigate, menus]);

  return { appName };
};

export default useWelcomeHandler;
