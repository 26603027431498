import { useQuery } from "@tanstack/react-query";
import {
  getBusinessOperation,
  getBusinessOperationDetail,
} from "src/services/BusinessOperation.service";
import {
  BusinessOperationDetailRequestBody,
  BusinessOperationParams,
} from "./interfaces";
import { reactQueryOptions } from "src/config/reactQuery.config";

export const useGetBusinessOperation = (params: BusinessOperationParams) => {
  return useQuery({
    ...reactQueryOptions,
    queryKey: ["business-operation", params],
    queryFn: () => getBusinessOperation(params),
  });
};

export const useGetBusinessOperationDetail = (
  requestBody: BusinessOperationDetailRequestBody
) => {
  return useQuery({
    ...reactQueryOptions,
    queryKey: ["business-operation-detail"],
    queryFn: () => getBusinessOperationDetail(requestBody),
  });
};
