import React from "react";
import { Checkbox } from "../ui/checkbox";
import { FormControl, FormField, FormItem, FormLabel } from "../ui/form";
import { CheckboxProps } from "@radix-ui/react-checkbox";

interface CheckBoxInputProps extends CheckboxProps {
  form: any;
  name: string;
  labelText?: string;
  size?: "default" | "large";
  noWrap?: boolean;
  onCheck?: (checked: boolean) => void;
  textClassName?: string;
}

const CheckBoxInput: React.FC<CheckBoxInputProps> = (props) => {
  const {
    form,
    name,
    labelText,
    size = "default",
    className = "",
    noWrap,
    onCheck,
    textClassName = "",
  } = props;

  const {
    control,
    formState: { errors },
    setValue,
  } = form;

  const isError = errors[name];

  const checkBoxSize = size === "default" ? "" : "h-5 w-5";

  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem className="flex flex-row items-start space-x-3 space-y-0">
          <FormControl>
            <Checkbox
              className={`${
                isError ? "border-redIndicator-40" : ""
              } ${checkBoxSize} ${className}`}
              checked={field.value}
              onCheckedChange={(checked) => {
                setValue(name, checked, { shouldValidate: true });

                if (onCheck) {
                  onCheck(checked as boolean);
                }
              }}
              {...props}
            />
          </FormControl>

          {labelText && (
            <FormLabel
              className={`pt-0.5 ${
                noWrap ? "text-nowrap" : "text-wrap"
              } ${textClassName}`}
            >
              {labelText}
            </FormLabel>
          )}
        </FormItem>
      )}
    />
  );
};

export default CheckBoxInput;
