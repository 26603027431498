import { format, isBefore } from "date-fns";
import parsePhoneNumberFromString from "libphonenumber-js";
import { getCountryCodes } from "src/utils/getCountries";
import { z } from "zod";

export const adminPasswordMinSchema = z
  .string()
  .min(1, { message: "Required" })
  .min(8, { message: "Password must be at least 8 characters long" });

export const adminPasswordSchema = adminPasswordMinSchema
  .regex(/[A-Z]/, {
    message: "Password must contain at least one uppercase letter",
  })
  .regex(/[\W_]/, {
    message: "Password must contain at least one special character",
  });

export const isCountryCodeOnly = (value: string) => {
  const countryCodes = getCountryCodes().map((countryCode) => countryCode.code);

  return countryCodes.includes(value) ? true : false;
};

export const phoneNumberValidation = (value: string, required?: boolean) => {
  if (value === "" && !required) {
    return true;
  }

  const phoneNumber = parsePhoneNumberFromString(value);

  if (!isCountryCodeOnly(value) && !phoneNumber?.isValid()) {
    return false;
  }
  if (required && isCountryCodeOnly(value)) {
    return false;
  }

  return true;
};

export const phoneNumberSchema = (
  required?: boolean
): z.ZodEffects<z.ZodString, string, string> => {
  return z.string().refine((value) => phoneNumberValidation(value, required), {
    message: "Invalid phone number",
  });
};

export const validateDateBefore = ({
  ctx,
  dateToValidate,
  targetDate,
  dateToValidateTitle,
  targetDateTitle,
  dateToValidateFormName,
  isDateTime,
}: {
  ctx: z.RefinementCtx;
  dateToValidate: Date | null | undefined;
  targetDate: Date;
  dateToValidateTitle: string;
  targetDateTitle: string;
  dateToValidateFormName: string;
  isDateTime?: boolean;
}) => {
  if (
    dateToValidate &&
    isBefore(
      format(dateToValidate, `yyyy-MM-dd ${isDateTime ? "HH:mm" : ""}`),
      format(targetDate, `yyyy-MM-dd ${isDateTime ? "HH:mm" : ""}`)
    )
  ) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: `${dateToValidateTitle} cannot be before the ${targetDateTitle}`,
      path: [dateToValidateFormName],
    });
  }
};
