import React, { useState } from "react";
import { capitalize } from "src/utils/commons";
import { TableOrderItem } from "./OrderListTableRow";
import OrderListTableRowOrderItemProducts from "./OrderListTableRowOrderItemProducts";

interface OrderListTableRowProps {
  orderItems: TableOrderItem[];
}

const OrderListTableRowOrderItems: React.FC<OrderListTableRowProps> = ({
  orderItems,
}) => {
  const [isShowMoreOrderItems, setIsShowMoreOrderItems] = useState(false);

  const showedData = isShowMoreOrderItems
    ? orderItems
    : [...orderItems].splice(0, 3);

  const handleToggleShowMoreOrderItem = () =>
    setIsShowMoreOrderItems(!isShowMoreOrderItems);

  return (
    <div className="flex flex-col">
      {showedData.map((orderItem, idx) => (
        <div className="flex flex-col" key={idx}>
          <div className="flex flex-col">
            <span className="text-sm font-semibold text-darkBrown-default">{`${
              orderItem.name
            }${orderItem.quantity ? ` - (x${orderItem.quantity})` : ""}`}</span>
            {orderItem?.orderType && orderItem?.warehouseName && (
              <span className="text-sm font-normal text-darkIndicator-30">{`${capitalize(
                orderItem.orderType
              )} Item - ${orderItem.warehouseName}`}</span>
            )}
          </div>

          <OrderListTableRowOrderItemProducts orderItem={orderItem} />
        </div>
      ))}

      {orderItems.length > 3 && (
        <span
          className="cursor-pointer font-semibold underline text-primary-50"
          onClick={handleToggleShowMoreOrderItem}
        >
          {isShowMoreOrderItems ? "Show Less" : "Show More"}
        </span>
      )}
    </div>
  );
};

export default OrderListTableRowOrderItems;
