import { useMutation } from "@tanstack/react-query";
import queryClient from "src/config/reactQuery.config";
import {
  OrderListContinueToPickUpOrDeliveryRequestBody,
  OrderListContinueToPickUpOrDeliveryResponse,
  OrderListGenerateRecipeRequestBody,
  OrderListGenerateRecipeResponse,
  OrderListGetEditOrderDetailRequestBody,
  OrderListGetEditOrderDetailResponse,
  OrderListMarkAsCompleteOrderRequestBody,
  OrderListMarkAsCompleteOrderResponse,
  OrderListMarkAsPickUpOrDeliveryRequestBody,
  OrderListMarkAsPickUpOrDeliveryResponse,
  OrderListSetFixedDateRequestBody,
  OrderListSetFixedDateResponse,
  OrderListSwitchToDeliveryRequestBody,
  OrderListSwitchToDeliveryResponse,
  OrderListSwitchToPickUpRequestBody,
  OrderListSwitchToPickUpResponse,
} from "./interfaces";
import {
  orderListContinueToPickUpOrDelivery,
  orderListGenerateRecipes,
  orderListGetEditOrderDetail,
  orderListMarkAsCompleteOrder,
  orderListMarkAsPickUpOrDelivery,
  orderListSetFixedDate,
  orderListSwitchToDelivery,
  orderListSwitchToPickUp,
} from "src/services/OrderList.service";

export const useOrderListContinueToPickUpOrDelivery = () => {
  return useMutation<
    OrderListContinueToPickUpOrDeliveryResponse,
    Error,
    OrderListContinueToPickUpOrDeliveryRequestBody
  >({
    mutationFn: (value) => orderListContinueToPickUpOrDelivery(value),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["order-list"],
      });
      queryClient.invalidateQueries({
        queryKey: ["order-list-detail"],
      });
    },
  });
};

export const useOrderListGenerateRecipe = () => {
  return useMutation<
    OrderListGenerateRecipeResponse,
    Error,
    OrderListGenerateRecipeRequestBody
  >({
    mutationFn: (value) => orderListGenerateRecipes(value),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["order-list/generate-recipe"],
      });
    },
  });
};

export const useOrderListSetFixedDate = () => {
  return useMutation<
    OrderListSetFixedDateResponse,
    Error,
    OrderListSetFixedDateRequestBody
  >({
    mutationFn: (value) => orderListSetFixedDate(value),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["order-list"],
      });
    },
  });
};

export const useOrderListSwitchToPickUp = () => {
  return useMutation<
    OrderListSwitchToPickUpResponse,
    Error,
    OrderListSwitchToPickUpRequestBody
  >({
    mutationFn: (value) => orderListSwitchToPickUp(value),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["order-list"],
      });
    },
  });
};

export const useOrderListSwitchToDelivery = () => {
  return useMutation<
    OrderListSwitchToDeliveryResponse,
    Error,
    OrderListSwitchToDeliveryRequestBody
  >({
    mutationFn: (value) => orderListSwitchToDelivery(value),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["order-list"],
      });
    },
  });
};

export const useOrderListMarkAsPickUpOrDelivery = () => {
  return useMutation<
    OrderListMarkAsPickUpOrDeliveryResponse,
    Error,
    OrderListMarkAsPickUpOrDeliveryRequestBody
  >({
    mutationFn: (value) => orderListMarkAsPickUpOrDelivery(value),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["order-list"] });
    },
  });
};

export const useOrderListMarkAsComplete = () => {
  return useMutation<
    OrderListMarkAsCompleteOrderResponse,
    Error,
    OrderListMarkAsCompleteOrderRequestBody
  >({
    mutationFn: (value) => orderListMarkAsCompleteOrder(value),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["order-list"] });
    },
  });
};

export const useGetOrderListEditOrderDetail = () => {
  return useMutation<
    OrderListGetEditOrderDetailResponse,
    Error,
    OrderListGetEditOrderDetailRequestBody
  >({
    mutationFn: (value) => orderListGetEditOrderDetail(value),
  });
};
