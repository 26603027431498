import { format } from "date-fns";
import { useState } from "react";
import {
  PaginationType,
  SetPaginationCountType,
} from "src/components/TablePaginationLimitChanger/hooks/useTablePaginationLimitChanger";
import { useGetAccessPermission } from "src/hooks/useGetAccessPermission";
import { useSetPaginationCount } from "src/hooks/useSetPaginationCount";
import { SelectOptionsType } from "src/types";
import { AccessResource, ActionType } from "src/types/accessControl";
import { convertArrayParamsToString } from "src/utils/convertArrayParamsToString";
import { useGetOrderList } from "../api/queries";
import { DateRange } from "react-day-picker";
import { ExportSummaryType } from "src/components/ExportSummaryModal";
import { OrderListTransactionStatus } from "../api/interfaces";
import { getOrderTransactionStatus } from "../OrderListTable/OrderListTableRow";

export interface OrderListFilterType {
  orderType: SelectOptionsType;
  productName: SelectOptionsType;
  productVariant: SelectOptionsType;
  packageName: SelectOptionsType;
  packageVariant: SelectOptionsType;
  businessOperation: SelectOptionsType;
  transactionCategory: SelectOptionsType;
  paymentStatus: SelectOptionsType;
  paymentType: SelectOptionsType;
  customerType: SelectOptionsType;
  customerCategory: SelectOptionsType;
}

export const orderListFilterDefaultValue = {
  orderType: [],
  productName: [],
  productVariant: [],
  packageName: [],
  packageVariant: [],
  businessOperation: [],
  transactionCategory: [],
  paymentStatus: [],
  paymentType: [],
  customerType: [],
  customerCategory: [],
};

interface UseOrderListHandlerProps {
  pagination: PaginationType;
  setCount: SetPaginationCountType;
}

const orderListDateFilterTypeOptions = [
  {
    label: "Filter By Delivery / Pickup Date",
    value: "delivery-or-pickup-date",
    placeholder: "Select Date",
  },
  {
    label: "Filter By Transaction Date",
    value: "transaction-date",
    placeholder: "Select Date",
  },
];

export const orderListSortOptions = [
  {
    label: "Nearest Pickup / Delivery Date",
    value: "nearest-pickup-or-delivery-date",
  },
  {
    label: "Furthest Pickup / Delivery Date",
    value: "furthest-pickup-or-delivery-date",
  },
  {
    label: "Unpaid to Paid Order",
    value: "unpaid-to-paid-order",
  },
];

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const orderListSortValue = [
  "nearest-pickup-or-delivery-date",
  "furthest-pickup-or-delivery-date",
  "unpaid-to-paid-order",
] as const;

export type OrderListSortValueType = (typeof orderListSortValue)[number];

export const getOrderListSortingParams = (type: OrderListSortValueType) => {
  let params = {};

  if (type === "nearest-pickup-or-delivery-date") {
    params = {
      sort_by: "on_fulfilled_date",
      sort_order: "asc",
    };
  } else if (type === "furthest-pickup-or-delivery-date") {
    params = {
      sort_by: "on_fulfilled_date",
      sort_order: "desc",
    };
  } else if (type === "unpaid-to-paid-order") {
    params = {
      sort_by: "payment_status",
      sort_order: "asc",
    };
  }

  return params;
};

export const getOrderListTransactionStatuses = (index: number) => {
  let result;

  if (index === 0) {
    result = ["in_production"];
  } else if (index === 1) {
    result = ["on_waiting", "on_fulfilled"];
  } else {
    result = ["completed"];
  }

  return result as OrderListTransactionStatus[];
};

interface GetOrderListExportSummariesProps {
  activeTabIndex: number;
  searchInputValue: string;
  searchInputType: string;
  dateFilterInputValue?: DateRange;
  dateFilterType?: string;
  filter: OrderListFilterType;
}

export const getOrderListExportSummaries = ({
  activeTabIndex,
  searchInputValue,
  searchInputType,
  dateFilterInputValue,
  dateFilterType,
  filter,
}: GetOrderListExportSummariesProps) => {
  const selectedOrderListDateFilterTypeOption =
    orderListDateFilterTypeOptions.find(
      (orderListDateFilterTypeOption) =>
        orderListDateFilterTypeOption.label === dateFilterType
    );

  return [
    {
      label: "Order Status",
      value: getOrderListTransactionStatuses(activeTabIndex)
        .map((value) =>
          getOrderTransactionStatus({
            transactionStatus: value,
            orderType: null,
            hasReturn: false,
          })
        )
        .join(", "),
      paramsKey: "transaction_statuses",
      paramsValue:
        getOrderListTransactionStatuses(activeTabIndex).length > 0
          ? convertArrayParamsToString(
              getOrderListTransactionStatuses(activeTabIndex)
            )
          : "",
    },
    {
      label: "Customer / Partner Name",
      value:
        searchInputValue !== "" &&
        searchInputType.startsWith("Search by Customer / Partner Name")
          ? searchInputValue
          : "All User",
      paramsKey: "qn",
      paramsValue:
        searchInputValue !== "" &&
        searchInputType.startsWith("Search by Customer / Partner Name")
          ? searchInputValue
          : "",
    },
    {
      label: "Recipient Name",
      value:
        searchInputValue !== "" &&
        searchInputType.startsWith("Search by Recipient Name")
          ? searchInputValue
          : "All User",
      paramsKey: "qrc",
      paramsValue:
        searchInputValue !== "" &&
        searchInputType.startsWith("Search by Recipient Name")
          ? searchInputValue
          : "",
    },
    {
      label: "Invoice Number",
      value:
        searchInputValue !== "" &&
        searchInputType === "Search by Invoice Number"
          ? searchInputValue
          : "All Invoice",
      paramsKey: "qinv",
      paramsValue:
        searchInputValue !== "" &&
        searchInputType === "Search by Invoice Number"
          ? searchInputValue
          : "",
    },
    ...(selectedOrderListDateFilterTypeOption
      ? selectedOrderListDateFilterTypeOption.label ===
        orderListDateFilterTypeOptions[0].label
        ? [
            {
              label: "Delivery / Pickup Start Date",
              value: dateFilterInputValue?.from
                ? format(dateFilterInputValue.from, "dd MMMM yyyy")
                : "All Date",
              paramsKey: "start_onfulfill_date",
              paramsValue: dateFilterInputValue?.from
                ? format(dateFilterInputValue.from, "yyyy-MM-dd")
                : "",
            },
            {
              label: "Delivery / Pickup End Date",
              value: dateFilterInputValue?.to
                ? format(dateFilterInputValue.to, "dd MMMM yyyy")
                : "All Date",
              paramsKey: "end_onfulfill_date",
              paramsValue: dateFilterInputValue?.to
                ? format(dateFilterInputValue.to, "yyyy-MM-dd")
                : "",
            },
          ]
        : [
            {
              label: "Transaction Start Date",
              value: dateFilterInputValue?.from
                ? format(dateFilterInputValue.from, "dd MMMM yyyy")
                : "All Date",
              paramsKey: "start_order_date",
              paramsValue: dateFilterInputValue?.from
                ? format(dateFilterInputValue.from, "yyyy-MM-dd")
                : "",
            },
            {
              label: "Transaction End Date",
              value: dateFilterInputValue?.to
                ? format(dateFilterInputValue.to, "dd MMMM yyyy")
                : "All Date",
              paramsKey: "end_order_date",
              paramsValue: dateFilterInputValue?.to
                ? format(dateFilterInputValue.to, "yyyy-MM-dd")
                : "",
            },
          ]
      : [null]),
    {
      label: "Order Type",
      value:
        filter.orderType.length > 0
          ? `${filter.orderType.map((psc) => psc?.label)}`
          : "All Type",
      paramsKey: "order_methods",
      paramsValue:
        filter.orderType.length > 0
          ? convertArrayParamsToString(
              filter.orderType.map((option) => option.value)
            )
          : "",
    },
    {
      label: "Product Name",
      value:
        filter.productName.length > 0
          ? `${filter.productName.map((psc) => psc?.label)}`
          : "All Product",
      paramsKey: "product_ids",
      paramsValue:
        filter.productName.length > 0
          ? convertArrayParamsToString(
              filter.productName.map((option) => option.value)
            )
          : "",
    },
    {
      label: "Product Variant",
      value:
        filter.productVariant.length > 0
          ? `${filter.productVariant.map((psc) => psc?.label)}`
          : "All Product Variant",
      paramsKey: "product_variant_ids",
      paramsValue:
        filter.productVariant.length > 0
          ? convertArrayParamsToString(
              filter.productVariant.map((option) => option.value)
            )
          : "",
    },
    {
      label: "Package Name",
      value:
        filter.packageName.length > 0
          ? `${filter.packageName.map((psc) => psc?.label)}`
          : "All Package",
      paramsKey: "package_ids",
      paramsValue:
        filter.packageName.length > 0
          ? convertArrayParamsToString(
              filter.packageName.map((option) => option.value)
            )
          : "",
    },
    {
      label: "Package Variant",
      value:
        filter.packageVariant.length > 0
          ? `${filter.packageVariant.map((psc) => psc?.label)}`
          : "All Package Variant",
      paramsKey: "package_variant_ids",
      paramsValue:
        filter.packageVariant.length > 0
          ? convertArrayParamsToString(
              filter.packageVariant.map((option) => option.value)
            )
          : "",
    },
    {
      label: "Business Operation",
      value:
        filter.businessOperation.length > 0
          ? `${filter.businessOperation.map((psc) => psc?.label)}`
          : "All Business Operation",
      paramsKey: "business_operation_ids",
      paramsValue:
        filter.businessOperation.length > 0
          ? convertArrayParamsToString(
              filter.businessOperation.map((option) => option.value)
            )
          : "",
    },
    {
      label: "Transaction Category",
      value:
        filter.transactionCategory.length > 0
          ? `${filter.transactionCategory.map((psc) => psc?.label)}`
          : "All Transaction Category",
      paramsKey: "transaction_flows",
      paramsValue:
        filter.transactionCategory.length > 0
          ? convertArrayParamsToString(
              filter.transactionCategory.map((option) => option.value)
            )
          : "",
    },
    {
      label: "Payment Status",
      value:
        filter.paymentStatus.length > 0
          ? `${filter.paymentStatus.map((psc) => psc?.label)}`
          : "All Payment Status",
      paramsKey: "payment_statuses",
      paramsValue:
        filter.paymentStatus.length > 0
          ? convertArrayParamsToString(
              filter.paymentStatus.map((option) => option.value)
            )
          : "",
    },
    {
      label: "Payment Type",
      value:
        filter.paymentType.length > 0
          ? `${filter.paymentType.map((psc) => psc?.label)}`
          : "All Payment Type",
      paramsKey: "payment_types",
      paramsValue:
        filter.paymentType.length > 0
          ? convertArrayParamsToString(
              filter.paymentType.map((option) => option.value)
            )
          : "",
    },
    {
      label: "Customer Type",
      value:
        filter.customerType.length > 0
          ? `${filter.customerType.map((psc) => psc?.label)}`
          : "All Customer Type",
      paramsKey: "customer_type_ids",
      paramsValue:
        filter.customerType.length > 0
          ? convertArrayParamsToString(
              filter.customerType.map((option) => option.value)
            )
          : "",
    },
    {
      label: "Customer Category",
      value:
        filter.customerCategory.length > 0
          ? `${filter.customerCategory.map((psc) => psc?.label)}`
          : "All Customer Category",
      paramsKey: "buyer_types",
      paramsValue:
        filter.customerCategory.length > 0
          ? convertArrayParamsToString(
              filter.customerCategory.map((option) => option.value)
            )
          : "",
    },
  ].filter((value) => value !== null);
};

export const orderListTabs = [
  "For Production",
  "Waiting For Pick Up or Delivery",
  "Completed",
];
export enum ORDER_LIST_TAB {
  ForProduction = "FOR_PRODUCTION",
  WaitingForPickUpOrDelivery = "WAITING_FOR_PICK_UP_OR_DELIVERY",
  Completed = "COMPLETED",
}

const useOrderListHandler = ({
  pagination,
  setCount,
}: UseOrderListHandlerProps) => {
  const isAllowToSeePhoneNumber = useGetAccessPermission({
    resource:
      AccessResource["Other Access - Allow to View Supplier Phone Number"],
    action: ActionType["Allow"],
  });

  const orderListSearchTypeOptions = [
    {
      label: `Search by Customer / Partner Name ${
        isAllowToSeePhoneNumber ? "or Phone Number" : ""
      }`,
      value: "customer-or-partner-name-or-phone-number",
      placeholder: `Input Name ${
        isAllowToSeePhoneNumber ? "or Phone Number" : ""
      }`,
    },
    {
      label: `Search by Recipient Name ${
        isAllowToSeePhoneNumber ? "or Phone Number" : ""
      }`,
      value: "customer-recipient-name-or-phone-number",
      placeholder: `Input Name ${
        isAllowToSeePhoneNumber ? "or Phone Number" : ""
      }`,
    },
    {
      label: "Search by Invoice Number",
      value: "invoice-number",
      placeholder: "Input Invoice Number",
    },
  ];

  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const [selectedRecipeIds, setSelectedRecipeIds] = useState<number[]>([]);
  const [searchInputValue, setSearchInputValue] = useState("");
  const [searchType, setSearchType] = useState(
    orderListSearchTypeOptions[0].label
  );
  const [dateFilterInputValue, setDateFilterInputValue] = useState<
    DateRange | undefined
  >();
  const [dateFilterType, setDateFilterType] = useState(
    orderListDateFilterTypeOptions[0].label
  );
  const [isShowExportSummaryModal, setIsShowExportSummaryModal] =
    useState(false);
  const [sort, setSort] = useState(orderListSortOptions[0].value);
  const [filter, setFilter] = useState<OrderListFilterType>(
    orderListFilterDefaultValue
  );

  const { data, isLoading } = useGetOrderList({
    ...pagination,
    transactionStatuses: getOrderListTransactionStatuses(activeTabIndex),
    searchValue: searchInputValue,
    searchType: searchType,
    dateFilterValue: dateFilterInputValue,
    dateFilterType: dateFilterType,
    sortBy: sort,
    ...filter,
  });

  const handleExportSummaryModal = () => setIsShowExportSummaryModal(true);
  const handleCloseExportSummaryModal = () =>
    setIsShowExportSummaryModal(false);

  useSetPaginationCount({ meta: data?.meta, setCount });

  const handleTabCallback = (index: number) => {
    setActiveTabIndex(index);
    setSelectedRecipeIds([]);
  };

  const exportSummaries = getOrderListExportSummaries({
    activeTabIndex,
    searchInputValue,
    searchInputType: searchType,
    dateFilterInputValue,
    dateFilterType,
    filter,
  });

  return {
    tabs: orderListTabs,
    isLoading,
    orderList: data?.data,
    totalCount: data?.meta.total ?? 0,
    orderListSearchTypeOptions,
    orderListDateFilterTypeOptions,
    sortOptions: orderListSortOptions,
    searchType,
    setSearchType,
    dateFilterType,
    setDateFilterType,
    dateFilterInputValue,
    setDateFilterInputValue,
    sort,
    setSort,
    filter,
    setFilter,
    hasFilter:
      searchInputValue !== "" ||
      Object.values(filter).some((filter) =>
        Array.isArray(filter) ? filter.length > 0 : filter
      ),
    selectedRecipeIds,
    setSelectedRecipeIds,
    handleTabCallback,
    setSearchInputValue,
    isShowExportSummaryModal,
    handleExportSummaryModal,
    handleCloseExportSummaryModal,
    exportSummaries: exportSummaries as ExportSummaryType[],
  };
};

export default useOrderListHandler;
