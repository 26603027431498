import React from "react";
import { UseFormReturn } from "react-hook-form";
import CustomInput from "src/components/CustomInput";
import { Form } from "src/components/ui/form";
import {
  ChangeMyPasswordFormType,
  ChangeOtherPasswordFormType,
} from "src/pages/AdminManagement/AdminList/api/interfaces";

interface ChangePasswordFormProps {
  form:
    | UseFormReturn<ChangeOtherPasswordFormType, any, undefined>
    | UseFormReturn<ChangeMyPasswordFormType, any, undefined>;
  type: "OTHER" | "MINE";
}

const ChangePasswordForm: React.FC<ChangePasswordFormProps> = ({
  form,
  type,
}) => {
  return (
    <Form
      {...(form as UseFormReturn<
        ChangeOtherPasswordFormType | ChangeMyPasswordFormType,
        any,
        undefined
      >)}
    >
      <div className="w-full grid gap-4 mb-12">
        {type === "MINE" && (
          <CustomInput
            form={form}
            name="oldPassword"
            labelText="Old Password"
            placeholder="Input old password"
            type="password"
          />
        )}

        <CustomInput
          form={form}
          name="password"
          labelText="Password"
          placeholder="Input password"
          type="password"
        />

        <CustomInput
          form={form}
          name="confirmPassword"
          labelText="Password"
          placeholder="Input confirm password"
          type="password"
        />
      </div>
    </Form>
  );
};

export default ChangePasswordForm;
