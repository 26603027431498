import React from "react";
import PageEmpty from "src/components/PageEmpty";
import Spinner from "src/components/Spinner";
import { Button } from "src/components/ui/button";
import {
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuLabel,
} from "src/components/ui/dropdown-menu";
import useNotificationDropdownMenuHandler from "../hooks/useNotificationDropdownMenuHandler";
import NotificationDropdownMenuItem from "./NotificationDropdownMenuItem";

interface NotificationDropdownMenuContentProps {}

const NotificationDropdownMenuContent: React.FC<
  NotificationDropdownMenuContentProps
> = () => {
  const { notifications, isLoading, handleViewDetail } =
    useNotificationDropdownMenuHandler();

  return (
    <DropdownMenuContent className="w-[460px]" align="end">
      <DropdownMenuLabel className="text-xl text-darkBrown-default font-semibold">
        Notifications
      </DropdownMenuLabel>

      <DropdownMenuGroup className="px-3">
        {!isLoading ? (
          notifications.length > 0 ? (
            notifications.map((notification, index) => (
              <NotificationDropdownMenuItem
                notification={notification}
                key={index}
              />
            ))
          ) : (
            <div className="pt-5">
              <PageEmpty />
            </div>
          )
        ) : (
          <div className="flex items-center justify-center h-20">
            <Spinner />
          </div>
        )}
      </DropdownMenuGroup>

      <div className="flex justify-end mt-4">
        <Button variant="link" className="underline" onClick={handleViewDetail}>
          View Detail
        </Button>
      </div>
    </DropdownMenuContent>
  );
};

export default NotificationDropdownMenuContent;
