import {
  LogoutResponse,
  MeResponse,
  SignInFormType,
  SignInResponse,
} from "src/pages/SignIn/api/intefaces";
import { tokenNames } from "src/utils/getAuthTokens";
import API from "./index";
import queryClient from "src/config/reactQuery.config";

export const signIn = async ({
  username,
  password,
}: SignInFormType): Promise<SignInResponse> => {
  const response = await API.post<SignInResponse>("/auth/sign-in", {
    username,
    password,
  });

  return response.data;
};

export const refreshToken = async () => {
  const response = await API.put<SignInResponse>("/auth/refresh");
  const { access_token, refresh_token } = response.data;

  localStorage.setItem(tokenNames["token"], access_token);
  localStorage.setItem(tokenNames["refreshToken"], refresh_token);
};

export const logOut = async () => {
  await API.post<LogoutResponse>("/auth/sign-out");

  localStorage.removeItem(tokenNames["token"]);
  localStorage.removeItem(tokenNames["refreshToken"]);

  queryClient.removeQueries({ queryKey: ["me"] });
  queryClient.invalidateQueries({ queryKey: ["me"] });
};

export const getCurrentUser = async (): Promise<MeResponse> => {
  const response = await API.get<MeResponse>("/auth/me");

  return response.data;
};
