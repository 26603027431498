import {
  differenceInDays,
  format,
  isAfter,
  isBefore,
  isSameDay,
} from "date-fns";
import React from "react";
import ReactShowMoreText from "react-show-more-text";
import TableActionDropdown from "src/components/TableActionDropDown";
import { Checkbox } from "src/components/ui/checkbox";
import { TableCell, TableRow } from "src/components/ui/table";
import { reactShowMoreTextConfig } from "src/config/reactShowMoreText.config";
import {
  OrderMethodValue,
  PAYMENT_METHOD_BACKGROUND,
  PAYMENT_STATUS_BACKGROUND,
  TRANSACTION_LABEL_BACKGROUND,
  TransactionFlowValue,
} from "src/constant";
import { capitalize } from "src/utils/commons";
import { convertToCurrency } from "src/utils/convertToCurrency";
import { formatPhoneNumber } from "src/utils/formatPhoneNumber";
import { OrderList, OrderListTransactionStatus } from "../api/interfaces";
import OrderListTableRowOrderItems from "./OrderListTableRowOrderItems";
import { DropdownMenuItem } from "src/components/ui/dropdown-menu";
import { ORDER_LIST_TAB } from "../hooks/useOrderListHandler";
import {
  PaymentStatus,
  PaymentType,
  TransactionFlow,
} from "src/types/apiResponse";

interface OrderListTableRowProps {
  isAllowToSwitchOrderTypeToPickUpOrDelivery: boolean;
  isAllowToSetFixedDateForEstimatedOrder: boolean;
  type: ORDER_LIST_TAB;
  orderList: OrderList;
  index: number;
  selectedRecipeIds: number[];
  handleToggleSelectRecipe: (id: number) => void;
  handleContinuePickUpOrDelivery: (id: number) => void;
  handleGenerateOrderRecipe: (id: number) => void;
  handleViewDetail: (id: number) => void;
  handleSetFixedDate: (data: OrderList) => void;
  handleSwitchToPickUp: (data: OrderList) => void;
  handleSwitchToDelivery: (data: OrderList) => void;
  handleMarkAsPickUpOrDelivery: (props: {
    id: number;
    orderMethod: OrderMethodValue;
  }) => void;
  handleMarkAsCompleteOrder: (id: number) => void;
  handleCreateMemorandum: (orderList: OrderList) => void;
}

export type TableOrderItem =
  | {
      name: string;
      quantity: number | null;
      orderType: string;
      warehouseName: string;
      products?: [];
    }
  | {
      name: string;
      quantity: number | null;
      orderType?: null;
      warehouseName?: null;
      products: {
        name: string;
        quantity: number;
        orderType: string;
        warehouseName: string;
      }[];
    };

export const getOrderTransactionStatus = ({
  transactionStatus,
  orderType,
  hasReturn,
}: {
  transactionStatus: OrderListTransactionStatus;
  orderType: OrderMethodValue | null;
  hasReturn: boolean;
}) => {
  let orderStatus: string = transactionStatus;

  if (transactionStatus === "in_production") {
    orderStatus = "For Production";
  } else if (transactionStatus === "on_waiting") {
    if (orderType !== null) {
      orderStatus = `Waiting For ${
        orderType === "delivery" ? "Delivery" : "Pick Up"
      }`;
    } else {
      orderStatus = "Waiting For Pick Up or Delivery";
    }
  } else if (transactionStatus === "on_fulfilled") {
    if (orderType !== null) {
      orderStatus = orderType === "delivery" ? "On Delivery" : "Has Pick Up";
    } else {
      orderStatus = "Has Pick Up or On Delivery";
    }
  } else if (transactionStatus === "completed") {
    orderStatus = "Arrived";

    if (hasReturn) {
      orderStatus = "Arrived - Has Return Item";
    }
  } else if (transactionStatus === "cancelled") {
    orderStatus = "Cancelled";
  }

  return orderStatus;
};

export const getTransactionStatusBadges = ({
  transactionFlow,
  isEstimationOrder,
  paymentStatus,
  paymentMethod,
}: {
  transactionFlow: TransactionFlow;
  isEstimationOrder: boolean;
  paymentStatus: PaymentStatus;
  paymentMethod: PaymentType;
}) => {
  return [
    transactionFlow ? (
      <div
        className="font-normal text-xs px-1 rounded"
        style={{
          backgroundColor: TRANSACTION_LABEL_BACKGROUND[transactionFlow],
        }}
      >
        {TransactionFlowValue[transactionFlow]}
      </div>
    ) : null,
    isEstimationOrder ? (
      <div
        className="font-normal text-xs px-1 rounded"
        style={{
          backgroundColor: TRANSACTION_LABEL_BACKGROUND["estimation_order"],
        }}
      >
        Estimation Order
      </div>
    ) : null,
    <div
      className="font-normal text-xs px-1 rounded"
      style={{
        backgroundColor: PAYMENT_STATUS_BACKGROUND[paymentStatus],
      }}
    >
      {capitalize(paymentStatus)}
    </div>,
    <div
      className="font-normal text-xs px-1 rounded"
      style={{
        backgroundColor: PAYMENT_METHOD_BACKGROUND[paymentMethod],
      }}
    >
      {capitalize(paymentMethod, true)}
    </div>,
  ].filter((data) => data !== null);
};

export const getPickUpOrDeliveryStatus = ({
  deliveryOrPickUpDate,
  orderDate,
  orderMethod,
}: {
  deliveryOrPickUpDate: string | null;
  orderDate: string;
  orderMethod: string;
}) => {
  if (
    !deliveryOrPickUpDate ||
    (deliveryOrPickUpDate &&
      isSameDay(
        format(orderDate, "yyyy-MM-dd"),
        format(deliveryOrPickUpDate, "yyyy-MM-dd")
      ))
  ) {
    return `Direct ${capitalize(orderMethod)} by Today`;
  } else if (
    deliveryOrPickUpDate &&
    isBefore(
      format(deliveryOrPickUpDate, "yyyy-MM-dd"),
      format(new Date(), "yyyy-MM-dd")
    )
  ) {
    return `${capitalize(orderMethod)} missed by ${differenceInDays(
      format(deliveryOrPickUpDate, "yyyy-MM-dd"),
      format(new Date(), "yyyy-MM-dd")
    )} days`;
  } else if (
    deliveryOrPickUpDate &&
    isAfter(
      format(deliveryOrPickUpDate, "yyyy-MM-dd"),
      format(new Date(), "yyyy-MM-dd")
    )
  ) {
    return `${capitalize(orderMethod)} in ${differenceInDays(
      format(deliveryOrPickUpDate, "yyyy-MM-dd"),
      format(new Date(), "yyyy-MM-dd")
    )} days`;
  }
};

const OrderListTableRow: React.FC<OrderListTableRowProps> = ({
  isAllowToSwitchOrderTypeToPickUpOrDelivery,
  isAllowToSetFixedDateForEstimatedOrder,
  type,
  orderList,
  index,
  selectedRecipeIds,
  handleToggleSelectRecipe,
  handleContinuePickUpOrDelivery,
  handleGenerateOrderRecipe,
  handleViewDetail,
  handleSetFixedDate,
  handleSwitchToPickUp,
  handleSwitchToDelivery,
  handleMarkAsPickUpOrDelivery,
  handleMarkAsCompleteOrder,
  handleCreateMemorandum,
}) => {
  const recipeId = orderList.id;
  const recipientName = orderList.recipient?.name;
  const recipientPhoneNumber = orderList.recipient?.phone_number
    ? formatPhoneNumber(orderList.recipient?.phone_number)
    : null;
  const businessOperation = orderList.business_operations.join(", ");
  const orderItems: TableOrderItem[] = [
    ...orderList.order_products.map((orderProduct) => ({
      name: `${orderProduct.product.name} - ${orderProduct.name}`,
      quantity: orderProduct.quantity,
      orderType: orderProduct.is_rent ? "rent" : "purchase",
      warehouseName: orderProduct.warehouse.name,
    })),
    ...orderList.order_packages.map((orderPackage) => ({
      name: orderPackage.name,
      quantity: orderPackage.quantity,
      products: orderPackage.variant_products.map((variantProduct) => ({
        name: `${variantProduct.product.name} - ${variantProduct.name}`,
        quantity: variantProduct.quantity,
        orderType: variantProduct.is_rent ? "rent" : "purchase",
        warehouseName: variantProduct.warehouse.name,
      })),
    })),
  ];

  const address = orderList.recipient?.address;
  const orderMethod = orderList.order_method;
  const orderType = capitalize(orderMethod);

  const deliveryOrPickUpDate =
    orderList.order_method === OrderMethodValue["Delivery"]
      ? orderList.delivery_date
      : orderList.pickup_date;
  const formattedDeliveryOrPickUpDate = deliveryOrPickUpDate
    ? format(deliveryOrPickUpDate, "dd MMMM yyyy HH:mm")
    : "-";

  const buyerName = orderList.buyer.name;
  const customerType = orderList.buyer.customer_type?.name;
  const customerTypeHex = orderList.buyer?.customer_type?.hex;
  const buyerPhoneNumber = orderList.buyer.phone_number
    ? formatPhoneNumber(orderList.buyer.phone_number)
    : null;
  const orderDate = orderList.order_date;
  const transactionDate = format(orderDate, "dd MMMM yyyy HH:mm");
  const totalPayment = convertToCurrency(orderList.total_paid);
  const remainingAmount = convertToCurrency(
    orderList.order_price - orderList.total_paid
  );
  const invoiceNumber = orderList.invoice_no.join(", ");
  const transactionFlow = orderList.transaction_flow;
  const isEstimationOrder =
    deliveryOrPickUpDate === null &&
    orderList.estimation_start_date !== null &&
    orderList.estimation_end_date !== null;
  const paymentStatus = orderList.payment_status;
  const paymentMethod = orderList.payment_type;
  const transactionStatus = orderList.transaction_status;
  const hasRent = orderList.has_rent;
  const hasReturn = orderList.has_return;
  const orderTransactionStatus = getOrderTransactionStatus({
    transactionStatus,
    orderType: orderMethod,
    hasReturn,
  });
  const estimatedOrderDate =
    orderList.estimation_start_date && orderList.estimation_end_date
      ? `${format(orderList.estimation_start_date, "dd MMMM yyyy")} - ${format(
          orderList.estimation_end_date,
          "dd MMMM yyyy"
        )}`
      : "";

  const statuses = getTransactionStatusBadges({
    transactionFlow,
    isEstimationOrder,
    paymentStatus,
    paymentMethod,
  });

  let isAllowToContinuePickUpOrOrderDelivery = false;
  const isAllowToGenerateOrderRecipe = true;
  let isAllowToSwitchToPickUp = false;
  let isAllowToSwitchToDelivery = false;
  let isAllowToMarkAsPickUpOrDelivery = false;
  let isAllowSetFixedDate = false;
  let isAllowToMarkAsCompleteOrder = false;
  let isAllowToCreateMemorandum = false;

  if (type === ORDER_LIST_TAB.ForProduction) {
    isAllowToContinuePickUpOrOrderDelivery =
      type === ORDER_LIST_TAB["ForProduction"] && !isEstimationOrder;
    isAllowToSwitchToPickUp =
      isAllowToSwitchOrderTypeToPickUpOrDelivery &&
      orderMethod === OrderMethodValue["Delivery"] &&
      paymentStatus === "paid";
    isAllowToSwitchToDelivery =
      isAllowToSwitchOrderTypeToPickUpOrDelivery &&
      orderMethod === OrderMethodValue["PickUp"] &&
      paymentStatus === "paid";
    isAllowSetFixedDate =
      isAllowToSetFixedDateForEstimatedOrder && isEstimationOrder;
    isAllowToMarkAsPickUpOrDelivery = false;
    isAllowToMarkAsCompleteOrder = false;
    isAllowToCreateMemorandum = false;
  } else if (type === ORDER_LIST_TAB.WaitingForPickUpOrDelivery) {
    isAllowToContinuePickUpOrOrderDelivery = false;
    isAllowToSwitchToPickUp =
      isAllowToSwitchOrderTypeToPickUpOrDelivery &&
      orderMethod === OrderMethodValue["Delivery"] &&
      paymentStatus === "paid" &&
      transactionStatus === "on_waiting";
    isAllowToSwitchToDelivery =
      isAllowToSwitchOrderTypeToPickUpOrDelivery &&
      orderMethod === OrderMethodValue["PickUp"] &&
      paymentStatus === "paid" &&
      transactionStatus === "on_waiting";
    isAllowSetFixedDate = false;
    isAllowToMarkAsPickUpOrDelivery = transactionStatus === "on_waiting";
    isAllowToMarkAsCompleteOrder = transactionStatus === "on_fulfilled";
    isAllowToCreateMemorandum = hasRent;
  } else if (type === ORDER_LIST_TAB.Completed) {
    isAllowToContinuePickUpOrOrderDelivery = false;
    isAllowToSwitchToPickUp = false;
    isAllowToSwitchToDelivery = false;
    isAllowSetFixedDate = false;
    isAllowToMarkAsPickUpOrDelivery = false;
    isAllowToMarkAsCompleteOrder = false;
    isAllowToCreateMemorandum = false;
  }

  return (
    <TableRow key={index}>
      <TableCell>
        <Checkbox
          checked={selectedRecipeIds.includes(recipeId)}
          onCheckedChange={() => handleToggleSelectRecipe(recipeId)}
        />
      </TableCell>
      <TableCell>
        <div className="flex flex-col gap-1">
          {recipientName ? (
            <>
              <span>{recipientName}</span>
              {recipientPhoneNumber && (
                <span className="text-xs font-normal text-darkIndicator-30">
                  {recipientPhoneNumber}
                </span>
              )}
            </>
          ) : (
            <span className="italic">No Recipient Added</span>
          )}
        </div>
      </TableCell>
      <TableCell>{businessOperation}</TableCell>
      <TableCell className="w-56">
        <OrderListTableRowOrderItems orderItems={orderItems} />
      </TableCell>
      <TableCell>
        {address ? (
          <ReactShowMoreText {...reactShowMoreTextConfig}>
            {address}
          </ReactShowMoreText>
        ) : (
          <span className="italic">No Address Added</span>
        )}
      </TableCell>
      <TableCell>{orderType}</TableCell>
      <TableCell>
        {!isEstimationOrder ? (
          <div className="flex flex-col">
            <span>{formattedDeliveryOrPickUpDate}</span>

            <span className="italic text-darkBrown-default font-medium">
              {getPickUpOrDeliveryStatus({
                deliveryOrPickUpDate,
                orderDate,
                orderMethod,
              })}
            </span>
          </div>
        ) : (
          <span>{estimatedOrderDate}</span>
        )}
      </TableCell>
      <TableCell>
        <div className="flex flex-col items-start gap-1">
          <span>{buyerName}</span>
          {customerType && (
            <div
              className="font-normal text-xs px-1 rounded"
              style={{
                backgroundColor: customerTypeHex,
              }}
            >
              {customerType}
            </div>
          )}
          {buyerPhoneNumber && (
            <span className="text-xs font-normal text-darkIndicator-30">
              {buyerPhoneNumber}
            </span>
          )}
        </div>
      </TableCell>
      <TableCell>{transactionDate}</TableCell>
      <TableCell>
        <div className="flex flex-col gap-1">
          <span>{totalPayment}</span>
          <div className="flex flex-col">
            <span className="text-xs font-normal text-darkIndicator-30 text-nowrap">
              Remaining Amount:
            </span>
            <span className="text-xs font-normal text-darkIndicator-30">
              {remainingAmount}
            </span>
          </div>
        </div>
      </TableCell>
      <TableCell>
        <div className="flex flex-col gap-1">
          <span>{invoiceNumber}</span>

          <div className="flex flex-wrap items-start gap-2 w-32">
            {statuses}
          </div>
        </div>
      </TableCell>
      {type !== ORDER_LIST_TAB.ForProduction && (
        <TableCell>{orderTransactionStatus}</TableCell>
      )}
      <TableCell className="text-center">
        <TableActionDropdown>
          <>
            {isAllowToContinuePickUpOrOrderDelivery && (
              <DropdownMenuItem
                className="text-[#0099FF] hover:!text-[#0099FF]"
                onClick={() => handleContinuePickUpOrDelivery(recipeId)}
              >
                Continue to Pick Up/Delivery
              </DropdownMenuItem>
            )}
            {isAllowToMarkAsPickUpOrDelivery && (
              <DropdownMenuItem
                className="text-[#0099FF] hover:!text-[#0099FF]"
                onClick={() =>
                  handleMarkAsPickUpOrDelivery({ id: recipeId, orderMethod })
                }
              >
                {`Mark as ${
                  orderMethod === OrderMethodValue.Delivery
                    ? "On Delivery"
                    : "Has Pick Up"
                }`}
              </DropdownMenuItem>
            )}
            {isAllowToMarkAsCompleteOrder && (
              <DropdownMenuItem
                className="text-[#0099FF] hover:!text-[#0099FF]"
                onClick={() => handleMarkAsCompleteOrder(recipeId)}
              >
                Mark as Complete Order
              </DropdownMenuItem>
            )}
            {isAllowToGenerateOrderRecipe && (
              <DropdownMenuItem
                onClick={() => handleGenerateOrderRecipe(recipeId)}
              >
                Generate Order Recipe
              </DropdownMenuItem>
            )}
            {isAllowToCreateMemorandum && (
              <DropdownMenuItem
                onClick={() => handleCreateMemorandum(orderList)}
              >
                Create Memorandum
              </DropdownMenuItem>
            )}
            {isAllowSetFixedDate && (
              <DropdownMenuItem onClick={() => handleSetFixedDate(orderList)}>
                Set Fixed Date
              </DropdownMenuItem>
            )}
            <DropdownMenuItem onClick={() => handleViewDetail(recipeId)}>
              View Detail
            </DropdownMenuItem>

            {isAllowToSwitchToPickUp && (
              <DropdownMenuItem
                className="text-indicator-green hover:!text-indicator-green"
                onClick={() => handleSwitchToPickUp(orderList)}
              >
                Switch to Pick Up
              </DropdownMenuItem>
            )}
            {isAllowToSwitchToDelivery && (
              <DropdownMenuItem
                className="text-indicator-green hover:!text-indicator-green"
                onClick={() => handleSwitchToDelivery(orderList)}
              >
                Switch to Delivery
              </DropdownMenuItem>
            )}
          </>
        </TableActionDropdown>
      </TableCell>
    </TableRow>
  );
};

export default OrderListTableRow;
