import React from "react";

import {
  FetchNextPageOptions,
  InfiniteData,
  InfiniteQueryObserverResult,
} from "@tanstack/react-query";
import InfiniteScroll from "react-infinite-scroll-component";
import Spinner from "src/components/Spinner";
import { POSItem } from "./api/interfaces";
import POSCard from "./POSCard";

interface POSContainerProps {
  data: POSItem[];
  fetchNextPage: (
    options?: FetchNextPageOptions
  ) => Promise<InfiniteQueryObserverResult<InfiniteData<any, unknown>, Error>>;
  hasNextPage: boolean;
  handleAddProductToCart: (value: {
    id: number;
    type: "product" | "package";
  }) => void;
}

const POSContainer: React.FC<POSContainerProps> = ({
  data,
  fetchNextPage,
  hasNextPage,
  handleAddProductToCart,
}) => {
  return (
    <div className="px-5 -mx-5 ">
      <InfiniteScroll
        dataLength={data?.length ?? 0}
        next={() => fetchNextPage()}
        hasMore={hasNextPage}
        loader={
          <div className="flex items-center justify-center p-5">
            <Spinner size="large" />
          </div>
        }
        endMessage={<div />}
      >
        <div className="grid grid:cols-1 lg:grid-cols-2 xl:grid-cols-4 2xl:grid-cols-4 gap-2">
          {data &&
            data.map((posItem, index) => (
              <POSCard
                posItem={posItem}
                handleAddProductToCart={handleAddProductToCart}
                key={index}
              />
            ))}
        </div>
      </InfiniteScroll>
    </div>
  );
};

export default POSContainer;
