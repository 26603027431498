import { useState } from "react";
import { useDebounce } from "src/hooks/useDebounce";

const useSearch = (noDebounce?: boolean) => {
  const [searchInput, setSearchInput] = useState("");

  const debouncedSearchInput = useDebounce({ value: searchInput });

  return {
    value: noDebounce ? searchInput : debouncedSearchInput,
    search: searchInput,
    setSearch: setSearchInput,
  };
};

export default useSearch;
