import {
  ProductPackageDetailRequestBody,
  ProductPackageDetailResponse,
  ProductPackageParams,
  ProductPackageResponse,
  CreateProductPackageFormType,
  CreateProductPackageResponse,
  DeleteProductPackageResponse,
  DeleteProductPackageRequestBody,
  EditProductPackageFormType,
  EditProductPackageResponse,
  ProductPackageVariantListParams,
  ProductPackageVariantListResponse,
} from "src/pages/ProductManagement/ProductPackage/api/interfaces";
import { getPagination } from "src/utils/getPagination";
import API from ".";
import { convertArrayParamsToString } from "src/utils/convertArrayParamsToString";
import {
  getProductAndPackageSortingParams,
  ProductAndPackageSortValueType,
} from "src/pages/ProductManagement/ProductList/hooks/useProductListHandler";
import { format } from "date-fns";

const featureRouteApi = "/packages";

const convertFormData = ({
  form,
  isEdit,
}: {
  form: CreateProductPackageFormType | EditProductPackageFormType;
  isEdit?: boolean;
}) => {
  return {
    name: form.name,
    package_category_id: form.categoryId,
    package_sub_category_id: form.subCategoryId,
    item_stock_unit_id: form.itemStockUnitId,
    variants: (form.variants as EditProductPackageFormType["variants"]).map(
      (variant) => ({
        ...(isEdit && { package_variant_id: variant.id ?? null }),
        name: variant.name,
        sku: variant.sku,
        is_not_for_sale: variant.isNotForSale,
        not_for_sale_until: variant.notForSaleUntil
          ? format(variant.notForSaleUntil, "yyyy-MM-dd")
          : "",
        sell_price: variant.sellPrice ? parseInt(`${variant.sellPrice}`) : 0,
        description: variant.description,
        key_image: variant.keyImage,
        variant_products: variant.products.map((product) => ({
          ...(isEdit && {
            package_variant_product_id:
              product?.packageVariantProductId ?? null,
          }),
          product_variant_id: product.id,
          quantity: product.quantity,
          sell_price: product.price ? parseInt(`${product.price}`) : 0,
        })),
      })
    ),
  };
};

export const getProductPackage = async ({
  limit,
  offset,
  productPackageNameOrPackageSKU,
  sortBy,
  productPackageCategory,
  productPackageSubCategory,
  productPackageName,
  productPackageVariant,
  availability,
  minPrice,
  maxPrice,
  productStatus,
}: ProductPackageParams): Promise<ProductPackageResponse> => {
  const paginationParam = getPagination({ limit, offset });

  const response = await API.get<ProductPackageResponse>(featureRouteApi, {
    params: {
      ...paginationParam,
      ...(productPackageNameOrPackageSKU
        ? { qn: productPackageNameOrPackageSKU }
        : {}),
      ...(productPackageCategory.length > 0
        ? {
            package_category_ids: convertArrayParamsToString(
              productPackageCategory.map((option) => option.value)
            ),
          }
        : {}),
      ...(productPackageSubCategory.length > 0
        ? {
            package_sub_category_ids: convertArrayParamsToString(
              productPackageSubCategory.map((option) => option.value)
            ),
          }
        : {}),
      ...(productPackageName.length > 0
        ? {
            package_ids: convertArrayParamsToString(
              productPackageName.map((option) => option.value)
            ),
          }
        : {}),
      ...(productPackageVariant.length > 0
        ? {
            package_variant_ids: convertArrayParamsToString(
              productPackageVariant.map((option) => option.value)
            ),
          }
        : {}),
      ...(availability.length > 0
        ? {
            availability: convertArrayParamsToString(
              availability.map((option) => option.value)
            ),
          }
        : {}),
      ...(productStatus.length > 0
        ? {
            package_status: convertArrayParamsToString(
              productStatus.map((option) => option.value)
            ),
          }
        : {}),
      ...(maxPrice !== null ? { sell_range_max: maxPrice } : {}),
      ...(minPrice !== null ? { sell_range_min: minPrice } : {}),
      ...(sortBy
        ? getProductAndPackageSortingParams(
            sortBy as ProductAndPackageSortValueType
          )
        : {}),
    },
  });

  return response.data;
};

export const getProductPackageVariantList = async ({
  limit,
  offset,
  name,
  sku,
  productName,
  selectedVariantIds,
}: ProductPackageVariantListParams): Promise<ProductPackageVariantListResponse> => {
  const paginationParam = getPagination({ limit, offset });

  const response = await API.get<ProductPackageVariantListResponse>(
    "/package-variants",
    {
      params: {
        ...paginationParam,
        ...(name ? { name } : {}),
        ...(sku ? { sku } : {}),
        ...(productName ? { product_name: productName } : {}),
        ...(selectedVariantIds && selectedVariantIds?.length > 0
          ? { selected_variant_ids: selectedVariantIds }
          : {}),
      },
    }
  );

  return response.data;
};

export const getProductPackageDetail = async ({
  id,
}: ProductPackageDetailRequestBody): Promise<ProductPackageDetailResponse> => {
  const response = await API.get<ProductPackageDetailResponse>(
    `${featureRouteApi}/${id}`
  );

  return response.data;
};

export const createProductPackage = async (
  form: CreateProductPackageFormType
): Promise<CreateProductPackageResponse> => {
  const requestBody = convertFormData({ form });

  const response = await API.post<CreateProductPackageResponse>(
    featureRouteApi,
    requestBody
  );

  return response.data;
};

export const editProductPackage = async ({
  id,
  ...form
}: EditProductPackageFormType): Promise<EditProductPackageResponse> => {
  const requestBody = convertFormData({ form, isEdit: true });

  const response = await API.put<CreateProductPackageResponse>(
    `${featureRouteApi}/${id}`,
    requestBody
  );

  return response.data;
};

export const deleteProductPackage = async ({
  id,
}: DeleteProductPackageRequestBody): Promise<DeleteProductPackageResponse> => {
  const response = await API.delete<DeleteProductPackageResponse>(
    `${featureRouteApi}/${id}`
  );

  return response.data;
};
