import React from "react";
import BarcodeReader from "react-barcode-reader";
import { ErrorToast } from "src/config/reactQuery.config";
import { ProductVariant } from "src/pages/ProductManagement/ProductList/api/interfaces";
import CustomInput from "../CustomInput";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "../ui/dialog";
import { Form } from "../ui/form";
import useScanQRModal from "./hooks/useScanQRModal";

interface ScanQRModalProps {
  isShow: boolean;
  onClose: () => void;
  onSuccess: (productVariant: ProductVariant) => void;
}

const ScanQRModal: React.FC<ScanQRModalProps> = ({
  isShow,
  onClose,
  onSuccess,
}) => {
  const { form, handleScanQR, handleCloseModal } = useScanQRModal({
    onClose,
    onSuccess,
  });

  return (
    <Dialog open={isShow} onOpenChange={handleCloseModal}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle className="text-md font-semibold text-darkBrown-default">
            Scan QR
          </DialogTitle>
        </DialogHeader>

        <div className="flex flex-col items-center gap-4 pt-2 pb-5">
          <Form {...form}>
            <CustomInput
              form={form}
              name="qrValue"
              placeholder="QR Code"
              onChange={() => {}}
              onFocus={(e) => e.target.blur()}
            />
          </Form>

          <BarcodeReader
            onError={(error) =>
              ErrorToast({
                error: {
                  message: error,
                },
              })
            }
            onScan={handleScanQR}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ScanQRModal;
